import { Client } from "@microsoft/microsoft-graph-client";
import { User, DriveItem, File } from '@microsoft/microsoft-graph-types';
import { CloudFile, ICloudStorageService, PreviewResult } from "@api/CloudStorage";
import { AuthenticationResult, EventMessage, EventType, PublicClientApplication, SilentRequest } from '@azure/msal-browser';
import { CloudFilled } from "@fluentui/react-icons";
import { drive } from "googleapis/build/src/apis/drive";
import { access } from "fs/promises";
// import { Buffer } from "buffer";

export class OneDriveStorageService implements ICloudStorageService {
    private client: Client;

    readonly fileAccessScopes = { scopes: ["Files.ReadWrite"] };

    // Reference to the MSAL instance.
    private msalInstance: PublicClientApplication;

    constructor(msalInstance: PublicClientApplication) {
        this.msalInstance = msalInstance;
    }

    async getUserDetails(): Promise<any> {
        try {
            const user: User = await this.client.api('/me').get();
            console.log('User:', user);
            return user;
        } catch (error) {
            throw error;
        }
    }

    public async getToken(): Promise<string> {
        console.log("getFilesToken");
        if (this.msalInstance.getActiveAccount() === null && this.msalInstance.getAllAccounts().length > 0) {
            this.msalInstance.setActiveAccount(this.msalInstance.getAllAccounts()[0]);
        }
        try {
            // Get the token silently
            const silentResult: AuthenticationResult = await this.msalInstance.acquireTokenSilent(this.fileAccessScopes);
            console.log("Silent token acquisition succeeded:", silentResult.accessToken);
            return silentResult.accessToken;
        } catch (silentError) {
            console.error("Error getting token silently", silentError);
            try {
                // If silent token acquisition fails, get the token interactively.
                const interactiveResult: AuthenticationResult = await this.msalInstance.acquireTokenPopup(this.fileAccessScopes);
                console.log("Token acquired interactively:", interactiveResult.accessToken);
                return interactiveResult.accessToken;
            } catch (interactiveError) {
                console.error("Error getting token interactively", interactiveError);
                throw interactiveError;
            }
        }
    }

    /**
     * Initialize the Microsoft Graph client with the provided access token.
     * If the access token is not provided, it will be fetched from the MSAL instance.
     * 
     * @param accessToken 
     * @returns 
     */
    public async initClient(accessToken: string = ""): Promise<any | null> {
        if (accessToken === "") {
            try {
                accessToken = await this.getToken();
                console.log("OneDrive token:", accessToken);
            } catch (error) {
                console.error("Error getting OneDrive token:", error);
                return null;
            }
        }
        console.log("Initializing Microsoft Graph client. accessToken:", accessToken);
        this.client = Client.init({
            debugLogging: true,
            authProvider: (done) => {
                done(null, accessToken); // First parameter takes an error if you can't get an access token
            },
        });
        return this.client;
    }

    private toCloudFile(driveItem: DriveItem): CloudFile {
        let result: CloudFile = driveItem as CloudFile;
        if (driveItem.file?.mimeType) {
            result.mimeType = driveItem.file.mimeType;
        }
        result.isFile = !!driveItem.file;
        result.isFolder = !!driveItem.folder;
        return result;
    }

    public async listFiles(path: string): Promise<CloudFile[]> { // Add return type annotation
        try {
            // TODO: do we need to use path (name) or id?
            let url: string = (path === "/" || path === "") ?
                "/me/drive/root/children" :
                `/me/drive/items/${path}/children`;
            // `/me/drive/root:${path}:/children`;
            const response = await this.client.api(url).get();
            console.log("OneDrive listFiles response:", response.value);
            return response.value.map((file: DriveItem) => this.toCloudFile(file));
        } catch (error) {
            console.error("Error listing files from OneDrive", error);
        }
        return [];
    }

    async listFilesFromAppFolder(): Promise<{ id: string; name: string; mimeType: string; }[]> {
        try {
            const response = await this.client.api('/me/drive/special/approot/children').get();
            console.log("OneDrive listFilesFromAppFolder response:", response.value);
            return response.value.map((file: DriveItem) => this.toCloudFile(file));
        } catch (error) {
            console.error("Error listing files from OneDrive app folder", error);
            throw error;
        }
    }

    async readFile(fileId: string): Promise<ArrayBuffer> {
        try {
            let item: CloudFile = await this.getMetadataById(fileId);
            const endpoint = `https://graph.microsoft.com/v1.0/me/drive/items/${fileId}/content`;
            const accessToken = await this.getToken();
            const response = await fetch(endpoint, {
                method: 'GET',
                headers: { 'Authorization': `Bearer ${accessToken}` },
                redirect: 'follow' // This is the default behavior
            });
            if (!response.ok) {
                throw new Error(`Could not fetch file: ${response.statusText}`);
            }
            // Check the content type
            const contentType: string = response.headers.get('Content-Type')!;
            let data;
            let buffer: ArrayBuffer = new ArrayBuffer(0);
            if (contentType.includes('application/json')) {
                data = await response.json();
                buffer = new TextEncoder().encode(JSON.stringify(data)).buffer;
            } else if (contentType.includes('text')) {
                data = await response.text();
                buffer = new TextEncoder().encode(data).buffer;
            } else {
                // Default to blob for other types of content like images, videos, etc.
                data = await response.blob();
                buffer = await data.arrayBuffer();
            }
            return buffer;
        } catch (error) {
            window.alert("Error reading file from OneDrive!" + error);
        }
        // Empty buffer
        return new ArrayBuffer(0);
    }

    async readFileFromAppFolder(filename: string): Promise<ArrayBuffer> {
        try {
            const fileContent: ArrayBuffer = await this.client.api(`/me/drive/special/approot/children/${filename}/content`).get();
            return fileContent!;
        } catch (error) {
            console.error("Error reading file from OneDrive app folder", error);
            throw error;
        }
    }

    async writeFile(filename: string, content: string): Promise<string | null> {
        try {
            const file: DriveItem = await this.client.api('/me/drive/root/children/' + filename + '/content').put(content);
            console.log('File uploaded:', file);
            if (file === undefined || !file.id || !file.name) {
                throw new Error("Error writing file to OneDrive");
            }
            return file.id!;
        } catch (error) {
            console.error("Error writing file to OneDrive", error);
            throw error;
        }
        return null;
    }

    async writeFileToAppFolder(filename: string, content: string): Promise<string | null> {
        try {
            const file: DriveItem = await this.client.api('/me/drive/special/approot/children/' + filename + '/content').put(content);
            console.log('File uploaded:', file);
            if (file === undefined || !file.id || !file.name) {
                throw new Error("Error writing file to OneDrive");
            }
            return file.id!;
        } catch (error) {
            console.error("Error writing file to OneDrive", error);
            throw error;
        }
        return null;
    }

    async getFilenameById(fileId: string): Promise<string> {
        try {
            const file: DriveItem = await this.client.api(`/me/drive/items/${fileId}`).get();
            if (file === undefined || !file.name) {
                throw new Error("Error fetching file details from OneDrive");
            }
            return file.name;
        } catch (error) {
            console.error("Error fetching file details from OneDrive", error);
            throw error;
        }
    }

    async getMetadataById(fileId: string): Promise<CloudFile> {
        try {
            const file: DriveItem = await this.client.api(`/me/drive/items/${fileId}`).get();
            return this.toCloudFile(file);
        } catch (error) {
            console.error("Error fetching file details from OneDrive", error);
            throw error;
        }
    }

    async getPreview(fileId: string): Promise<string | PreviewResult | any> {
        /*
                //
                // TODO: this API does not seem to be supported yet with the v1 client library
                //
                try {
                    console.log("Trying preview endpoint...");
                    const body = {};
                    const preview: any = await this.client.api(`/me/drive/items/${fileId}/preview`)
                        .header("Content-Type", "application/json")
                        .post(body);
                    console.log("OneDrive preview response:", preview);
                    if (preview.getUrl != undefined)
                    {
                        return preview.getUrl;
                    }
                } catch (error) {
                    console.error("Error getting preview from OneDrive", error);
                }
         */
        try {
            console.log("Trying thumbnails endpoint...");
            const thumbnail = await this.client.api(`/me/drive/items/${fileId}/thumbnails`).get();
            console.log("OneDrive getPreview response:", thumbnail);
            if (thumbnail.value && thumbnail.value.length > 0) {
                return thumbnail.value[0] as PreviewResult;
            }
        } catch (error) {
            console.error("Error getting preview from OneDrive", error);
        }
        // If we can't get the preview, fallback to return the webUrl.
        // This URL will open the file in the browser in a popup window.
        let metadata = await this.getMetadataById(fileId);
        console.log("Fallback to metadata:", metadata);
        return metadata.webUrl;
    }

}
