import '@app/styles.css'
import React, { useEffect, useState } from 'react';

import { initializeIcons } from '@fluentui/react/lib/Icons';

// NEW STUFF
import {
  Add24Regular,
  AppsListDetail24Regular,
  Delete24Regular,
  List24Regular,
  MoreHorizontal24Filled,
  ArrowMinimize24Regular,
  ArrowMaximize24Regular,
  FullScreenMaximize24Regular,
  ArrowExitRegular,
  Library24Regular,
  Cloud24Regular
} from "@fluentui/react-icons";

import {
  Toolbar,
  ToolbarButton,
  ToolbarDivider,
  Menu,
  MenuTrigger,
  MenuPopover,
  MenuList,
  MenuItem
} from "@fluentui/react-components";


import { Switch } from "@fluentui/react-components";

import App from '@app/App';
import * as AppEvents from '@api/AppEvents';

import { AssistantPopover } from '@components/AssistantInfo';

// TODO: This is only for desktop app
// @ts-ignore
interface Global {
  window: any;
}
const nw = (window.nw !== undefined) ? window.nw : null;

// Initialize icons to ensure you have the icons available for use in your menu bar
initializeIcons();

const ChatHistory24Regular: React.FunctionComponent = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24"><path fill="currentColor" d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10a9.96 9.96 0 0 1-4.587-1.112l-3.826 1.067a1.25 1.25 0 0 1-1.54-1.54l1.068-3.823A9.96 9.96 0 0 1 2 12C2 6.477 6.477 2 12 2m0 1.5A8.5 8.5 0 0 0 3.5 12c0 1.47.373 2.883 1.073 4.137l.15.27l-1.112 3.984l3.987-1.112l.27.15A8.5 8.5 0 1 0 12 3.5M8.75 13h4.498a.75.75 0 0 1 .102 1.493l-.102.007H8.75a.75.75 0 0 1-.102-1.493zh4.498zm0-3.5h6.505a.75.75 0 0 1 .101 1.493l-.101.007H8.75a.75.75 0 0 1-.102-1.493zh6.505z"></path></svg>
  )
}

export function menuBarAction(id: string) {
  let app = App.instance;
  switch (id) {
    case "menu":
      app.openSideMenu();
      break;
    case "add":
      app.aiActions.trainJSON();
      break;
    case "history":
      app.showChatHistory();
      break;
    case "storage":
      app.showStorage();
      break;
    case "delete":
      app.clearChat();
      break;
    case "threads":
      app.showThreads()
      break;
    case "files":
      app.showFiles()
      break;
    case "theme":
      app.toggleTheme();
      break;
    case "full":
      try {
        nw?.Window?.get().toggleFullscreen();
      } catch (err) { };
      break;
    case "minimize":
      try {
        nw?.Window?.get().minimize();
      } catch (err) { };
      break;
    case "maximize":
      //@ts-ignore
      try {
        nw?.Window.get().maximize();
      } catch (err) { };
      break;
    case "exit":
      //@ts-ignore
      window?.slideOut();
      setTimeout(() => {
        nw?.Window.get().hide();
        // TODO: in browser scenario need to do window.close() here?
      }, 500);
      break;
  }
}


const MenuToolbar: React.FunctionComponent = () => {
  let app = App.instance;
  const [isLoggedIn, setIsLoggedIn] = useState( () => {
    app.on(AppEvents.USER_AUTH_SUCCESS, () => {
      setIsLoggedIn(true);
    });
    return app.state.isLoggedIn;
  });

  return (
    <Toolbar aria-label="Main menu" /* {...props} */>
      <ToolbarButton aria-label="Library" icon={<Library24Regular />} onClick={() => menuBarAction("menu")} />
      <ToolbarDivider />
      <ToolbarButton aria-label="Add" icon={<Add24Regular />} onClick={() => menuBarAction("add")} />
      <ToolbarButton aria-label="Chat History" icon={<ChatHistory24Regular />} onClick={() => menuBarAction("history")} />
      <ToolbarButton aria-label="Delete" icon={<Delete24Regular />} onClick={() => menuBarAction("delete")} />
      <ToolbarDivider />
      {(isLoggedIn) && <ToolbarButton aria-label="My Files" icon={<Cloud24Regular />} onClick={() => menuBarAction("storage")} />}
      {(isLoggedIn) && <ToolbarDivider />}
      {(isLoggedIn) && <ToolbarButton aria-label="Threads" icon={<List24Regular />} onClick={() => menuBarAction("threads")} />}
      {(isLoggedIn) && <ToolbarButton aria-label="AI Files" icon={<AppsListDetail24Regular />} onClick={() => menuBarAction("files")} />}
      <AssistantPopover />
      <ToolbarDivider />
      <Menu>
        <MenuTrigger>
          <ToolbarButton aria-label="More" icon={<MoreHorizontal24Filled />} />
        </MenuTrigger>
        <MenuPopover>
          <MenuList>
            <MenuItem>New </MenuItem>
            <MenuItem>New Window</MenuItem>
            <MenuItem disabled>Open File</MenuItem>
            <MenuItem>Open Folder</MenuItem>
          </MenuList>
        </MenuPopover>
      </Menu>
    </Toolbar>
  );
}

const WindowButtons: React.FunctionComponent = () => {
  return (
    <Toolbar aria-label="Main Window Buttons" /* {...props} */>
      <Switch label="Theme" onClick={() => menuBarAction("theme")} defaultChecked={true} />
      <ToolbarButton aria-label="Minimize" icon={<ArrowMinimize24Regular />} onClick={() => menuBarAction("minimize")} />
      <ToolbarButton aria-label="Maximize" icon={<ArrowMaximize24Regular />} onClick={() => menuBarAction("maximize")} />
      <ToolbarButton aria-label="Full Screen" icon={<FullScreenMaximize24Regular />} onClick={() => menuBarAction("full")} />
      <ToolbarButton aria-label="Exit" icon={<ArrowExitRegular />} onClick={() => menuBarAction("exit")} />
    </Toolbar>
  );
}

export const MenuBar: React.FunctionComponent = () => {
  return (
    <div className='menubar'>
      <MenuToolbar />
      <div style={{ position: "absolute", right: 0, top: 0 }}>
        <WindowButtons/>
      </div>
    </div>
  );

};
