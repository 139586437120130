export const TRAINING_DATA_READ         = "training-data-read";
export const TRAINING_MODE_CHANGED      = "training-mode-changed";
export const AI_RESPONSE_RECEIVED       = "ai-response-received";
export const AI_REQUEST_SENT            = "ai-request-sent";
export const AI_ASSISTANT_READY         = "ai-assistant-ready";
export const AI_CONFIGURE_FAILED        = "ai-configure-failed";
export const EDITOR_DIALOG              = "editor-dialog";
export const SETTINGS_SAVED             = "settings-saved";

export const AI_STREAM_MESSAGECREATED   = "ai-stream-messagecreated";
export const AI_STREAM_MESSAGECOMPLETED = "ai-stream-messagecompleted";
export const AI_STREAM_TEXTCREATED      = "ai-stream-textcreated";
export const AI_STREAM_TEXTDELTA        = "ai-stream-textdelta";
export const AI_STREAM_TOOLCALLCREATED  = "ai-stream-toolcallcreated";
export const AI_STREAM_TOOLCALLDELTA    = "ai-stream-toolcalldelta";
export const AI_STREAM_IMAGECREATED     = "ai-stream-imagecreated";
export const AI_STREAM_CODECREATED      = "ai-stream-codecreated";
export const AI_STREAM_LOGSCREATED      = "ai-stream-logscreated";
export const AI_STREAM_ERRORCREATED     = "ai-stream-errorcreated";

export const USER_AUTH_SUCCESS          = "user-auth-success";
export const USER_AUTH_FAILED           = "user-auth-failed";
export const USER_AUTH_SIGNOUT          = "user-auth-signout";

export const GOOGLE_AUTH_SUCCESS        = "google-auth-success";
export const GOOGLE_AUTH_FAILED         = "google-auth-failed";
export const GOOGLE_AUTH_SIGNOUT        = "google-auth-signout";
export const GOOGLE_AUTH_TOKEN          = "google-auth-token";
export const GOOGLE_AUTH_PROFILE        = "google-auth-profile";
export const GOOGLE_AUTH_EXPIRED        = "google-auth-expired";

export const MICROSOFT_AUTH_SUCCESS     = "microsoft-auth-success";
export const MICROSOFT_AUTH_FAILED      = "microsoft-auth-failed";
export const MICROSOFT_AUTH_SIGNOUT     = "microsoft-auth-signout";
export const MICROSOFT_AUTH_TOKEN       = "microsoft-auth-token";
export const MICROSOFT_AUTH_PROFILE     = "microsoft-auth-profile";
export const MICROSOFT_AUTH_EXPIRED     = "microsoft-auth-expired";

export const SPEECH_ON_PHONEME          = "speech-on-phoneme";

export const STORAGE_READY              = "storage-ready";