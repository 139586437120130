import App from "@app/App";
import { CloseButton } from "@components/CloseButton";
import { AIFileList } from "@dialogs/AIFileList";
import { Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, Spinner } from "@fluentui/react-components";
import React from "react";

export const AIFileDialog: React.FunctionComponent<{ app: App }> = ({ app }) => {
    return (
      <Dialog
        open={app.state.isFiles}>
        <DialogSurface className='dialogsurface'>
          <DialogBody className='dialogbody'>
            <CloseButton onClick={() => app.dialogClose()} />
            <DialogTitle className='dialogtitle'>{app.state.dialogTitle}</DialogTitle>
            <DialogContent className="dialogcontent">
              <div className='dialog_1024x768'>
                <AIFileList items={app.fileList}
                  //                  onSave={(id, filename) => this.storage.saveAssistantFile(id, filename)}
                  onDelete={(id) => app.aiActions.deleteAssistantFile(id)} />
                {app.state.isLoading && <Spinner size="extra-large" className="app-spinner" />}
              </div>
            </DialogContent>
            <DialogActions>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    );
  }
