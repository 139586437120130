import App from "@app/App";
import { AvatarCreatorPage } from "@app/components/AvatarCreatorPage";
import { CloseButton } from "@components/CloseButton";
import { Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, Spinner } from "@fluentui/react-components";
import React from "react";

export const AvatarDialog: React.FunctionComponent<{ app: App }> = ({ app }) => {
    const width = window.screen.availWidth * 0.8;
    const height = window.screen.availHeight * 0.8;
    return (
      <Dialog
        open={app.state.isAvatar}>
        <DialogSurface className='dialogsurface'>
          <DialogBody className='dialogbody'>
            <CloseButton onClick={() => app.dialogClose()} />
            <DialogTitle className='dialogtitle'>{app.state.dialogTitle}</DialogTitle>
            <DialogContent className="dialogcontent">
              <div className='dialog_fullscreen' style={{width: width, height: height}}>
                <AvatarCreatorPage />
              </div>
            </DialogContent>
            <DialogActions>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    );
  }
