import { ICloudStorageService, CloudFile, PreviewResult } from '@api/CloudStorage';
import { GoogleOAuthProvider } from '@components/GoogleAuth';
const gapi = window.gapi;

export class GoogleDriveStorageService implements ICloudStorageService {

    // Reference to the MSAL instance.
    private gauth: GoogleOAuthProvider;

    constructor(gauth: GoogleOAuthProvider) {
        this.gauth = gauth;
    }

    public async initClient(accessToken: string = ""): Promise<any | null> {
        try {
            await gapi.client.init({
                clientId: this.gauth.clientId,
                discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'], // Discovery doc URL for Drive API v3
            });
            gapi.auth2.init({ client_id: this.gauth.clientId, scope: this.gauth.scopes.join(' ')});
            gapi.client.setToken({ access_token: this.gauth.token });
            return true;
        } catch (error) {
            window.alert("Error initializing GAPI client: " + JSON.stringify(error, null, 2));
            console.error("Error initializing GAPI client", error);
        }
        return false;
    }

    async getUserDetails(): Promise<any> {
        return await gapi.client.drive.about.get({ fields: 'user' });
    }

    private toCloudFile(f: gapi.client.drive.File): CloudFile {
        let result: CloudFile = {
            id: f.id!,
            name: f.name!,
            mimeType: f.mimeType,
            createdBy: f.owners![0].displayName,
            createdDateTime: f.createdTime!,
            isFile: f.mimeType !== "application/vnd.google-apps.folder",
            isFolder: f.mimeType === "application/vnd.google-apps.folder",
            eTag: null,
            lastModifiedBy: f.lastModifyingUser!.displayName,
            lastModifiedDateTime: f.modifiedTime!,
            parentReference: f.parents![0],
            webUrl: f.webViewLink,
            size: parseInt(f.size!)
        };
        return result;
    }

    async listFiles(path: string, spaces: string = ""): Promise<CloudFile[]> {
        try {
            if (path === "/" || path === "") {
                path = "root";
            }
            const response: gapi.client.drive.FileList = (await gapi.client.drive.files.list(
                {
                    q: `'${path}' in parents`,
                    spaces: spaces,
                    pageSize: 1000,
                    'fields': 'files(id, name, mimeType, owners, createdTime, modifiedTime, parents, size, webViewLink, lastModifyingUser)'
                })).result;
            let result: CloudFile[] = [];
            await response.files?.forEach(async file => {
                result.push(this.toCloudFile(file));
            });
            return result;
        } catch (err) {
            console.log("Error listing files", err);
        }
        return [];
    }

    async listFilesFromAppFolder(): Promise<CloudFile[]> {
        return await this.listFiles("", "appDataFolder");
    }

    async getFileMetadata(fileId: string) {
        const response = await gapi.client.drive.files.get({
            fileId: fileId,
            fields: 'webContentLink, downloadUrl',
        });
        return response.result;
    }

    async downloadFileContent(url: string): Promise<Blob> {
        const accessToken = this.gauth.token;
        const response = await fetch(url, {
            headers: new Headers({ 'Authorization': 'Bearer ' + accessToken })
        });
        if (!response.ok) {
            throw new Error('Failed to download file content.');
        }
        return response.blob();
    }

    async readFile(fileId: string): Promise<ArrayBuffer> {
        try {
            const metadata = await this.getMetadataById(fileId);
            const blob = await this.downloadFileContent(metadata.webContentLink!);
            const arrayBuffer = await blob.arrayBuffer();
            return arrayBuffer;
        } catch (error) {
            console.error("Error reading file from Google Drive", error);
            throw error;
        }    
    }

    async readFileFromAppFolder(filename: string): Promise<ArrayBuffer> {
        console.log("TODO: Implement readFileFromAppFolder properly");
        return this.readFile(filename);
    }

    async uploadFile(file: File): Promise<string | null> {
        const boundary = '-------314159265358979323846';
        const delimiter = `\r\n--${boundary}\r\n`;
        const closeDelimiter = `\r\n--${boundary}--`;

        const metadata = {
            'name': file.name,
            'mimeType': file.type || 'application/octet-stream',
        };

        const multipartRequestBody =
            delimiter +
            'Content-Type: application/json; charset=UTF-8\r\n\r\n' +
            JSON.stringify(metadata) +
            delimiter +
            'Content-Type: ' + (file.type || 'application/octet-stream') + '\r\n\r\n' +
            // Here, the actual file content will go into the body, but since it's binary content,
            // we'll need to read the file asynchronously
            closeDelimiter;

        try {
            let result = "";
            const reader = new FileReader();
            reader.readAsBinaryString(file);
            reader.onload = async (e) => {
                const contentType = file.type || 'application/octet-stream';
                const base64Data = btoa(reader.result as string);
                const multipartRequestBodyWithFile =
                    multipartRequestBody.replace(closeDelimiter, `\r\n${base64Data}\r\n${closeDelimiter}`);

                const response = await gapi.client.request({
                    'path': '/upload/drive/v3/files',
                    'method': 'POST',
                    'params': { 'uploadType': 'multipart' },
                    'headers': {
                        'Content-Type': `multipart/related; boundary="${boundary}"`,
                    },
                    'body': multipartRequestBodyWithFile,
                });
                console.log(response);
                result = response.result.id;
            };
            return result;
        } catch (error) {
            console.error("Error uploading file:", error);
        }
        return null;
    }


    async writeFile(filename: string, content: string, mimeType: string = "text/plain") {
        const file = new File([content], filename, { type: mimeType });
        return await this.uploadFile(file);
    }

    async writeFileToAppFolder(filename: string, content: string) {
        return await this.writeFile(filename, content);
    }

    async getMetadataById(fileId: string): Promise<CloudFile>
    {
        const response = await gapi.client.drive.files.get({
            fileId: fileId,
            fields: 'id, name, mimeType, owners, createdTime, modifiedTime, parents, size, webViewLink, lastModifyingUser, webContentLink'
        });
        return this.toCloudFile(response.result);
    }

    getImageDimensions(url: string) {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = function() {
            resolve({ width: img.width, height: img.height });
          };
          img.onerror = function() {
            reject(new Error('There was an error loading the image.'));
          };
          img.src = url;
        });
      }

    async getPreview(fileId: string): Promise<any> {
        const response = await gapi.client.drive.files.get({
            fileId: fileId,
            fields: 'thumbnailLink, hasThumbnail, webContentLink, webViewLink'
        });
        let r = response.result;
        if (r.thumbnailLink !== undefined)
        {
            if (r.thumbnailLink.endsWith("=s220")) {
                // Increase thumbnail height to 600px
                r.thumbnailLink = r.thumbnailLink.replace("=s220", "=s600");
            }
            const dimensions: any = await this.getImageDimensions(r.thumbnailLink!);
            let preview: PreviewResult = {
                id: "0",
                large: { url: r.thumbnailLink!, width: dimensions.width, height: dimensions.height }
            };
            return preview;
        }
        return r.webViewLink;
    }

}
