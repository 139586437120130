import { CommandBar } from "@fluentui/react";
import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle } from "@fluentui/react-components";
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import React, { useEffect, useState } from "react";
import { AIThreadList, ThreadItem } from "./AIThreadList";
import App from "@app/App";
import { CubeSync24Regular, DocumentRegular } from "@fluentui/react-icons";
import { CloseButton } from "@components/CloseButton";

export interface ThreadsDialogProps {
    parent: App;
    threads: Object[];
}

export const ThreadsDialog: React.FunctionComponent<ThreadsDialogProps> = ({ parent, threads }: ThreadsDialogProps) => {
    let selectedThreadId: string = "";

    const [items, setItems] = useState<ThreadItem[]>([]);

    function toDateString(timestamp: number): string {
        // Implement the logic to convert timestamp to date string
        return new Date(timestamp).toLocaleDateString();
    }

    async function deleteThread(id: string) {
        await parent.ai.deleteThread(id);
        threads = parent.ai.threads;
        updateItems();
    }

    async function downloadThread(id: string) {
        let result = await parent.ai.downloadThreadMessages(id);
        if (!result) {
            window.alert("Failed to download thread messages.");
        }
    }

    function updateItems()
    {
        if (threads) {
            console.log("ThreadsDialog.updateItems: length=" + threads.length);
            const updatedItems = threads.map((thread: any) => ({
                threadId: { label: thread.id, icon: <DocumentRegular /> },
                title: { label: thread.metadata?.['title'] ?? "Untitled" },
                createdAt: { label: toDateString(thread.created_at), timestamp: thread.created_at }
            }));
            setItems(updatedItems);
            console.log("ThreadsDialog.updateItems: length=" + updatedItems.length);
        }
    }

    useEffect(() => {
        updateItems();
    }, [threads]);

    const RefreshArrow: React.FunctionComponent = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 16 16"><path fill="currentColor" d="M3.5 8a4.5 4.5 0 0 1 7.854-3H9.75a.75.75 0 0 0 0 1.5h3a.75.75 0 0 0 .75-.75v-3a.75.75 0 0 0-1.5 0v.778a6 6 0 1 0 1.94 5.324a.75.75 0 0 0-1.485-.211A4.501 4.501 0 0 1 3.5 8"></path></svg>
        )
    }

    return (
        <Dialog open={parent.state.isThreads}>
            <DialogSurface className='dialogsurface'>
                <DialogBody className='dialogbody'>
                    <CloseButton onClick={() => parent.dialogClose()} />
                    <DialogTitle className='dialogtitle'><Button onClick={() => parent.showThreads(true)}
                        icon={<RefreshArrow/>} appearance="secondary"
                        style={{ position: "absolute", left: 0, top: 0 }}>Refresh</Button>{parent.state.dialogTitle}</DialogTitle>
                    <DialogContent className="dialogcontent">
                        <div className='dialog_1024x768'>
                            <AIThreadList
                                items={items}
                                onSelect={(id: string, title: string) => { selectedThreadId = id; }}
                                onDelete={async (id: string) => { await deleteThread(id); }}
                                onSave={async (id: string) => { await downloadThread(id); }} />
                            {parent.state.isLoading && <Spinner size={SpinnerSize.large} className="app-spinner" />}
                        </div>
                    </DialogContent>
                    <DialogActions>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
}
