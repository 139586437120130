import React, { ReactNode, useState } from "react";

import {
    Delete16Regular,
    Save16Regular,
    FolderRegular,
    EditRegular,
    OpenRegular,
    DocumentRegular,
    PeopleRegular,
    DocumentPdfRegular,
    VideoRegular,
  
    Document16Regular,
    DocumentPdf16Regular,
    TextBulletList16Regular,
    DocumentLandscapeRegular,
    DocumentText16Regular,
    FluentIcon,
    Javascript16Filled,
    DocumentPdf24Regular,
    Document24Regular,
    TextBulletList24Regular,
    Javascript24Regular
  } from "@fluentui/react-icons";

export interface FileItem {
    id: string;
    bytes: number;
    created_at: number;
    filename: string;
    purpose?: string;
    icon?: any
  }

export type FileType = 'markdown' | 'html' | 'pdf' | 'pptx' | 'docx' | 'txt' | 'csv' | 'js' | 'json' | 'image' | 'video' | 'unknown';

export const getFileIcon = (filename: string): ReactNode => {
    // A mapping of file extensions to file types
    const extensionToFileType: { [key: string]: FileType } = {
      '.md': 'markdown',
      '.html': 'html',
      '.pdf': 'pdf',
      '.ppt': 'pptx',
      '.pptx': 'pptx',
      '.doc': 'docx',
      '.docx': 'docx',
      '.txt': 'txt',
      '.csv': 'csv',
      '.js': 'js',
      '.jpg': 'image',
      '.jpeg': 'image',
      '.png': 'image',
      '.gif': 'image',
      '.svg': 'image',
      '.bmp': 'image',
      '.tiff': 'image',
      '.ico': 'image',
      '.avi': 'video',
      '.mp4': 'video',
      '.json': 'json',
    };
  
    // Extract the file extension, including the dot
    const extension = filename.slice(filename.lastIndexOf('.')).toLowerCase();
    let type = extensionToFileType[extension] || 'unknown';
    switch (type) {
      case 'markdown':
        return ("📝")
      case 'html':
        return ("🌐")
      case 'pdf':
        return (<DocumentPdf24Regular />)
      case 'pptx':
        return (<Document24Regular />)
      case 'docx':
        return (<Document24Regular />)
      case 'txt':
        return ("📄")
      case 'csv':
        return (<TextBulletList24Regular />)
      case 'js':
        return (<Javascript24Regular />)
      case 'json':
        return (<Javascript24Regular />)
      case 'image':
        return ("📷");
      case 'video':
        return ("🎥");
    }
    return ("📃");
  }

export type OnSave = (id: string, filename: string) => void;

export type OnDelete = (id: string, filename: string) => void;

export type OnEdit = (id: string, filename: string) => void;

export type OnView = (id: string, filename: string) => void;

export type OnSelect = (id: string, item?: any | undefined) => void;

export type OnDirectoryChange = (id: string, dirs: any[] | undefined) => void;

export interface FileListTableColumn
{
  columnKey: string;
  label: string;
  style?: React.CSSProperties;
}

export interface FileListProps {
  items?: any[];
  dirs?: any[];
  selectedId?: string;
  onSelect?: OnSelect;
  onSave?: OnSave;
  onDelete?: OnDelete;
  onEdit?: OnEdit;
  onView?: OnView;
  onDirectoryChange?: OnDirectoryChange;
  columns?: FileListTableColumn[];
}