import { Button } from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';
import React from 'react';

const CloseButton: React.FC<{ onClick: (evt?: any) => void }> = ({ onClick }) => {
    return (
        <Button
            style={{
                position: "absolute", right: 0, top: 0, borderRadius: "4px",
                borderStyle: "ridge", backgroundColor: '#553333', color: '#FFFFFF',
                zIndex: 1000}}
            icon={<Dismiss24Regular/>}
            appearance="secondary"
            about='Close the Assistant Settings dialog'
            className='red-close-button'
            onClick={onClick}
        />
    );
};

const CloseButton2: React.FC<{ onClick: (evt?: any) => void }> = ({ onClick }) => {
    return (
        <Button
            style={{ position: "absolute", right: 0, top: 0 }}
            icon="✖️"
            appearance="secondary"
            about='Close the Assistant Settings dialog'
            onClick={onClick}
        />
    );
};

export { CloseButton, CloseButton2 };
