import '@app/styles.css';

import React, { KeyboardEvent, MouseEvent, ChangeEvent, useState } from 'react';
import { Avatar, Popover, PopoverSurface, PopoverTrigger } from "@fluentui/react-components";
import { Chat, ChatMessage, ChatMyMessage } from "@fluentui-contrib/react-chat";
import { InfoLabel, Link } from "@fluentui/react-components";

import { Stack, IStackStyles, IStackTokens, IStackItemStyles } from '@fluentui/react';
import { Attach20Regular, Delete20Regular, Send20Regular, Cloud20Regular, Bot24Filled, BotRegular } from '@fluentui/react-icons';
import { Button } from '@fluentui/react-components';

import App from '@app/App';
import { AI } from '@api/AI';

import {
    Field,
    ProgressBar,
} from "@fluentui/react-components";

// Markdown functionality
import Markdown from 'react-markdown'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism'

import { FileObject } from 'openai/resources';

import { getFileIcon } from '@dialogs/FileCommonTypes';
import { AI_STREAM_CODECREATED, AI_STREAM_IMAGECREATED, AI_STREAM_LOGSCREATED, AI_STREAM_MESSAGECOMPLETED, AI_STREAM_MESSAGECREATED, AI_STREAM_TEXTCREATED, AI_STREAM_TEXTDELTA, AI_STREAM_TOOLCALLCREATED, AI_STREAM_TOOLCALLDELTA } from '../api/AppEvents';
import { CloudFile } from '@api/CloudStorage';
import { AvatarPopover } from '@components/AssistantInfo';

const verticalStackStyles: IStackStyles = {
    root: {
        width: '100%',         // Use the full width of the container
        height: '100%'
    }
};

const horizontalStackStyles: IStackStyles = {
    root: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden',
        width: '100%',
        height: '100%',
        padding: '16px'
    }
};

const topItemStyles: IStackItemStyles = {
    root: {
      marginBottom: 'auto'
    },
  };
  

// Define the tokens for spacing and alignment
const horizontalStackTokens: IStackTokens = {
    childrenGap: 8, // Adjust the gap between the components
};

interface IMessage {
    type: 'received' | 'sent';
    role?: string;
    info?: string;
    link?: string;
    content: string;
    mimetype?: string;
    image_file?: string;
}

let defaultMessages: IMessage[] = [
    /*
        { type: 'received', role: 'assistant', content: 'Hello I am Ashley...\n' },
        { type: 'sent', role: 'system', content: 'Ashley!\nLook\nAt\nMe...\n' },
        { type: 'received', role: 'assistant', content: 'Do you have time to talk about that AI project?' },
        { type: 'sent', role: 'user', content: 'Yes sure! let\'s hop on a call' }
     */
];

interface ChatRoomProps {
    parent: App,
    trainer: AI,
    name: string,
    onComponentMount?: (ChatRoom) => void
}

interface ChatRoomState {
    attachEnabled: boolean;
    attachmentLabel?: string;
    count: number;
    messages: IMessage[]
    progress: number;
    progressVisibility: boolean;
    imageSrc?: { [id: string]: string };
}

const ChatBackgroundStyles = {
    root: {
        position: 'relative',
        background: "transparent",
        width: "100%",
        height: "100%",
        overflowX: 'inherit',
        overflowY: 'scroll'
    },
    chatBackground: {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundSize: 'cover',
        opacity: '0.8',
        zIndex: -1,
    }
};

const ChatBackgroundStyle = JSON.stringify(ChatBackgroundStyles)
    .replace(/"([^"]+)":/g, '$1: ')
    .replace(/"([^"]+)"/g, '$1');

/* eslint-disable */
class ChatRoom extends React.Component<ChatRoomProps, ChatRoomState> {

    private trainer: AI;
    private parent: App;
    private progressBarTaskId;

    private isUpdateAllowed: boolean = true;

    private stackRef: React.RefObject<HTMLDivElement>;

    private fileFormRef: React.RefObject<HTMLFormElement>;
    private fileInputRef: React.RefObject<HTMLInputElement>;

    private inputStackRef: React.RefObject<HTMLDivElement>;
    private attachButtonRef: React.RefObject<HTMLButtonElement>;
    private removeButtonRef: React.RefObject<HTMLButtonElement>;
    private messageRef: React.RefObject<HTMLDivElement>;

    private inputValue: string = "";
    private attachment?: File | null = null;
    private imgRef: React.RefObject<HTMLImageElement>;

    public shouldComponentUpdate(): boolean {
        return this.isUpdateAllowed;
    }

    public setUpdateAllowed(updateAllowed: boolean): void {
        this.isUpdateAllowed = updateAllowed;
    }

    /**
     * Save chat history.
     * 
     * @returns Chat history as JSON array.
     */
    public saveChatHistory(): string {
        let result: Object[] = [];
        this.state.messages.forEach((message, index, arr) => {
            result.push({ role: message.role, content: message.content })
        });
        return JSON.stringify(result, null, 2);
    }

    constructor(props: ChatRoomProps) {
        super(props);

        this.state = {
            count: 0,
            attachEnabled: true,
            messages: defaultMessages,
            progress: 0,
            progressVisibility: false,
            imageSrc: {}
        };

        this.inputValue = "";
        this.trainer = props.trainer;
        this.parent = props.parent;

        this.stackRef = React.createRef();
        this.fileFormRef = React.createRef();
        this.fileInputRef = React.createRef();
        this.inputStackRef = React.createRef();
        this.attachButtonRef = React.createRef();
        this.removeButtonRef = React.createRef();
        this.imgRef = React.createRef();
        this.messageRef = React.createRef();

        this.parent.on(AI_STREAM_MESSAGECREATED, (message: any) => {
            let chatMessage: IMessage = { role: 'assistant', type: 'received', content: "" };
            this.addMessage(chatMessage);
            this.forceUpdate();
        });

        this.parent.on(AI_STREAM_TEXTCREATED, (text: any) => {
            this.messageRef?.current?.scrollIntoView();
            let lastMessage = this.state.messages.at(-1);
            lastMessage!.content = ""; // text.value;
            this.setState({ messages: this.state.messages });
        });

        this.parent.on(AI_STREAM_TEXTDELTA, async (textDelta: any) => {
            this.messageRef?.current?.scrollBy(0, 100);
            let lastMessage = this.state.messages.at(-1);
            lastMessage!.content += textDelta.value;
            this.setState({ messages: this.state.messages });
            await this.parent.aiActions.speakChunkToWebAudio(textDelta.value);
        });

        this.parent.on(AI_STREAM_TOOLCALLCREATED, (toolCall: any) => {
            // this.addMessage(message);
        });

        this.parent.on(AI_STREAM_TOOLCALLDELTA, (toolCallDelta: any) => {
            // this.addMessage(message);
        });

        this.parent.on(AI_STREAM_MESSAGECOMPLETED, async (message: any) => {
            this.messageRef?.current?.scrollBy(0, 100);
            let lastMessage = this.state.messages.at(-1);
            if (message.data && message.data.content.length > 0)
            {
                lastMessage!.content = message.data.content[0].text?.value;
                this.setState({ messages: this.state.messages });
            }
            await this.parent.aiActions.speakChunkToWebAudio("", true);
        });

        this.parent.on(AI_STREAM_IMAGECREATED, (image_id: string) => {
            let chatMessage: IMessage = { role: 'assistant', type: 'received', content: "", image_file: image_id};
            this.addMessage(chatMessage);
            this.forceUpdate();
        });

        this.parent.on(AI_STREAM_CODECREATED, (code: string, logs: string = "") => {
            let chatMessages: IMessage[] = [];

            let chatMessage: IMessage = { role: 'assistant', type: 'received', content: code, info: 'interpreter' };
            chatMessages.push(chatMessage);

            if (logs != "")
            {
                chatMessage = { role: 'assistant', type: 'received', content: logs, info: 'logs' };
                chatMessages.push(chatMessage);
            }

            this.addMessages(chatMessages);
            this.forceUpdate();
        });
    }

    componentDidMount() {
        if (this.props.onComponentMount) {
            this.props.onComponentMount(this);
        }
    }

    public setAttachEnabled(enabled: boolean) {
        this.setState({ attachEnabled: enabled });
    }

    public setMessages(messages: IMessage[]) {
        this.setState({ messages: messages });
    }

    public attachCloudFile(item: CloudFile) {
        let fileId = item.id!;
        this.parent.storage?.readFile(fileId).then((file: ArrayBuffer) => {
            console.log('Read file from cloud storage', file);
            let mimetype: string = item.mimeType!;
            let fileblob = new Blob([file], { type: mimetype });
            let fileobj = new File([fileblob], item.name!, { type: mimetype });
            this.attachFile(fileobj);
        }).catch((error: any) => {
            console.error('Error reading file from cloud storage', error);
        });
    }

    public attachFile(file: File) {
        this.attachment = file;
        this.setAttachEnabled(false);
        this.setState({ attachmentLabel: "📎" + file.name });
        this.forceUpdate();
    }

    handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const file = files[0];
            console.log('Selected file:', file.name);
            this.attachment = file;
            this.setAttachEnabled(false);
            this.setState({ attachmentLabel: "📎" + file.name });
            this.forceUpdate();
        } else {
            this.attachment = null;
            this.setState({ attachmentLabel: "" });
        }
    };

    handleTextareaInput = (data) => {
        this.inputValue = data.value;
    }

    public clearMessages(): void {
        this.setMessages([]);
    }

    public addMessages(messages: IMessage[]): void {
        const mergedArray: IMessage[] = [...this.state.messages, ...messages];
        this.setMessages(mergedArray);
    }

    public addMessage(message: IMessage): void {
        const arrCopy = [...this.state.messages, message];
        this.setMessages(arrCopy);
    }

    public stopProgress(): void {
        this.setState({ progressVisibility: false, progress: 0 });
    }

    public startProgress(): void {
        this.setState({ progressVisibility: true, progress: 0 });
        this.progressBarTaskId = setInterval(() => {
            let progress = this.state.progress;
            progress += 0.01;
            this.setState({ progress: progress });
            if (progress >= 1 || !this.state.progressVisibility) {
                clearInterval(this.progressBarTaskId);
            }
        }, 200);
    }

    public async sendMessage(text: string = "") {

        if (text == "") {
            // Get text from textarea element.
            let textarea: HTMLTextAreaElement = window.document.getElementById("inputTextArea") as HTMLTextAreaElement;
            text = textarea.value;
            textarea.value = "";
            this.inputValue = "";
        }

        if (text == "") {
            console.log("No input!");
            return;
        }

        this.startProgress();

        let file_id: string = "";
        if (this.attachment != null) {
            let result: any = await this.trainer.attachAssistantFile(this.attachment);
            if (typeof (result) === undefined) {
                window.alert("Failed to attach file: " + this.attachment?.name);
            } else {
                file_id = (result as FileObject)!.id;
                console.log("Attached file:", this.attachment, file_id);
            }
        }

        let content: string = text;
        let info: string = "";
        let link: string = "";
        if (file_id != "") {
            // content += `\n\n${this.attachment?.name}`;
            // content += `\nfile id=${file_id}\n`;
            info = 'file';
            link = this.attachment?.name + "|" + file_id;
        }

        this.removeAttachment();
        // Add new outgoing message to the chat.
        this.addMessage({ type: 'sent', role: 'user', content: content, info: info, link: link });

        this.parent.aiActions.ask(text, file_id);
    }

    handleTextareaKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
        if ((!event.shiftKey) && event.key === "Enter") {
            this.sendMessage();
            event.preventDefault();
        }
    }

    handleSubmitClick = () => {
        this.sendMessage();
    }

    handleAttachClick = (event: MouseEvent) => {
        this.fileInputRef.current?.click();
    }

    handleAttachCloudClick = (event: MouseEvent) => {
        this.parent.showStorage();
    }

    removeAttachment() {
        this.fileInputRef.current?.setAttribute('value', '');
        this.fileFormRef.current?.reset();
        this.setAttachEnabled(true);
        this.setState({ attachmentLabel: "" });
        this.attachment = null;
    }

    handleRemoveAttachmentClick = (event: MouseEvent) => {
        this.removeAttachment();
    }

    componentDidUpdate(prevProps: ChatRoomProps, prevState: ChatRoomState) {
        const stackElement = this.stackRef.current;
        if (stackElement && prevState.messages.length < this.state.messages.length) {
            stackElement.scrollTop = stackElement.scrollHeight;
        }
    }

    public renderMarkdown(message: IMessage) {
        // TODO: TeachingPopover - would be great to show Kusto stuff...
        let markdown = message.content;
        return (
            <Markdown
                className="bubble-small"
                children={markdown}
                components={{
                    code(props) {
                        const { children, className, node, ...rest } = props
                        const match = /language-(\w+)/.exec(className || '')
                        if (match) {
                            message.mimetype = match[1];
                        }
                        return match ? (
                            <SyntaxHighlighter
                                PreTag="div"
                                children={String(children).replace(/\n$/, '')}
                                language={match[1]}
                                className={`${className}`}
                                style={materialDark}
                            />
                        ) : (
                            <code {...rest} className={`${className}`}>
                                {children}
                            </code>
                        )
                    }
                }}
            />
        )
    }

    public infolabel(message: IMessage) {
        switch (message.info) {
            case 'file':
                let parts = message.link?.split('|') ?? "";
                return (
                    <InfoLabel
                        info={`Attachment file id ${parts[1]}`}>
                        <Link href={parts[1]}>{getFileIcon(parts[0])}&nbsp;{parts[0]}</Link>
                    </InfoLabel>);

            case 'interpreter':
                return (<InfoLabel style={{ backgroundColor: 'navy' }} info="Code executed by AI interpeter">Code Interpreter</InfoLabel>);

            case 'logs':
                return (<InfoLabel style={{ backgroundColor: 'navy' }} info="Logs generated by AI interpeter">Code Interpreter Logs</InfoLabel>);
    
            default:
                return (
                    <InfoLabel
                        info={
                            <>
                                {message.info}.{" "}
                                <Link href="{message.link}">Learn more</Link>
                            </>}
                    >{message.role?.toUpperCase()} {message.info}</InfoLabel>);
        }
    }

    // Define an array of messages about an AI assistant crunching numbers
    messages: string[] = [
        "Hang tight! I'm crunching the numbers as fast as I can.",
        "Just a moment more, I'm processing heaps of data for you.",
        "Please bear with me while I sift through the numbers.",
        "Almost there! I'm analyzing the data to get you the best results.",
        "Give me a second, I'm compiling all the necessary calculations.",
        "Your patience is appreciated, I'm working hard on these calculations.",
        "I'm on it, making sense of the data for you.",
        "Calculations underway! I'll have those numbers for you in a jiffy.",
        "Working my digital magic on the numbers, just a bit longer.",
        "I'm diving deep into the data pool, will surface with your results shortly.",
        "Did you know that Azure Data Explorer is awesome?",
        "I'm a real boy!! (c) Pinocchio",
        "ʕ•́ᴥ•̀ʔっ♡",
        "📎♪ ♪ ♪ ♪ ♪ ♪ ♪ ......"
    ];

    // Function to select five random messages
    public getRandomMessage() {
        let randomIndex = (Date.now() / 5000) | 0;
        randomIndex = randomIndex % this.messages.length | 0;
        const message = this.messages[randomIndex];
        return message;
    }

    public async imageLoaded(evt: React.SyntheticEvent<HTMLImageElement, Event>) {
        console.log("Preloader loaded:", evt);
        if (evt.target != null) {
            let img = (evt.target as HTMLImageElement);
            let id = img.id;
            console.log("Replacing image with blob for id:", id);
            let uri = img.src;
            if (!uri.startsWith("blob:") && id != "") {
                console.log("Loading image blob:", id);
                let fileblob = await this.trainer.fetchAssistantFileBlob(id);
                if (fileblob != null) {
                    this.setState({ imageSrc: { ...this.state.imageSrc, [id]: URL.createObjectURL(fileblob.blob) } });
                }
            }
            evt.target.removeEventListener('load', this.imageLoaded as unknown as EventListener);
        }
    }

    /**
     * imageLoaded should trigger the call to getImage whem state changes. 
     * @param id 
     * @returns 
     */
    public getImage(id: string): boolean {
        if (this.state.imageSrc === undefined) {
            this.setState({ imageSrc: {} });
        }
        if (this.state.imageSrc![id] === undefined) {
            // Set the image to a loading gif while we wait for the image to load
            this.setState({ imageSrc: { ...this.state.imageSrc, [id]: "img/gear.gif" } });
        }
        return true;
    }

    public render() {
        const { name } = this.props;
        const { count, imageSrc } = this.state;

        return (
            <Stack id="chatroomStack" verticalFill={true}>
                <Chat id="chatbox" ref={this.stackRef}>
                    <Stack id="messageStack" styles={verticalStackStyles}>
                        {this.state.messages.map((message, index) =>
                            message.type === 'received' ? (
                                <Stack.Item key={index}>
                                    <ChatMessage ref={this.messageRef}
                                        key={index} avatar={<AvatarPopover/>}>
                                        {((message.info != undefined) || (message.role == 'system')) && this.infolabel(message)}
                                        {
                                            (message.image_file===undefined) ?
                                            (
                                                ((message.link !== undefined) && (message.mimetype?.includes('image/'))) ?
                                                    <div><img alt="DALL-E image"src={message.link}/></div> :
                                                    <div style={{ whiteSpace: 'pre-wrap' }}>{this.renderMarkdown(message)}</div>
                                            ):
                                            <div><img ref={this.imgRef}
                                                id={message.image_file}
                                                src={this.getImage(message.image_file) ? imageSrc![message.image_file]! : "img/gear.gif" }
                                                alt="Loading..." onLoad={(evt) => this.imageLoaded(evt)}></img></div>
                                        }
                                    </ChatMessage>
                                </Stack.Item>
                            ) : (
                                <Stack.Item key={index}>
                                    <ChatMyMessage
                                        key={index}>
                                        {(message.role == 'system') && this.infolabel(message)}
                                        <div style={{ whiteSpace: 'pre-wrap' }}>
                                            {this.renderMarkdown(message)}
                                            {((message.info == 'file') && this.infolabel(message))}
                                        </div>
                                    </ChatMyMessage>
                                </Stack.Item>
                            )
                        )}
                    </Stack>
                </Chat>
                <Stack.Item verticalFill={false} style={{ alignItems: 'center' }} key="10000" className='stackCenter' styles={{root: { width: "100%"}}}>
                    <Field validationMessage={this.getRandomMessage()} validationState="none" style={{
                        position: 'absolute', left: "25%", right: "25%", display: 'inline-block',
                        visibility: this.state.progressVisibility ? ('visible') : ('hidden'),
                        textAlign: "center", alignContent: "center", alignItems: "center"
                    }}><ProgressBar shape='rounded' thickness='large' value={this.state.progress} style={{ filter: "sepia(100%)" }} />
                    </Field>
                </Stack.Item>
                <Stack id="inputTab" horizontal styles={horizontalStackStyles} tokens={horizontalStackTokens} verticalFill={true} horizontalAlign='stretch' verticalAlign="end">
                    <Stack.Item verticalFill={true} align='center' styles={topItemStyles} >
                        <form ref={this.fileFormRef} style={{display: 'none'}}>
                            <input type="file" ref={this.fileInputRef} onChange={this.handleFileChange} style={{ display: 'none' }} />
                        </form>
                        <Button
                            disabled={!this.state.attachEnabled}
                            ref={this.attachButtonRef}
                            id="attachButton" icon={<Attach20Regular />}
                            title="Attach" onClick={(evt) => this.handleAttachClick(evt)} />
                        <Button
                            disabled={!this.state.attachEnabled}
                            id="attachCloudButton" icon={<Cloud20Regular />}
                            title="Attach Cloud File" onClick={(evt) => this.handleAttachCloudClick(evt)} />
                    </Stack.Item>
                    <Stack.Item verticalFill={true} align='center' styles={topItemStyles}>
                        <Button
                            disabled={this.state.attachEnabled}
                            ref={this.removeButtonRef}
                            id="removeButton" icon={<Delete20Regular />}
                            title="Remove" onClick={(evt) => this.handleRemoveAttachmentClick(evt)} />
                    </Stack.Item>
                    <Stack.Item grow align="stretch" verticalFill={true} styles={topItemStyles}>
                        <textarea
                            id="inputTextArea"
                            onInput={(evt) => this.handleTextareaInput(evt)}
                            onKeyDown={(evt) => this.handleTextareaKeyDown(evt)}
                            placeholder="Type your message here..."
                            className='dark-theme textarea-100'>
                        </textarea>
                        <p>&nbsp;{this.state.attachmentLabel}</p>
                    </Stack.Item>
                    <Stack.Item verticalFill={true} align='center' styles={topItemStyles}>
                        <Button icon={<Send20Regular />} title="Submit" onClick={() => this.handleSubmitClick()}>Submit</Button>
                    </Stack.Item>
                </Stack>
            </Stack>
        );
    }
};
/* eslint-enable */

export { ChatRoom, IMessage };
