import React from 'react';
import { PublicClientApplication } from "@azure/msal-browser";
import { Settings } from '@app/Settings';

export const AppContext = React.createContext(
    {
        msalAuth: undefined as PublicClientApplication | undefined,
        isAuthenticated: false
    }
);
