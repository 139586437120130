import axios from 'axios';
// import fs from 'fs';

class Utils {
    public static isBrowser(): boolean {
        return typeof window !== 'undefined';
    }

    public static async readFileAsync(filename: string, isBinary: boolean = false): Promise<string | Blob | ArrayBuffer> {
        console.log('Reading file', filename);
        let result: string | Blob | ArrayBuffer = "";
        if (Utils.isBrowser()) {
            const response = await axios.get(filename, { responseType: isBinary ? 'blob' : 'text' });
            result = isBinary ? response.data : response.data as string;
        }
        return result;
    }

    public static getFileDirectoryPath(filename: string): string {
        if (filename.startsWith('http')) {
            const url = new URL(filename);
            const path = url.pathname.substring(0, url.pathname.lastIndexOf('/'));
            return `${url.origin}${path}`;
        } else {
            const path = filename.substring(0, filename.lastIndexOf('/'));
            return path === '' ? '.' : path;
        }
    }

    public static downloadObjectAsJson<T>(filename: string, exportObj: T) {
        const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(exportObj))}`;
        const downloadAnchorNode = document.createElement('a');

        downloadAnchorNode.href = dataStr;
        downloadAnchorNode.download = `${filename}.json`;

        document.body.appendChild(downloadAnchorNode);
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    }

    public static downloadTextFile(filename: string, contents: string, mimetype = "text/plain;charset=utf-8") {
        const dataStr = `data:${mimetype},${encodeURIComponent(contents)}`;
        const downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.href = dataStr;
        downloadAnchorNode.download = filename;

        document.body.appendChild(downloadAnchorNode);
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    }

    public static downloadBinaryFile(filename: string, binaryData: Blob, mimetype = 'application/octet-stream') {
        const dataStr = URL.createObjectURL(binaryData);
        const downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.href = dataStr;
        downloadAnchorNode.download = filename;

        document.body.appendChild(downloadAnchorNode);
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    }

    public static async uploadTextFileToRemote(cb): Promise<string> {
        return new Promise((resolve, reject) => {
            const uploadAnchorNode = document.createElement('input');
            uploadAnchorNode.type = "file";
            uploadAnchorNode.addEventListener('change', async (event) => {
                const files = (event.target as HTMLInputElement).files;
                if (files && files.length > 0) {
                    const file = files[0];
                    const result = await cb(file);
                    resolve(result as string);
                } else {
                    reject(new Error("No file selected."));
                }
                uploadAnchorNode.remove();
            });
            document.body.appendChild(uploadAnchorNode);
            uploadAnchorNode.click();
        });
    }

    public static async uploadTextFile(): Promise<string> {
        return new Promise((resolve, reject) => {
            const uploadAnchorNode = document.createElement('input');
            uploadAnchorNode.type = "file";
            uploadAnchorNode.addEventListener('change', (event) => {
                const files = (event.target as HTMLInputElement).files;
                if (files && files.length > 0) {
                    const file = files[0];
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        const text = e.target!.result as string;
                        resolve(text);
                        uploadAnchorNode.remove();
                    };
                    try {
                        reader.readAsText(file);
                    } catch (ex) {
                        reject(ex);
                    }
                } else {
                    reject(new Error("No file selected."));
                }
            });
            document.body.appendChild(uploadAnchorNode);
            uploadAnchorNode.click();
        });
    }

    public static databind(obj: any, key: string, cb = (obj) => { }) {
        const handleChange = (event) => {
          if (!obj) return;
          obj[key] = event.target.value ?? event.target.checked ?? event.target.selectedOptions[0].value ?? event.target.selectedOptions;
          cb(obj);
        };
        return {
          onChange: handleChange
        };
      }
}

export { Utils };
