import OpenAI from 'openai';

import { MDProcessor, MDSection } from "@api/MDProcessor"
import { Utils } from "@api/Utils";

// OLD v1 API
import { ChatCompletion, ChatCompletionMessageParam, ErrorObject, FileObject, FileObjectsPage, ImageCreateVariationParams, Model } from "openai/resources";

// New BETA API
import { Assistant, AssistantTool, AssistantUpdateParams, FunctionTool } from "openai/resources/beta/assistants/assistants";
import { Thread } from "openai/resources/beta/threads/threads";
import { ImageFileContentBlock, Message, Messages, TextContentBlock } from "openai/resources/beta/threads/messages";

import { Uploadable, sleep } from "openai/core";
import EventEmitter from 'events';

import { AI_ASSISTANT_READY, AI_CONFIGURE_FAILED, AI_STREAM_CODECREATED, AI_STREAM_IMAGECREATED, AI_STREAM_LOGSCREATED, AI_STREAM_MESSAGECOMPLETED, AI_STREAM_MESSAGECREATED, AI_STREAM_TEXTCREATED, AI_STREAM_TEXTDELTA, AI_STREAM_TOOLCALLCREATED, AI_STREAM_TOOLCALLDELTA } from '@api/AppEvents'; // Import the missing constant
import { BlobLike, FileLike, toFile } from 'openai/uploads';
import { saveAppSettings } from '@app/Settings';
import { updateA } from '@fluentui/react';
import { NullSpeechSegment, SpeechSegment } from './WebSpeechPlayer';

// const isBrowser = typeof window !== 'undefined' && typeof window.document !== 'undefined';
// const isNode = typeof process !== 'undefined' && process.versions != null && process.versions.node != null;

// TODO: calculate the actual token budget instead
const training_treshold = 4;

// Metadata can hold up to 16 key-value pairs.
interface AssistantMetadata {
  id?: string;          // Assistant Metadata ID
  avatar?: string;      // URL to the avatar image
  avatar_3d?: string;   // URL to the 3D avatar image
  gender?: string;      // Assistant gender
  age?: string;         // Assistant age
  name?: string;        // Assistant friendly name
  location?: string;    // Assistant location
  language?: string;    // Assistant preferred language
  voice?: string;       // Assistant preferred voice
  dob?: string;         // Assistant date of birth
  identity?: string;    // Assistant identity
  skills?: string;      // Assistant skills
  [key: string]: any;   // Additional metadata
}

interface FileAttachment {
  name?: string;
  id: string;
  size?: number;
  mimeType?: string;
  fileTokenSize?: number;
}

interface AssistantMessage {
  role: string;
  content: string;
  metadata?: {
    attachments: FileAttachment[];
  };
}

export interface AssistantSettings /* extends Assistant */ {
  id: string;
  object: 'assistant';
  created_at: number;
  name: string | null;
  description: string | null;
  model: string;
  instructions: string | null;
  tools: AssistantTool[];
  file_ids: string[];
  metadata: AssistantMetadata | null;
  threads?: string[];
}

export interface Tools {
  codeRemote: boolean;
  codeLocal: boolean;
  retrieval: boolean;
  cloudFiles: boolean;
  functions: string[];
}

interface OpenAISettings {
  model: string;
  apiKey: string;
  // Azure specific seettings below:
  resource?: string;
  apiVersion?: string
  baseURL?: string;
}

interface FileBlob {
  filename: string;
  blob: Blob;
}

/**
 * ${1:Description placeholder}
 * @date 3/12/2024 - 11:54:26 AM
 *
 * @class AI
 * @typedef {AI}
 */
class AI {

  //  Default settings could be set before the instance is created.
  public static DEFAULT_MODEL: string = "gpt-3.5-turbo";
  public static DEFAULT_INSTRUCTIONS: string = "You are a friendly assistant. You can help me with anything.";
  public static DEFAULT_NAME: string = "AI Assistant";
  // TODO: this is a temporary solution to get the assistant ID in case if we run into issues with assistant settings.
  public static DEFAULT_ASSISTANT_ID: string = "asst_zSVHN4WaRPWm2AHY0OLevPGA";

  public openai: OpenAI;           // OpenAI API (current version)
  public openai_v1: OpenAI;        // OpenAI API (completions API v1)

  // Default assistant settings:
  public model: string;            // OpenAI model
  public instructions: string;     // Default instructions

  // Azure-specific settings:
  public apiVersion?: string       // Azure API version

  // Assistants, models, and threads:
  public assistants_settings: AssistantSettings[] = [];
  public assistants: Assistant[] = [];
  public models: OpenAI.Models.Model[] = [];
  public threads: OpenAI.Beta.Thread[] = [];

  // Current assistant information:
  public assistant: Assistant | undefined;
  public thread: Thread | null;    // Current assistant thread
  // public messages: Messages;       // Current assistant messages
  public files: FileObject[] = [];

  private event_emitter: EventEmitter | undefined;
  private settings: any | undefined;
  private saveAppSettings: Function | undefined;

  // Audio processing:
  textToSpeechQueue: string[] = [];
  public speechModel: string;
  public speechVoice: string;

  /**
 * @constructor
 */
  constructor(settings: any, emitter: EventEmitter | undefined = undefined, saveAppSettings: Function | undefined = undefined) {
    this.settings = settings;
    this.event_emitter = emitter;
    this.saveAppSettings = saveAppSettings;
  }

  public async initialize() {
    this.threads = (this.settings.threads as Thread[]) ?? [];
    let result = await this.configure(this.settings.openai, this.settings.assistants, this.event_emitter);
    if (result instanceof Error) {
      console.error("Failed to configure AI service:", result.message);
      this.event_emitter?.emit(AI_CONFIGURE_FAILED, result);
    } else {
      console.log("Configured AI service:", result);
      this.event_emitter?.emit(AI_ASSISTANT_READY, result);
    }
  }

  /**
   * ${1:Description placeholder}
   * @date 3/12/2024 - 11:54:26 AM
   *
   * @public
   */
  public async extractTrainingDataSections(filename: string): Promise<MDSection[]> {
    let processor: MDProcessor = new MDProcessor(filename);
    return await processor.extractSectionsAsync();
  }

  public async downloadAssistantFile(id: string, origFilename: string = "contents.bin"): Promise<Response> {
    const response: Response = await this.retrieveFileContent(id);
    if (response.ok) {
      const header: string = response.headers?.get('Content-Disposition') ?? origFilename;
      const filename: string = header.replace('attachment; filename=', '');
      const blob = await response.blob();
      Utils.downloadBinaryFile(filename, blob);
    } else {
      console.error("There was an error while trying to download file id=" + id);
    }
    return response;
  }

  public async fetchAssistantFileBlob(id: string): Promise<FileBlob | null> {
    const response: Response = await this.retrieveFileContent(id);
    if (response.ok) {
      const header: string = response.headers?.get('Content-Disposition') ?? id;
      const filename: string = header.replace('attachment; filename=', '');
      const blob = await response.blob();
      let fileBlob: FileBlob = { filename: filename, blob: blob };
      return fileBlob;
    }
    // TODO: maybe we should return an error object instead of null?
    console.error("There was an error while trying to download file id=" + id);
    console.error(response);
    return null;
  }

  /**
   * Official OpenAI API.
   * 
   * @param filename 
   * @returns 
   */
  public async uploadFileForTuning(filename: string, contents: Blob | undefined = undefined): Promise<OpenAI.Files.FileObject | undefined> {
    if (contents === undefined) {
      // Read file contents
      if (filename === "") {
        console.error("Filename is empty!");
        return;
      }

      let content = await Utils.readFileAsync(filename);
      if (content === undefined || content === "") {
        console.error("File not found or empty:", filename);
        return;
      }

      contents = new Blob([content]);
      if (contents === undefined) {
        console.error("Failed to create file blob:", filename);
        return;
      }
    }

    let result = await this.openai.files.create({
      file: await OpenAI.toFile(contents, filename),
      purpose: "fine-tune"
    });

    if (result === undefined) {
      console.error("Failed to upload file for tuning:", filename);
      return;
    }
    return result;
  }

  /**
   * Reverse-engineered implementation for web client.
   * 
   * @param filename
   * @returns 
   */
  public async attachAssistantFile(file: File, purpose: string = 'assistants'): Promise<OpenAI.Files.FileObject | undefined> {
    /*
        if (false)
        {
            let form = new FormData();
            form.append('file', file);
            form.append('purpose', purpose);
            form.append('use_case', "my_files");
            // Axios request to upload the file
            return axios.post(`${this.openai.baseURL}/files`, form, {
              headers: {
                'Authorization': `Bearer ${this.openai.apiKey}`,
                // Axios and browsers will automatically set the Content-Type
                // to multipart/form-data with the correct boundary.
              },
            });
        }
    */

    let uploadResult = await this.openai.files.create({
      file: file,
      purpose: 'assistants'
    })

    if (uploadResult !== undefined) {
      console.log("File uploaded:", uploadResult);
      console.log("Waiting for file processing");
      let waitParams: Object = { pollInterval: 500, maxWait: 5000 };
      let processingResult = await this.openai.files.waitForProcessing(uploadResult.id, waitParams);
      console.log("Processing result", processingResult);
      return processingResult;
    }

  }

  /**
   * Add thread to the list of threads and save to Settings.
   * @param id 
   */
  private addThread(thread: Thread) {
    if (typeof this.threads === undefined) this.threads = [];
    if (this.threads.find((item) => item.id === thread.id) === undefined) {
      this.threads.push(thread);
    }
    if (this.settings !== undefined) {
      if (this.settings.threads === undefined) this.settings.threads = [];
      this.settings.threads = this.threads as any[];
      this.saveAppSettings?.();
    }
  }

  /**
   * Delete thread from the list of threads and save to Settings.
   * @param id 
   * @returns 
   */
  public async deleteThread(id: string): Promise<boolean> {
    try {
      let result = await this.openai.beta.threads.del(id)
      if (result === undefined) {
        console.error("Failed to delete thread:", id);
        return false;
      }
      console.log("Thread deleted:", id);
    } catch (error) {
      console.log("Error:", error);
      // Error out if the thread is not found, but proceed to delete from the local list of threads.
    }

    this.threads = this.threads.filter((item) => item.id !== id);
    if (this.settings !== undefined) {
      this.settings.threads = this.threads as any[];
      this.saveAppSettings?.();
    }

    return true;
  }

  /**
   * Create a new assistant thread.
   * 
   * @param content 
   * @param file 
   * @returns 
   */
  public async createThread(content: string | undefined = undefined, file: FileAttachment[] = []): Promise<Thread | null> {
    let file_ids: string[] = [];
    file.forEach((value) => { file_ids.push(value.id); });
    let thread = (content === undefined) ?
      await this.openai.beta.threads.create() :
      await this.openai.beta.threads.create(
        {
          messages: [{ role: 'user', content: content, file_ids: file_ids, metadata: {} }],
          metadata: {}
        }
      );

    if (thread === undefined) {
      console.error("Failed to create thread!");
      return null;
    }
    this.addThread(thread);
    return thread;
  }

  public async downloadThreadMessages(id: string): Promise<boolean> {
    let openai = this.openai;
    let content = await openai.beta.threads.messages.list(id);
    if (content === undefined || content === null || content.data === undefined || content.data === null) {
      console.error("Failed to retrieve thread messages:", id);
      return false;
    }

    let messages: OpenAI.Beta.Threads.Messages.Message[] = content.data;
    Utils.downloadObjectAsJson(`messages-${id}`, messages);
    return true;
  }

  async fetchAssistantList(): Promise<boolean> {
    try {
      let assistant_list = await this.openai.beta.assistants.list();
      this.assistants = assistant_list.data;
      return true;
    } catch (error) {
      console.error("Failed to retrieve assistant list:", error);
    }
    return false;
  }

  async createAssistant(metadata: AssistantMetadata = {}): Promise<Assistant | undefined> {
    console.log("Creating default assistant...");
    await this.fetchModelList();
    let assistant = await this.openai.beta.assistants.create({
      model: this.model ?? AI.DEFAULT_MODEL,
      instructions: this.instructions ?? AI.DEFAULT_INSTRUCTIONS,
      name: AI.DEFAULT_NAME,
      tools: this.getDefaultTools(),
      file_ids: [],
      metadata: metadata
    });
    if (assistant === undefined) {
      console.error("Failed to create assistant!");
    }
    return assistant;
  }

  populateDefaultAssistant()
  {
    let defaultSettings: AssistantSettings = {
      id: AI.DEFAULT_ASSISTANT_ID,
      object: 'assistant',
      created_at: Date.now(),
      name: AI.DEFAULT_NAME,
      description: "AI Trainer Assistant",
      model: AI.DEFAULT_MODEL,
      instructions: AI.DEFAULT_INSTRUCTIONS,
      tools: this.getDefaultTools(),
      file_ids: [],
      metadata: {}
    };
    defaultSettings.metadata = AI.populateDefaultMetadata(defaultSettings.metadata!);
    this.assistants_settings.push(defaultSettings);
  }

  /**
   * Get assistant at zero slot or create new assistant.
   * 
   * @returns 
   */
  async initializeAssistans(updateAllowed: boolean = false): Promise<Assistant | ErrorObject> {

    try {
      await this.fetchAssistantList();
      if (this.assistants && this.assistants.length == 0) {
        console.log("No assistants found");
        if (updateAllowed) {
          // Create new default assistant
          let assistant = await this.createAssistant();
          if (assistant !== undefined) {
            this.assistant = assistant!; {
              this.assistants.push(this.assistant);
              return this.assistant;
            }
          } else {
            let error = {
              message: "No assistants found and assistant creation is not allowed!",
              code: null,
              param: null,
              type: ''
            }
            console.error(error.message);
            return error;
          }
        }
      }
    } catch (error) {
      return {
        message: "Failed to initialize assistants!",
        code: null,
        param: null,
        type: ''
      };
    }

    if (!this.assistants) {
      return {
        message: "No assistants found!",
        code: null,
        param: null,
        type: ''
      };
    }

    // Update existing assistant configuration.
    // Keep only those assistants that are still on server.
    console.log("Assistant list:", this.assistants);
    // Updating remote assistants with local settings is a privileged operation.
    // We should only do it when the user is authorized to do so.
    if (updateAllowed) {
      let updateDetected: boolean = false;
      for (let i = 0; i < this.assistants.length; i++) {
        for (let j = 0; j < this.assistants_settings.length; j++) {
          if (this.assistants[i].id == this.assistants_settings[j].id) {
            console.log("Updating remote assistant with local settings:", this.assistants[i].id);
            this.assistants[i] = await this.updateExistingAssistant(this.assistants[i], this.assistants_settings[j]);
            updateDetected = true;
            break; // Added break statement to exit inner loop once a match is found
          }
        }
      }
      if (updateDetected) {
        // If we updated any assistant settings, we should refresh the list of assistants.
        this.fetchAssistantList();
      }
    }
    // From the list of available assistants, we'll pick the one we had previously configured in the settings.
    // This will be the default assistant we'll use for the session.
    let assistant = (this.assistants_settings.length > 0) ?
      this.assistants.find((assistant) => assistant.id === this.assistants_settings[0].id) :
      undefined;
    if (assistant === undefined) {
      // If assistant we had previously configured in the settings is not found, we'll pick the first one
      // from the list of available assistants on the server.
      this.assistant = this.assistants[0];
      console.log("Running with default assistant:", this.assistant);
    } else {
      // Otherwise, we'll use the assistant we found in the list, and update the index pointing to it.
      this.assistant = assistant;
      console.log("Running with assistant based on settings:", this.assistant);
    }
    console.log("Active assistant:", this.assistant);
    return this.assistant;
  }

  /**
   * Retrieve message text from assistant response object.
   * 
   * @param message 
   * @returns 
   */
  public getMessageText(message: any | undefined): string {
    if (message !== undefined) {
      return message.content?.[0]?.text?.value ?? message.choices?.[0]?.message?.content ?? "";
    }
    return "";
  }

  /**
   * Ask assistant for completions.
   * 
   * @param content 
   * @returns 
   */
  public async askAssistant(content: string, file_id: string = "", useStreaming: boolean = false): Promise<OpenAI.Beta.Threads.Messages.Message[] | null> {
    if (!this.assistant) {
      console.error("Invalid assistant!");
      return null;
    }

    console.log("Running with modern assistant API");
    if (this.thread == null) {
      console.log("Creating new thread");
      this.thread = await this.createThread();
      if (this.thread == null) {
        console.log("Unable to create thread!");
        return null;
      }
    } else {
      console.log("Continue conversation on thread:", this.thread.id);
      // TODO: check if the thread is still active
    }

    console.log("Creating new message");
    let file_ids = (file_id != "") ? [file_id] : [];
    let myMessage = await this.openai.beta.threads.messages.create(
      this.thread.id,
      {
        role: 'user',
        content: content,
        file_ids: file_ids,
        metadata: {}
      }
    );

    if (myMessage == null) {
      console.log("Unable to create message!");
      return null;
    }
    console.log("New message created:", myMessage.id);

    if (!useStreaming) {
      return this.sendMessageWithoutStreaming(content);
    }
    return this.askAssistantWithStreaming(content);
  }

  private async sendMessageWithoutStreaming(content: string): Promise<OpenAI.Beta.Threads.Messages.Message[] | null> {
    console.log("Sending message without streaming...");
    let run: OpenAI.Beta.Threads.Runs.Run = await this.openai.beta.threads.runs.create(this.thread!.id, { assistant_id: this.assistant!.id });
    if (run == null) {
      console.log("Unable to create run!");
      return null;
    }
    console.log("Message sent. run.id:", run.id);
    let run_id = run.id;
    while (!(["completed", "cancelled", "expired", "failed"].includes(run.status))) {
      console.log("Waiting for run.id:", run_id);
      run = await this.openai.beta.threads.runs.retrieve(this.thread!.id, run_id)
      await sleep(200);
    }

    console.log("[ DONE ] -", run.status);
    if (run.status == "completed") {
      // TODO: check if we actually got the right message.
      let messages = await this.openai.beta.threads.messages.list(this.thread!.id);
      if (messages == null || messages.data.length == 0) {
        console.log("Unable to retrieve messages!");
        return null;
      }
      let responseMessages: OpenAI.Beta.Threads.Messages.Message[] = [];
      for (let i = 0; i < messages.data.length; i++) {
        let message = messages.data[i];
        if (message.role == 'assistant') {
          responseMessages.push(message);
        }
        if (message.content[0].type == "text" && message.content[0].text.value == content) {
          console.log("Found original message:", message.content[0].text.value);
          break;
        }
      }
      responseMessages.reverse();
      return responseMessages;
    }
    console.log("Run failed!");
    return null;
  }

  /**
   * Ask assistant using new async streaming API.
   * 
   * @param content 
   * @param file_id 
   * @returns 
   */
  private async askAssistantWithStreaming(content: string): Promise<OpenAI.Beta.Threads.Messages.Message[] | null> {

    console.log("Sending message with streaming...");
    let showToolCalls: boolean = true;
    let inInterpreter: boolean = false;
    let codetext: string = "";
    let logstext: string = "";

    const tsStart = Date.now();
    let stream = await this.openai.beta.threads.runs.createAndStream(this.thread!.id, { assistant_id: this.assistant!.id })

      .on('textCreated', (text) => {
        console.log("assistant > ", text.value ?? "");
        this.event_emitter?.emit(AI_STREAM_TEXTCREATED, text);
        inInterpreter = false;
      })

      .on('textDelta', (textDelta, snapshot) => {
        // console.log(textDelta.value ?? "");
        this.event_emitter?.emit(AI_STREAM_TEXTDELTA, textDelta);
      })

      .on('toolCallCreated', (toolCall) => {
        console.log("assistant > ", toolCall.type);
        switch (toolCall.type) {
          case "code_interpreter":
            codetext = "";
            logstext = "";
            inInterpreter = true;
            codetext += '```python\n';
            break;
          case "function":
            console.log("Function call:", toolCall.function?.name, toolCall.function?.arguments);
            break;
          case "retrieval":
            console.log("Retrieval call:", toolCall.retrieval);
            break;
        }
        this.event_emitter?.emit(AI_STREAM_TOOLCALLCREATED, toolCall);
      })

      .on('toolCallDelta', (toolCallDelta, snapshot) => {
        this.event_emitter?.emit(AI_STREAM_TOOLCALLDELTA, toolCallDelta);
        if (toolCallDelta.type === 'code_interpreter') {
          if (toolCallDelta.code_interpreter?.input) {
            codetext += toolCallDelta.code_interpreter.input;
          }
          if (toolCallDelta.code_interpreter?.outputs) {
            toolCallDelta.code_interpreter.outputs.forEach(output => {
              if (output.type === "logs") {
                logstext += output.logs;
              }
              if (output.type === "image") {
                // TODO: handle image output
              }
            });
          }
        }
      })

      .on('error', (err) => {
        console.error("Error:", err.message);
        // TODO: change this to emit an event instead of alert
        window.alert("Error: " + err.message);
        inInterpreter = false;
      })

      .on('end', async () => {
        const tsEnd = Date.now();
        const duration = (tsEnd - tsStart) / 1000;
        console.log("Stream processing took", duration, "seconds");
      })

      .on('event', (params: any) => {
        const { event, data } = params;
        switch (event) {

          case 'thread.message.created':
            // Create a new message in UI
            this.event_emitter?.emit(AI_STREAM_MESSAGECREATED, data);
            break;

          case 'thread.message.completed':
            let message: OpenAI.Beta.Threads.Messages.Message = data;
            for (let i = 0; i < message.content.length; i++) {
              // Here we assume that all text output have been already processed.
              // Only images are left to be processed.
              if (message.content[i].type === "image_file") {
                let file_id = (message.content[i] as ImageFileContentBlock).image_file.file_id;
                this.event_emitter?.emit(AI_STREAM_IMAGECREATED, file_id);
              }
            }
            this.event_emitter?.emit(AI_STREAM_MESSAGECOMPLETED, message);
            break;

          case 'thread.message.delta':
          case 'thread.run.step.delta':
            // Do nothing - we handle it elsewhere above
            return;

          case 'thread.run.step.completed':
            if (inInterpreter) {
              // Close the code block
              codetext += '\n```\n';
              inInterpreter = false;
              if (logstext !== "") {
                logstext = "\n```\n" + logstext + "\n```\n";
              }
              this.event_emitter?.emit(AI_STREAM_CODECREATED, codetext, logstext);
              codetext = "";
              logstext = "";
            }
            break;

          case 'thread.run.created':
            const { id } = data;
            console.log(`Thread run created: ${id}`);
            break;

          default:
            console.log(event, ":", data);
            break;
        }
      });

    await stream.done();
    return [];
  }

  /**
   * Ask assistant for completions using older v1 API.
   * 
   * @param text 
   * @param file_id 
   * @returns 
   */
  public async askCompletions(text: string, file_id: string | undefined = undefined): Promise<ChatCompletion | null> {
    if (this.openai_v1) {
      /// UNDOCUMENTED v1 API to try for completion API files support:
      /*
            {
              "name": "test.txt",
              "id": "file-t6Uex42azRfsgTw2jPMJDnhH",
              "size": 21,
              "mimeType": "text/plain",
              "fileTokenSize": 16
          }
      */
      console.log("Running with legacy completions API");
      let result: ChatCompletion = await this.openai_v1.chat.completions.create
        (
          {
            stream: false,
            model: this.model,
            messages: [
              // TODO: give system hints depending on context
              { role: 'system', content: this.instructions },
              { role: 'user', content: text }
            ]
          }
        );
      if (result === undefined || result === null) {
        console.error("Failed to get completions!");
        return null;
      }
      return result;
    }
    console.error("Completions not configured!");
    return null;
  }

  public async getUserImage(): Promise<BlobLike> {
    return new Promise((resolve, reject) => {
      let input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';
      input.onchange = (event) => {
        let files = (event.target as HTMLInputElement).files;
        if (files && files.length > 0) {
          let file = files[0];
          resolve(file);
        } else {
          reject("No file selected!");
        }
      };
      input.click();
    });
  }

  public async askDallE(text: string, model: string = "dall-e-3", size: string = "1024x1024", N: number = 1): Promise<OpenAI.Images.ImagesResponse | undefined> {
    let dalle_model = model;
    let dalle_size = size;
    let n = N;

    if (text.includes("n=") || text.includes("N=")) {
      const regex = /n=(\d+)/i;
      const match = text.match(regex);
      n = match ? parseInt(match[1]) : 1;
      text = text.replace(regex, "");
    }

    if (text.includes("size=") || text.includes("SIZE=")) {
      const regex = /size=(\d+x\d+)/i;
      const match = text.match(regex);
      dalle_size = match ? match[1] : "1024x1024";
      text = text.replace(regex, "");
    }

    if (text.includes("model=") || text.includes("MODEL=")) {
      const regex = /model=([a-z0-9-]+)/i;
      const match = text.match(regex);
      dalle_model = match ? match[1] : "dall-e-3";
      text = text.replace(regex, "");
    }

    if (text.includes("variation")) {
      let url = "";
      if (text.includes("https://") || text.includes("http://")) {
        const regex = /(https?:\/\/[^\s]+)/;
        const match = text.match(regex);
        const link = match ? match[0] : "";
        url = link;
      }
      let image: Uploadable | undefined = undefined;
      console.log("Variationt prompt:", text);
      let response: OpenAI.Images.ImagesResponse | undefined = undefined;
      if (url === "") {
        console.log("Getting user image...");
        image = {
          blob: async () => await this.getUserImage(),
          url: "http://localhost:3000/image.png",
        };
        response = await this.openai.images.createVariation({
          image,
          n,
          response_format: "url",
          size: dalle_size as ImageCreateVariationParams["size"],
        });
      } else {
        console.log("Creating image variation from URL:", url);
        const blob: Blob = (await Utils.readFileAsync(url, true)) as Blob;
        response = await this.openai.images.createVariation({
          image: {
            blob: async () => blob,
            url,
          },
          n,
          response_format: "url",
          size: dalle_size as ImageCreateVariationParams["size"],
        });
      }
      return response;
    }

    console.log("Generating image from prompt:", text);
    const response = await this.openai.images.generate({
      model: dalle_model,
      prompt: text,
      n,
      response_format: "url",
      size: dalle_size as ImageCreateVariationParams["size"],
    });
    return response;
  }


  /**
   * Classic OpenAI-suggested training mechanism for completions endpoint.
   * 
   * @param trainingData string - contains JSON string of training dataset.
   * @returns 
   */
  public async train(trainingData: string | MDSection[], useCompletions: boolean = true): Promise<ChatCompletion[] | OpenAI.Beta.Threads.Messages.Message[] | null> {
    let responses: ChatCompletion[] | OpenAI.Beta.Threads.Messages.Message[] | null = null;
    let messages: any[] = [];
    let obj: Object[] = [];

    if (typeof trainingData === 'string') {
      // JSON training - previous chats
      obj = JSON.parse(trainingData);
    }
    else {
      // MD training - structured training packs
      let sections: MDSection[] = trainingData as MDSection[];
      for (let i = 0; i < sections.length; i++) {
        obj.push({ role: (useCompletions) ? 'system' : 'user', content: '## ' + sections[i].heading + '\n\n' + sections[i].content });
      }
    }

    if (obj === undefined || obj.length == 0) {
      console.error("Invalid training data!");
      return null;
    }

    let threshold: number = 0;
    for (let i = 0; i < obj.length; i++) {
      messages.push(obj[i]);
      threshold++;
      if (threshold >= training_treshold) {
        if (useCompletions) {
          const response: ChatCompletion = await this.openai_v1.chat.completions.create({
            stream: false,
            model: this.model,
            messages: messages
          });
          if (response !== undefined && response !== null) {
            if (response.usage !== undefined) {
              console.log("Training usage:", response.usage);
            }
            (responses! as ChatCompletion[]).push(response);
          } else {
            console.error("Failed to train completions on JSON data:", messages);
          }
        } else {
          const response: OpenAI.Beta.Threads.Messages.Message[] | null = await this.askAssistant(messages.map((value) => value.content).join("\n\n"));
          if (response != null) {
            response.reverse();
            for (let i = 0; i < response.length; i++) {
              let message = response[i];
              console.log("Message:", message);
              (responses! as Message[]).push(message);
              if (message.incomplete_at !== undefined) {
                console.warn("Training incomplete at:", message.incomplete_at);
                console.warn("Training incomplete details:", message.incomplete_details ?? "No details provided");
              }
            }
          } else {
            console.error("Failed to train assistant on JSON data:", messages);
          }
        }
        threshold = 0;
        messages = [];
      }
    }
    return responses;
  }

  /**
   * Fetch model list.
   * 
   * @returns 
   */
  public async fetchModelList(): Promise<Model[]> {
    let models = await this.openai.models.list();
    if (models === undefined || models.data === undefined || models.data.length == 0) {
      console.error("Failed to retrieve model list!");
      return [];
    }
    this.models = models.data;
    if (this.model === undefined || this.model === "") {
      console.log("No active model selected. Trying default model:", AI.DEFAULT_MODEL);
      for (let i = 0; i < this.models.length; i++) {
        console.log("Model:", this.models[i].id);
        if (this.models[i].id === AI.DEFAULT_MODEL) {
          console.log("Found default model:", AI.DEFAULT_MODEL);
          this.model = this.models[i].id;
          break;
        }
      }
      if (this.model === undefined || this.model === "") {
        console.error("Failed to find default model:", AI.DEFAULT_MODEL);
        this.model = this.models[0].id;
        console.log("Using first model:", this.model);
      }
    }
    return this.models;
  }

  public sortMessagesByCreated(messages: OpenAI.Beta.Threads.Messages.Message[], ascending: boolean = true): OpenAI.Beta.Threads.Messages.Message[] {
    return messages.sort((a, b) => (ascending) ? a.created_at - b.created_at : b.created_at - a.created_at);
  }

  public async fetchThreadMessages(id: string): Promise<OpenAI.Beta.Threads.Messages.Message[] | null> {
    let messages = await this.openai.beta.threads.messages.list(id);
    if (messages === undefined || messages.data === undefined || messages.data.length == 0) {
      console.error("Failed to retrieve messages for thread:", id);
      return null;
    }
    return messages.data;
  }

  /**
   * This request can only be made with a session token, not with an API key.
   * @returns 
   */
  public async retrieveAllThreads(): Promise<Thread[]> {
    const result: OpenAI.Beta.Threads.Thread[] = [];
    try {
      const response = await this.openai.beta.threads.retrieve("") as any;
      if (response.object === "list") {
        for (const thread of response.data) {
          result.push(thread as OpenAI.Beta.Threads.Thread);
        }
      }
    } catch (error) {
      console.error("Failed to retrieve threads:", error);
    }
    return result;
  }

  /**
   * Fetch thread list.
   * 
   * @returns 
   */
  public async fetchThreadList(force: boolean = false): Promise<Thread[]> {
    /*
        let threads = await this.retrieveAllThreads();
        if (threads != undefined && threads.length > 0) {
          console.log("Threads:", threads);
          this.threads = threads;
        }
    */

    if (!force && this.threads.length > 0) {
      console.log("Using previously saved threads list.");
      return this.threads;
    }

    console.log("Fetching threads list based on previously saved data...");
    for (let i = 0; i < this.threads.length; i++) {
      let thread = this.threads[i];
      let id = thread.id
      try {
        let thread: Thread = await this.openai.beta.threads.retrieve(id);
        if (thread === null) {
          console.log("Unable to retrieve thread:", id);
          this.threads[i] = { id: "", created_at: 0, metadata: null, object: 'thread' };
          continue;
        }
        console.log("Thread:", thread.id);
        const messages = await this.openai.beta.threads.messages.list(thread.id);
        if (messages === null || messages.data.length === 0) {
          console.log("Unable to retrieve messages for thread:", thread.id);
          thread.metadata = { title: "[Empty thread]" };
        } else {
          let message: any = messages.data.pop();
          if (message !== undefined && message.content[0].type === "text") {
            let title = (message.content[0] as TextContentBlock)?.text?.value?.substring(0, 128);
            thread.metadata = { title: title };
          }
        }
        this.threads[i] = thread;
      } catch (error) {
        console.log("Error retrieving thread:", id);
        console.error(error);
      }
    }
    // Remove empty threads
    this.threads = this.threads.filter((thread) => thread.id !== "");
    return this.threads;
  }

  /**
   * Get files list.
   * 
   * @returns 
   */
  public async fetchFilesList(): Promise<FileObject[]> {
    let openai = this.openai;
    let files: FileObjectsPage = await openai.files.list();
    if (files === undefined || files.data === undefined || files.data.length == 0) {
      console.error("Failed to retrieve files list!");
      return [];
    }
    this.files = files.data;
    return files.data;
  }

  /**
   * Delete file by OpenAI file id.
   * 
   * @param id 
   * @returns 
   */
  public async deleteFileById(id: string): Promise<Object> {
    let openai = this.openai;
    let result = await openai.files.del(id);
    if (result === undefined || result === null || result.deleted === false) {
      console.error("Failed to delete file:", id);
    }
    return result;
  }

  /**
   * Save file content.
   * 
   * @param id 
   * @returns 
   */
  public async retrieveFileContent(id: string): Promise<Response> {
    let openai = this.openai;
    let content = await openai.files.content(id);
    if (content === undefined || content === null || content.ok === false) {
      console.error("Failed to retrieve file content:", id);
      console.error(content);
    }
    return content;
  }

  /**
   * Configure AI service.
   * 
   * @param openai_settings 
   * @param assistant_settings 
   * @param event_emitter
   */
  async configure(
    openai_settings: OpenAISettings,
    assistants_local: any[] | undefined,
    event_emitter: EventEmitter | undefined): Promise<Assistant | ErrorObject> {

    if (!openai_settings) {
      let err: ErrorObject = {
        message: "OpenAI settings not provided!",
        code: null,
        param: null,
        type: ''
      };
      console.error(err.message);
      return err;
    }

    if (!assistants_local) {
      console.warn("No assistants settings provided!");
      assistants_local = [];
    }
    for(let i = 0; i < assistants_local.length; i++) {
      let assistant = assistants_local[i];
      if (assistant == null || assistant == undefined) {
        console.error("Invalid assistant settings detected");
        assistants_local = [];
        console.log("Restore default assistant settings");
        this.populateDefaultAssistant();
        break;
      }
    }

    // If resource is specified, it means we use Azure OpenAI service.
    const azure_settings: OpenAISettings | undefined = openai_settings.resource ? openai_settings : undefined;
    this.model = openai_settings.model ?? AI.DEFAULT_MODEL;
    this.instructions = AI.DEFAULT_INSTRUCTIONS;
    this.event_emitter = event_emitter;
    this.assistants_settings = assistants_local as AssistantSettings[] ?? [];

    if (azure_settings) {
      console.log("Using Azure OpenAI service settings:", azure_settings);
      await this.configureAzureOpenAI(azure_settings);
      await this.configureAzureCompletionsAPI(azure_settings);
    } else {
      console.log("Using vanilla OpenAI settings:", openai_settings);
      await this.configureVanillaOpenAI(openai_settings);
      // No hacks needed for vanilla OpenAI. It uses one consistent endpoint.
      this.openai_v1 = this.openai;
    }

    return await this.initializeAssistans();
  }

  /**
   * Configure Azure OpenAI service.
   * 
   * @param azure_settings 
   * @returns 
   */
  private async configureAzureOpenAI(azure_settings: OpenAISettings): Promise<OpenAI | ErrorObject> {
    const resource: string = azure_settings.resource!;
    this.apiVersion = azure_settings.apiVersion;
    this.openai = new OpenAI({
      apiKey: azure_settings.apiKey,
      baseURL: `https://${resource}.openai.azure.com/openai`,
      defaultQuery: { 'api-version': azure_settings.apiVersion },
      defaultHeaders: {
        'api-key': azure_settings.apiKey,
        'OpenAI-Beta': 'assistants=v1'
      },
      dangerouslyAllowBrowser: true
    });
    if (this.openai === undefined) {
      let err: ErrorObject = {
        message: "Failed to configure Azure OpenAI service!",
        code: null,
        param: null,
        type: ''
      };
      return err;
    }
    return this.openai;
  }

  /**
   * Configure vanilla OpenAI service.
   * 
   * @param openai_settings 
   * @returns 
   */
  private async configureVanillaOpenAI(openai_settings: OpenAISettings): Promise<OpenAI | ErrorObject> {
    this.apiVersion = "";
    this.openai = new OpenAI({
      apiKey: openai_settings.apiKey,
      baseURL: openai_settings.baseURL,
      dangerouslyAllowBrowser: true
    });
    if (this.openai === undefined) {
      let err: ErrorObject = {
        message: "Failed to configure OpenAI service!",
        code: null,
        param: null,
        type: ''
      };
      return err;
    }
    return this.openai;
  }

  /**
   * Get assistant tools.
   * 
   * @returns 
   */
  private getDefaultTools(): AssistantTool[] {
    return [
      { type: "code_interpreter" },
    ];
  }

  /**
   * Update existing assistant and save to Settings.
   * 
   * @param assistant_settings 
   * @returns 
   */
  private async updateExistingAssistant(assistant: Assistant, assistant_settings: AssistantSettings): Promise<Assistant> {
    console.log("Reusing and updating existing assistant");
    let body: AssistantUpdateParams = {
      name: assistant_settings.name,
      description: assistant_settings.description,
      model: assistant_settings.model,
      instructions: assistant_settings.instructions,
      file_ids: assistant_settings.file_ids,
      metadata: assistant_settings.metadata,
      tools: assistant_settings.tools
    };
    let updatedAssistant: Assistant = await this.openai.beta.assistants.update(assistant.id, body);
    for (let i = 0; i < this.assistants.length; i++) {
      if (this.assistants[i].id == assistant.id) {
        this.assistants[i] = updatedAssistant;
        break;
      }
    }
    if (this.settings !== undefined) {
      this.settings.assistants = this.assistants;
      this.saveAppSettings?.();
    }
    return updatedAssistant;
  }

  /**
   * Configure completions API for Azure OpenAI.
   * 
   * @param azure_settings 
   */
  private configureAzureCompletionsAPI(azure_settings: OpenAISettings) {
    // Azure OpenAI requires a separate endpoint for this.
    console.log("Configuring completions API for azure");
    const resource: string = azure_settings.resource!;
    this.openai_v1 = new OpenAI({
      apiKey: azure_settings.apiKey,
      baseURL: `https://${resource}.openai.azure.com/openai/deployments/${this.model}`,
      defaultQuery: { 'api-version': azure_settings.apiVersion },
      defaultHeaders: {
        'api-key': azure_settings.apiKey
      },
      dangerouslyAllowBrowser: true
    });
    console.log("Configured completions:", this.openai_v1);
  }

  /**
   * Get assistant tools and transform them into a more usable format.
   * @param assistant 
   * @returns 
   */
  public static getTools(assistant?: Assistant): Tools {
    if (!assistant) return { codeRemote: false, codeLocal: false, retrieval: false, cloudFiles: false, functions: [] };
    return {
      codeRemote: assistant?.tools.find((tool) => tool.type === "code_interpreter") !== undefined,
      codeLocal: assistant?.tools.find((tool) => (tool.type === "function" && tool.function.name === "code_interpreter")) !== undefined,
      retrieval: assistant?.tools.find((tool) => tool.type === "retrieval") !== undefined,
      cloudFiles: assistant?.tools.find((tool) => (tool.type === "function" && tool.function.name === "files")) !== undefined,
      functions: (assistant?.tools.filter((tool) => tool.type === "function").map((tool) => (tool as FunctionTool).function.name) ?? []) as string[]
    };
  }

  public static populateDefaultMetadata(metadata: AssistantMetadata): AssistantMetadata {
    let defaultMetadata: any = {
      "id": "WZCML9",
      "avatar": "https://openai.com/content/images/2021/05/Logo-1.png",
      "avatar_3d": "/avatars/661796ec54c2882b31ba0ad1.glb", // "https://models.readyplayer.me/661796ec54c2882b31ba0ad1.glb",
      "gender": "female",
      "age": "25",
      "name": "Ava",
      "location": "US",
      "language": "English",
      "voice": "English (US)",
      "identity": "She/Her",
      "skills": "conversation,psychology,gaming,industry,science,technology,mathematics,physics,chemistry,biology,medicine,engineering,programming,art,design,history,geography,philosophy,religion,literature,linguistics,education,law,politics,economics,psychology,sociology,anthropology,archaeology,ethnography",
    };
    for (const key in defaultMetadata) {
      if (metadata[key] === undefined || metadata[key] === "") {
        metadata[key] = defaultMetadata[key];
      }
    }
    return metadata;
  }

  public static getMetadata(assistant?: Assistant): AssistantMetadata {
    let metadata: any = assistant?.metadata ?? {};

    // TEMPORARY FIX: If metadata is empty, populate it with default values.
    if (metadata.id === undefined || metadata.id === "") {
      return AI.populateDefaultMetadata(metadata);
    }

    let assistantMetadata: AssistantMetadata = {
      id: metadata?.id ?? "",               // Assistant Metadata ID
      avatar: metadata?.avatar ?? "",       // URL to the avatar image
      avatar_3d: metadata?.avatar_3d ?? "", // URL to the 3D avatar image
      gender: metadata?.gender ?? "",       // Assistant gender
      age: metadata?.age ?? "",             // Assistant age
      name: metadata?.name ?? "",           // Assistant friendly name
      location: metadata?.location ?? "",   // Assistant location
      language: metadata?.language ?? "",   // Assistant preferred language
      voice: metadata?.voice ?? "",         // Assistant preferred voice
      dob: metadata?.dob ?? "",             // Assistant date of birth
      identity: metadata?.identity ?? "",   // Assistant identity
      skills: metadata?.skills ?? ""       // Assistant skills
    };
    return assistantMetadata;
  }

  public async setMetadata(assistant: Assistant, metadata: AssistantMetadata) {
    assistant.metadata = metadata;
    let assistant_id = 0;
    for (let i = 0; i < this.assistants.length; i++) {
      if (this.assistants[i].id == assistant.id) {
        assistant_id = i;
        break;
      }
    }
    this.assistants_settings[assistant_id].metadata = metadata;
    await this.updateExistingAssistant(assistant, this.assistants_settings[assistant_id]);
  }

  /**
   * Update assistant tools from Tools object.
   * Supported tools: code_interpreter, retrieval
   * TODO - not yet supported tools: code_local, cloud_files, functions.
   * @param assistant 
   * @param tools 
   */
  public static setTools(assistant: Assistant, tools: Tools) {
    if (tools.codeRemote) {
      if (!assistant.tools.find((tool) => tool.type === "code_interpreter")) {
        assistant.tools.push({ type: "code_interpreter" });
      }
    } else {
      assistant.tools = assistant.tools.filter((tool) => tool.type !== "code_interpreter");
    }
    if (tools.retrieval) {
      if (!assistant.tools.find((tool) => tool.type === "retrieval")) {
        assistant.tools.push({ type: "retrieval" });
      }
    } else {
      assistant.tools = assistant.tools.filter((tool) => tool.type !== "retrieval");
    }
  }

  /**
   * Text to speech implementation.
   * 
   * @param text 
   * @param model 
   * @param voice 
   */
  public async speak(text: string): Promise<SpeechSegment>
  {
    if (text === undefined || text === "")
      return NullSpeechSegment;

    // TODO: make this configurable
    this.speechModel = "tts-1";
    this.speechVoice = "nova";
    try {
      console.log("TTS: ", text);
      let result = await this.openai.audio.speech.create(
        (typeof text === "string") ?
          {
            model: this.speechModel,
            voice: this.speechVoice as OpenAI.Audio.SpeechCreateParams["voice"],
            input: text
          } : text);
      return { buffer: result.arrayBuffer(), text: text };
    } catch (error) {
      console.log("Failed to create speech:", error);
    }
    return NullSpeechSegment;
  }

  /**
   * Text to speech queue implementation to handle long text blocks.
   * 
   * @param textblock 
   * @param force 
   * @returns 
   */
  public async speakChunk(textblock: string, force: boolean = false): Promise<SpeechSegment>
  {
    this.textToSpeechQueue.push(textblock);
    let text = this.textToSpeechQueue.join("");
    if (force) {
      // If force is true, we will write all pending text to the stream and clear the queue.
      /// Replace anything that looks like a code block with an empty string.
      text = text.replace(/```[^```]*```/g, "");
      this.textToSpeechQueue.length = 0;
      return this.speak(text);
    }

    let len = this.textToSpeechQueue.length;
    if (text.includes("```")) {
      text = text.replace(/```[^```]*```/g, "");
      if (text.includes("```")) {
        // Tricky case, wait for entire block. We do not want to read the text inside code blocks.
        return NullSpeechSegment;
      }
    };

    if (this.textToSpeechQueue[len - 1].endsWith(".") || this.textToSpeechQueue[len - 1].endsWith(":")) {
      // This covers trivial case when the full sentence ends with a dot or colon in the last chunk.
      this.textToSpeechQueue.length = 0;
      return this.speak(text);
    }

    // Now let's try to find the last dot or colon in the text.
    for (let i = len - 1; i >= 0; i--) {
      let chunk = this.textToSpeechQueue[i];

      // Punctuation tokens that end a sentence.
      let tokens = [".","!",":","?"];
      let token = "";
      let lastToken = -1;
      for(let j = 0; j < tokens.length; j++) {
        token = tokens[j];
        lastToken = chunk.lastIndexOf(token);
        if (lastToken != -1) break;
      }
      if (lastToken != -1) {
        let partial = chunk.substring(0, lastToken+1);
        this.textToSpeechQueue[i] = (chunk.length > lastToken + 1) ? chunk.substring(lastToken + 1) : "";
        let sliced = this.textToSpeechQueue.slice(0, i);
        let text = sliced.join("") + partial;
        // Now we need to add the remaining part back to the queue.
        this.textToSpeechQueue = this.textToSpeechQueue.slice(i + 1);
        return this.speak(text);
      }
    }
    return NullSpeechSegment;
  }
}

export { AI, FileAttachment as FileAttachment };
