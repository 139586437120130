import axios from 'axios';

export async function switchTheme({ theme }) {
    const styleOverride = document.getElementById('theme-override') as HTMLStyleElement || document.createElement('style');
    styleOverride.id = 'theme-override';

    const styleContent = (await axios.get(`/css/styles-${theme}.css`)).data;
    console.log("Loaded theme: ", theme, "size: ", styleContent.length);

    styleOverride.textContent = styleContent;

    function moveToLastInBody(elementId) {
        const element = document.getElementById(elementId);
        if (element) {
            document.body.appendChild(element);
        } else {
            console.log(`Element with ID ${elementId} not found.`);
        }
    }
    moveToLastInBody('theme-override');
}
