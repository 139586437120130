import { useEffect, useRef, useMemo, useState } from "react"
import { useGLTF, useFBX, useAnimations } from '@react-three/drei'
import { useControls } from 'leva'
import { Canvas, useFrame, useLoader } from "@react-three/fiber"
import { Mesh } from 'three';
import {
  LinearFilter,
  MeshStandardMaterial,
  Material,
  Vector2,
  Object3D,
  SkinnedMesh,
  Euler,
  Vector3,
  BufferGeometry,
  Skeleton,
  Group
} from 'three';
import type { ObjectMap, SkinnedMeshProps } from '@react-three/fiber';
import * as THREE from 'three'
import React from "react";
import { use } from "matter";

const targetNames = [
  "viseme_sil",
  "viseme_PP",
  "viseme_FF",
  "viseme_TH",
  "viseme_DD",
  "viseme_kk",
  "viseme_CH",
  "viseme_SS",
  "viseme_nn",
  "viseme_RR",
  "viseme_aa", // viseme_AA
  "viseme_E",
  "viseme_I",
  "viseme_O",
  "viseme_U"
];

export interface CustomNode extends Object3D {
  geometry: BufferGeometry;
  material: Material;
  skeleton: Skeleton;
  isMesh: boolean;
  morphTargetInfluences?: number[];
}

export const useSkinnedControls = (name, defaultValue, options) => {
  return useControls({
    [name]: {
      value: defaultValue,
      ...options,
      label: name,
    }
  })
}

interface AnimationsData {
  key: string;
  path: string;
}

interface TalkingAvatarProps {
  viseme: string;
  url: string;
  animations?: AnimationsData[];
}

/*
  const animationsData = [
    { key: "Boxing", path: "animations/Boxing.fbx" },
    { key: "Angry", path: "animations/Standing Arguing.fbx" },
    { key: "Dance", path: "animations/Tut Hip Hop Dance.fbx" },
    { key: "Idle", path: "animations/Standing Idle.fbx" }
  ]
*/

export const TalkingAvatar: React.FC<TalkingAvatarProps> = ({ viseme, animations, url }) => {
  // This reference will give us direct access to the mesh
  const rootRef = useRef(null);
  const { nodes, materials } = useGLTF(url);
  let prevViseme = "viseme_sil";

  const [activeViseme, setActiveViseme] = useState(viseme);
  useEffect(() => {
    setActiveViseme(viseme);
  }, [viseme]);

  const [animationsData, setAnimationsData] = useState(animations ?? []);

  /*
    const animationObjects = [];
    animationsData.forEach(({ key, path }) => {
      const { animations } = useFBX(path);
      animations[0].name = key;
      animationObjects[key] = animations[0];
    });
   */

  // const sttInterimVal = sttStore((state) => state.sttInterimVal)
  // const { actions } = useAnimations(Object.values(animationObjects), rootRef)

  const Wolf3D_Hair_Params = useSkinnedControls('Wolf3D_Hair', true, { min: 0, max: 15, step: 0.1 });
  const Wolf3D_Glasses_Params = useSkinnedControls('Wolf3D_Glasses', false, { min: 0, max: 1, step: 0.1 });
  const Viseme_Params = useSkinnedControls('Viseme', activeViseme, { options: targetNames });
  const Avatar_Rotation = useSkinnedControls('Rotation', false, { min: 0, max: 1, step: 0.1 });

  /*
    const { plauAudio, script } = useControls({
      plauAudio: false,
      script: {
        value: "dance",
        options: ["dance", "angry", "boxing"]
      }
    })
   */

  // const audio = useMemo(() => new Audio(`audios/${script}.mp3`), [script]);

  // const jsonFile = useLoader(THREE.FileLoader, `audios/${script}.json`);
  // const lipsync = JSON.parse(jsonFile);

  function asCustom(name: string): CustomNode {
    return nodes[name]! as CustomNode;
  }

  function asSkinned(name: string): SkinnedMeshProps {
    return nodes[name]! as unknown as SkinnedMeshProps;
  }

    useFrame(() => {
      // const currentAudioTime = audio.currentTime;
      if (rootRef.current) {
        // (rootRef.current as Mesh).rotation.x += 0.01;

        if (Avatar_Rotation['Rotation']) {
          (rootRef.current as Mesh).rotation.y += 0.005;
        }

        let activeViseme = Viseme_Params['Viseme'];
        if (prevViseme !== activeViseme) {
          if (asCustom("Wolf3D_Head").morphTargetInfluences === undefined) {
            console.log("morphTargetInfluences is undefined");
          }
          for(let i = 0; i < targetNames.length; i++) {
            asCustom("Wolf3D_Head").morphTargetInfluences![asSkinned("Wolf3D_Head").morphTargetDictionary![targetNames[i]]] = 0;
            asCustom("Wolf3D_Teeth").morphTargetInfluences![asSkinned("Wolf3D_Teeth").morphTargetDictionary![targetNames[i]]] = 0;
          }
          asCustom("Wolf3D_Head").morphTargetInfluences![asSkinned("Wolf3D_Head").morphTargetDictionary![activeViseme]] = 1;
          asCustom("Wolf3D_Teeth").morphTargetInfluences![asSkinned("Wolf3D_Teeth").morphTargetDictionary![activeViseme]] = 1;
          prevViseme = activeViseme;
        }
      }
    });

  /*
    useEffect(() => {
      audio.pause()
      const keywords = ['打拳擊', '生氣', '跳舞']
      let matchedKeyword = null
  
      for (const keyword of keywords) {
        if (sttInterimVal.includes(keyword)) {
          matchedKeyword = keyword
          break
        }
      }
  
      switch (matchedKeyword) {
        case '打拳擊': {
          actions['Boxing']?.play()
          audio.src = 'audios/boxing.mp3'
          audio.play()
          break
        }
        case '生氣': {
          actions['Angry']?.play()
          audio.src = 'audios/angry.mp3'
          audio.play()
          break
        }
        case '跳舞': {
          actions['Dance']?.play()
          audio.src = 'audios/dance.mp3'
          audio.play()
          break
        }
        default:
          actions['Idle']?.play()
      }
    }, [actions, sttInterimVal])
  
    useEffect(() => {
      if (plauAudio) {
        switch (script) {
          case 'boxing': {
            actions['Boxing']?.play()
            audio.play()
            break
          }
          case 'angry': {
            actions['Angry']?.play()
            audio.play()
            break
          }
          case 'dance': {
            actions['Dance']?.play()
            audio.play()
            break
          }
          default:
            actions['Idle']?.play()
        }
      } else {
        audio.pause()
      }
    }, [plauAudio, script])
  */

  return (
    <mesh ref={rootRef} castShadow receiveShadow>
      <meshStandardMaterial 
        metalness={0.6}   // Increase metalness for more reflectivity
        roughness={0.1}   // Lower roughness makes the surface smoother and more mirror-like
      />
      <group dispose={null}>
        <primitive object={nodes.Hips} />
        {Wolf3D_Hair_Params['Wolf3D_Hair'] && <skinnedMesh name="Wolf3D_Hair"
          geometry={asCustom("Wolf3D_Hair").geometry}
          material={materials.Wolf3D_Hair}
          skeleton={asCustom("Wolf3D_Hair").skeleton}
        />}
        {Wolf3D_Glasses_Params['Wolf3D_Glasses'] && <skinnedMesh
          name="Wolf3D_Glasses"
          geometry={asCustom("Wolf3D_Glasses").geometry}
          material={materials.Wolf3D_Glasses}
          skeleton={asCustom("Wolf3D_Glasses").skeleton}
        />}
        <skinnedMesh
          name="Wolf3D_Body"
          geometry={asCustom("Wolf3D_Body").geometry}
          material={materials.Wolf3D_Body}
          skeleton={asCustom("Wolf3D_Body").skeleton}
        />
        <skinnedMesh
          name="Wolf3D_Outfit_Bottom"
          geometry={asCustom("Wolf3D_Outfit_Bottom").geometry}
          material={materials.Wolf3D_Outfit_Bottom}
          skeleton={asCustom("Wolf3D_Outfit_Bottom").skeleton}
        />
        <skinnedMesh
          name="Wolf3D_Outfit_Footwear"
          geometry={asCustom("Wolf3D_Outfit_Footwear").geometry}
          material={materials.Wolf3D_Outfit_Footwear}
          skeleton={asCustom("Wolf3D_Outfit_Footwear").skeleton}
        />
        <skinnedMesh
          name="Wolf3D_Outfit_Top"
          geometry={asCustom("Wolf3D_Outfit_Top").geometry}
          material={materials.Wolf3D_Outfit_Top}
          skeleton={asCustom("Wolf3D_Outfit_Top").skeleton}
        />
        <skinnedMesh
          name="EyeLeft"
          geometry={asCustom("EyeLeft").geometry}
          material={materials.Wolf3D_Eye}
          skeleton={asCustom("EyeLeft").skeleton}
          morphTargetDictionary={asSkinned("EyeLeft").morphTargetDictionary}
          morphTargetInfluences={asSkinned("EyeLeft").morphTargetInfluences}
        />
        <skinnedMesh
          name="EyeRight"
          geometry={asCustom("EyeRight").geometry}
          material={materials.Wolf3D_Eye}
          skeleton={asCustom("EyeRight").skeleton}
          morphTargetDictionary={asSkinned("EyeRight").morphTargetDictionary}
          morphTargetInfluences={asSkinned("EyeRight").morphTargetInfluences}
        />
        <skinnedMesh
          name="Wolf3D_Head"
          geometry={asCustom("Wolf3D_Head").geometry}
          material={materials.Wolf3D_Skin}
          skeleton={asCustom("Wolf3D_Head").skeleton}
          morphTargetDictionary={asSkinned("Wolf3D_Head").morphTargetDictionary}
          morphTargetInfluences={asSkinned("Wolf3D_Head").morphTargetInfluences}
        />

        <skinnedMesh
          name="Wolf3D_Teeth"
          geometry={asCustom("Wolf3D_Teeth").geometry}
          material={materials.Wolf3D_Teeth}
          skeleton={asCustom("Wolf3D_Teeth").skeleton}
          morphTargetDictionary={asSkinned("Wolf3D_Teeth").morphTargetDictionary}
          morphTargetInfluences={asSkinned("Wolf3D_Teeth").morphTargetInfluences}
        />
      </group>
    </mesh>
  )
}
