import '@app/styles.css';
import { MsalProvider, useMsal, AuthenticatedTemplate } from '@azure/msal-react';
import React, { useEffect, useState, KeyboardEvent } from 'react';

import { loginRequest } from '@app/authConfig';
import { callMsGraph } from '@app/graph';
import { ProfileData } from '@components/ProfileData';
import { Button, Dialog, DialogBody, DialogContent, DialogOpenChangeData, DialogOpenChangeEvent, DialogSurface, DialogTitle } from "@fluentui/react-components";
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { GoogleLoginButton } from "react-social-login-buttons";
import { DialogOpenChangeEventHandler } from '@fluentui/react-dialog'
import App from '@app/App';
import { GoogleUserProfile } from '@components/GoogleAuth';
import { MicrosoftSignInOutButton } from '@components/MicrosoftAuth';
import { MICROSOFT_AUTH_SUCCESS, MICROSOFT_AUTH_TOKEN } from '@app/api/AppEvents';
import { GuestLoginButton } from '@components/GuestButton';
import { CloseButton } from '@components/CloseButton';

interface AuthDialogProps {
  app: App;
  open: boolean;
  onOpenChange: DialogOpenChangeEventHandler;
  msalInstance: PublicClientApplication;
}

/**
 * Renders information about the signed-in user or a button to retrieve data about the user
 */
const MicrosoftProfileContent = () => {
  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState(null);

  function RequestProfileData() {
    // Silently acquires an access token which is then attached to a request for MS Graph data
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        callMsGraph(response.accessToken).then((response) => setGraphData(response));
      });
  }

  return (
    <>
      <h5 className="card-title">Welcome {accounts[0]?.name}</h5>
      {graphData ? (
        <ProfileData graphData={graphData} />
      ) : (
        <Button onClick={RequestProfileData}>Profile Information</Button>
      )}
    </>
  );
};

export const ensureMicrosoftLogin = (instance: PublicClientApplication): boolean => {
  const currentAccounts = instance.getAllAccounts();
  if (instance?.getActiveAccount() !== null) {
    return true;
  }
  if (currentAccounts.length != 0) {
    instance?.setActiveAccount(currentAccounts[0]);
  }
  if (instance?.getActiveAccount() !== null) {
    return true;
  }
  return false;
};

const AuthDialog: React.FC<AuthDialogProps> = ({ app, open, onOpenChange, msalInstance: instance }) => {

  const checkMicrosoftLogin = () => {
    const currentAccounts = instance.getAllAccounts();
    console.log(currentAccounts);
    if (instance?.getActiveAccount() === null) {
      if (currentAccounts.length != 0) {
        instance?.setActiveAccount(currentAccounts[0]);
      }
    }
    if (instance?.getActiveAccount() !== null) {
      if (!isMicrosoftLogin) {
        setMicrosoftLogin(true);
      }
      if (isGoogleLogin) {
        setGoogleLogin(false);
      }
    }
  };

  const checkGoogleLogin = () => {
    if (!app.googAuth.isAuthenticated) {
      let isAuthenticated: boolean = app.googAuth.handleAuthenticationResponse();
      if (isAuthenticated) {
        app.googAuth.getUserProfile().then((profile: GoogleUserProfile) => {
          setUserName(profile.name!);
          setUserPicture(profile.picture!);
          if (!isGoogleLogin) {
            setGoogleLogin(isAuthenticated);
          }
        });
        if (isMicrosoftLogin) {
          setMicrosoftLogin(false);
        }
      }
    }
  }

  const msalEventCallback = (message) => {
    switch (message.eventType) {
      case EventType.SSO_SILENT_SUCCESS:
      case EventType.LOGIN_SUCCESS:
        console.log("AuthDialog: Microsoft login successful:", message);
        app.emit(MICROSOFT_AUTH_SUCCESS, message.payload);
        app.emit(MICROSOFT_AUTH_TOKEN, message.payload.accessToken);
        for (let scope in message.payload.scopes) {
          console.log("Scope: " + scope);
        }
        checkMicrosoftLogin();
        break;
      case EventType.ACQUIRE_TOKEN_SUCCESS:
        console.log("AuthDialog: Microsoft token acquired:", message);
        app.emit(MICROSOFT_AUTH_TOKEN, message.payload.accessToken);
        break;
      case EventType.SSO_SILENT_FAILURE:
      case EventType.LOGIN_FAILURE:
        console.log("AuthDialog: Microsoft login failed:", message);
        setMicrosoftLogin(false);
        break;
      case EventType.LOGOUT_SUCCESS:
        console.log("AuthDialog: Microsoft logout successful:", message);
        setMicrosoftLogin(false);
        break;
    }
  }

  const [isMicrosoftLogin, setMicrosoftLogin] = useState(false);
  const [isGoogleLogin, setGoogleLogin] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [userName, setUserName] = useState<string>("");
  const [userPicture, setUserPicture] = useState<string>("");

  const msalCallbackHandle = instance.addEventCallback(msalEventCallback);

  const hideDialog = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    console.log('Hiding authentication dialog');
    open = false;
    console.log("open: " + open);
    setIsOpen(false);
    console.log("isOpen: " + isOpen);
    onOpenChange(evt, { type: 'triggerClick', open: false, event: evt });
  }

  const continueAsGuest = () => {
    console.log('Continuing as guest');
    open = false;
    console.log("open: " + open);
    setIsOpen(false);
    console.log("isOpen: " + isOpen);
    let evt: DialogOpenChangeEvent = {} as unknown as DialogOpenChangeEvent;
    let data: DialogOpenChangeData = { type: 'triggerClick', open: false, event: evt } as unknown as DialogOpenChangeData;
    onOpenChange(evt, data);
  }

  /*
    useEffect(() => {
      checkGoogleLogin();
      checkMicrosoftLogin();
      return () => {
        if (typeof msalCallbackHandle === "string") {
          instance.removeEventCallback(msalCallbackHandle!);
        }
      }
    });
  */

  checkGoogleLogin();
  checkMicrosoftLogin();

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogSurface className='dialogsurface'>
        <DialogBody className='dialogbody'>
          {(isGoogleLogin || isMicrosoftLogin) &&
            <CloseButton onClick={(evt) => hideDialog(evt)} />}

          <DialogTitle className='dialogtitle'>
            {!isGoogleLogin && !isMicrosoftLogin && <div>Please introduce yourself:</div>}
          </DialogTitle>

          <DialogContent style={{ display: "grid", placeItems: "center", placeContent: "center" }}>
            {!isGoogleLogin &&
              <MsalProvider instance={instance}>
                <AuthenticatedTemplate>
                  <MicrosoftProfileContent />
                </AuthenticatedTemplate>
                <MicrosoftSignInOutButton instance={instance} />
              </MsalProvider>
            }
            {!isMicrosoftLogin && !isGoogleLogin &&
              <GoogleLoginButton
                style={{ borderRadius: '10px', position: 'relative', border: '2px ridge' }}
                onClick={() => app.googAuth.requestAuthentication()}>Sign in with Google</GoogleLoginButton>
            }
            {isGoogleLogin &&
              <div className="flex-container">
                <h5 className="card-title">Welcome {userName}!</h5>
                <img src={userPicture} alt='Google Profile Picture' className="avatar" />
                <GoogleLoginButton
                  style={{ borderRadius: '10px', position: 'relative', border: '2px ridge' }}
                  onClick={() => app.googAuth.logout()}>Logout</GoogleLoginButton>
              </div>
            }
            {!isGoogleLogin && !isMicrosoftLogin &&
              <GuestLoginButton
                style={{ borderRadius: '10px', position: 'relative', border: '2px ridge' }}
                onClick={() => continueAsGuest()} />
            }
          </DialogContent>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
}

export { AuthDialog };
