import React from "react";
import { createButton } from 'react-social-login-buttons'

const config = {
  activeStyle: { background: '#0b0b0b' },
  icon: Icon,
  style: { background: "#000000" },
  text: "Continue as Guest",
};

export const GuestLoginButton = createButton(config);

function Icon({ size, color }: { size: number | string; color: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill={color} x="0px" y="0px" viewBox="0 0 48 48">
      <circle cx={24} cy={24} r={12} fill="black" />
    </svg>
  );
}
