const SettingsFilename = "settings.json";
const Settings = {
  "name": "OpenAI Trainer",
  "version": "1.0.0",
  "openai": {
    "apiKey": "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",
    "model": "gpt-3.5-turbo"
  },
  "completions": {
    "stream": false,
    "model": "gpt-3.5-turbo"
  },
  "assistants": [
    {
      "id": "asst_zSVHN4WaRPWm2AHY0OLevPGA",
      "object": "assistant",
      "created_at": 0,
      "name": "AI Trainer Assistant",
      "description": "AI Trainer Assistant",
      "model": "gpt-3.5-turbo-0125",
      "instructions": "You are a helpful assistant.",
      "tools": [
        {
          "type": "code_interpreter"
        }
      ],
      "file_ids": [],
      "metadata": {
        "id": "WZCML9",
        "avatar": "https://openai.com/content/images/2021/05/Logo-1.png",
        "avatar_3d": "https://models.readyplayer.me/661796ec54c2882b31ba0ad1.glb",
        "gender": "female",
        "age": "25",
        "name": "Ava",
        "location": "US",
        "language": "English",
        "voice": "English (US)",
        "identity": "She/Her",
        "skills": "conversation,psychology,gaming,industry,science,technology,mathematics,physics,chemistry,biology,medicine,engineering,programming,art,design,history,geography,philosophy,religion,literature,linguistics,education,law,politics,economics,psychology,sociology,anthropology,archaeology,ethnography",
      }
    }
  ],
  "threads": [
    "thread_KmkSVghj2KGI8QEvHbvjDwsH"
  ],
  "topics": [
    {
      "id": "taskText",
      "name": "Text Generation",
      "description": "",
      "image": "",
      "document": ""
    },
    {
      "id": "taskImage",
      "name": "Image Generation",
      "description": "",
      "image": "",
      "document": ""
    },
    {
      "id": "taskVideo",
      "name": "Video Generation",
      "description": "",
      "image": "",
      "document": ""
    },
    {
      "id": "task3D",
      "name": "3D Reconstruction",
      "description": "",
      "image": "",
      "document": ""
    }
  ]
};

// @ts-nocheck
window.Settings = Settings;
window.SettingsFilename = SettingsFilename;

function loadAppSettings() {
  // Retrieve the JSON string from localStorage
  const storedObjectString = localStorage.getItem(SettingsFilename);
  if (storedObjectString != null) {
    console.log("loadAppSettings:", storedObjectString);
    // Deserialize the string back into an object
    Object.keys(Settings).forEach(key => delete Settings[key]);
    Object.assign(Settings, JSON.parse(storedObjectString));
  }
}
window.loadAppSettings = loadAppSettings;

function saveAppSettings() {
  // Serialize the object to a string and save it to localStorage
  const storedObjectString = JSON.stringify(Settings, null, 2);
  console.log("saveAppSettings:", storedObjectString);
  localStorage.setItem(SettingsFilename, storedObjectString);
}
window.saveAppSettings = saveAppSettings;

function loadSettings(filename)
{
  // Retrieve the JSON string from localStorage
  const storedObjectString = localStorage.getItem(filename);
  if (storedObjectString != null) {
    console.log("loadSettings:", storedObjectString);
    // Deserialize the string back into an object
    const settings = JSON.parse(storedObjectString);
    return settings;
  }
  return null;
}
window.loadSettings = loadSettings;

function saveSettings(filename, settings)
{
  // Serialize the object to a string and save it to localStorage
  const storedObjectString = JSON.stringify(settings, null, 2);
  console.log("saveSettings:", storedObjectString);
  localStorage.setItem(filename, storedObjectString);
}
window.saveSettings = saveSettings;

//
export { Settings, SettingsFilename, loadAppSettings, saveAppSettings, loadSettings, saveSettings};
