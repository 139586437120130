import '@app/styles.css';
import React, { useEffect, useState } from "react";
import { Delete24Regular, DocumentRegular, Save24Regular } from "@fluentui/react-icons";
import { TableBody, TableCell, TableRow, Table, TableHeader, TableHeaderCell, TableCellLayout, Button } from "@fluentui/react-components";
import { FileListProps, FileListTableColumn } from './FileCommonTypes';

export interface ThreadItem {
  threadId?: {
    label?: string;
    icon?: any
  };
  title?: {
    label?: string;
  }
  createdAt?: {
    label?: string;
    timestamp?: number;
  }
  lastUpdate?: {
    label?: string;
    icon?: any
  }
}

function toDateString(unixTimestamp: number): string {
  const date = new Date(unixTimestamp * 1000);
  const formattedDate = date.toLocaleString();
  return formattedDate;
}

export const AIThreadList: React.FunctionComponent<FileListProps> = ({ columns = [], ...props }: FileListProps) => {
  const [selectedId, setSelectedId] = useState<string>("");
  const [items, setItems] = useState<ThreadItem[]>(props.items ?? []);

  const onSelectRow = (event: React.SyntheticEvent<HTMLTableRowElement, Event>, id: string) => {
    let target = event.target as HTMLTableRowElement;
    setSelectedId(id);
    props.onSelect?.(id, "");
  };

  function onSave(event: React.SyntheticEvent<HTMLButtonElement, Event>, id: string) {
    console.log("AIThreadList.onSave: " + id);
    let target = event.target as HTMLTableRowElement;
    props.onSave?.(id, "");
  };

  function onDelete(event: React.SyntheticEvent<HTMLButtonElement, Event>, id: string) {
    console.log("AIThreadList.onDelete: " + id);
    let target = event.target as HTMLTableRowElement;
    props.onDelete?.(id, "");
  };

    useEffect(() => {
      if (props.items) {
        setItems(props.items);
      }
    }, [props, props.items]);

  columns = (columns.length > 0) ? columns : [
    { columnKey: "saveIcon", label: "", style: { width: "3%", overflow: "auto", fontSize: "1.5em" } },
    { columnKey: "deleteIcon", label: "", style: { width: "3%", overflow: "auto", fontSize: "1.5em" } },
    { columnKey: "threadId", label: "ThreadId", style: { width: "40%" } },
    { columnKey: "title", label: "Last Message", style: { alignItems: "flex-start", width: "30%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontSize: "1.5em" } },
    { columnKey: "createdAt", label: "Last updated", style: { width: "20%" } }
  ];

  return (
    <Table id="threadListTable" arial-label="Thread List" className="scrollable-table" sortable={true}>
      <colgroup>
        {columns.map((column, index) => (<col key={index} style={column.style} />))}
      </colgroup>
      <TableHeader className='scrollable-table_th'>
        <TableRow>
          {columns.map((column) => (<TableHeaderCell key={column.columnKey}>{column.label}</TableHeaderCell>))}
        </TableRow>
      </TableHeader>
      <TableBody>
        {items.map(({ threadId, title, createdAt }) => (
          <TableRow key={threadId?.label} itemID={threadId?.label}
            onClick={(event) => onSelectRow(event, threadId!.label!)}
            className={(selectedId === threadId?.label) ? ('scrollable-table_tr_selected') : ('scrollable-table_tr')}>
            <TableCell>
              <Button appearance='subtle' icon={<Delete24Regular />} onClick={(event) => onDelete(event, threadId!.label!)} />
            </TableCell>
            <TableCell>
              <Button appearance='subtle' icon={<Save24Regular />} onClick={(event) => onSave(event, threadId!.label!)} />
            </TableCell>
            <TableCell>
              <TableCellLayout>{threadId?.label}</TableCellLayout>
            </TableCell>
            <TableCell>
              <TableCellLayout>{title?.label}</TableCellLayout>
            </TableCell>
            <TableCell>{createdAt?.label}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}