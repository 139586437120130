import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, Toolbar, ToolbarButton, ToolbarDivider } from "@fluentui/react-components";
import { ArrowDownload24Regular, Save24Regular } from '@fluentui/react-icons';
import MonacoEditor, { monaco } from 'react-monaco-editor';
import App from '@app/App';
import { saveAppSettings } from '@app/Settings';
import * as AppEvents from '@app/api/AppEvents';
import { Utils } from '@api/Utils';
import { CloseButton } from '@components/CloseButton';

export enum EditorMode {
    Settings = 1,
    LocalFile = 2,
    RemoteFile = 3,
    ViewOnly = 4
  }

interface EditorDialogProps {
    app: App;
    open: boolean;
    editorFile: string;
    editorMode: EditorMode;
    editorText: string;
    dialogTitle: string;
    theme: string;
}

// See C:\build\aitrainer\node_modules\monaco-editor\esm\vs\editor\standalone\common\themes.js

const vsLightTheme2: monaco.editor.IStandaloneThemeData = {
    base: 'vs',
    inherit: true,
    colors: {
        'editor.foreground': '#000000',
        'editor.background': '#efefef',
    },
    rules: [
        { token: '', foreground: '000000', background: 'efefef' },
        { token: 'comment', foreground: '000000', fontStyle: 'italic' },
        { token: 'keyword', foreground: '0000ff' },
    ]
};

const vsDarkTheme2: monaco.editor.IStandaloneThemeData = {
    base: 'vs-dark',
    inherit: true,
    colors: {
        'editor.foreground': '#FFFFFF',
        'editor.background': '#000000',
    },
    rules: [
        { token: '', foreground: 'ffffff', background: '000000' },
        { token: 'comment', foreground: 'ffffff', fontStyle: 'italic' },
        { token: 'keyword', foreground: '0000ff' },
    ]
};

export const EditorDialog: React.FC<EditorDialogProps> = ({ app, ...props }) => {
    const editorHeight = window.screen.availHeight - 200;
    const editorWidth = window.screen.availWidth - 200;
    const [title, setTitle] = useState<string>(props.dialogTitle);
    const [open, setOpen] = useState<boolean>(props.open);
    const [editorMode, setEditorMode] = useState<EditorMode>(props.editorMode);
    const [editorText, setEditorText] = useState<string>(props.editorText);
    const [editorFile, setEditorFile] = useState<string>(props.editorFile);
    const [editorTheme, setEditorTheme] = useState<string>(props.theme);
    const [isSaveVisible, setIsSaveVisible] = useState<boolean>(true);
    const [isDownloadVisible, setIsDownloadVisible] = useState<boolean>(true);
    const editorRef = useRef<monaco.editor.IStandaloneCodeEditor | null>(null);

    useEffect(() => {
        setTitle(props.dialogTitle);
        setOpen(props.open);
        setEditorMode(props.editorMode);
        setEditorText(props.editorText);
        setEditorFile(props.editorFile);

        if (props.theme != editorTheme)
        {
            // Adjust alignment of the editor
            editorRef.current?.layout({width: editorWidth, height: editorHeight});
        }
        setEditorTheme(props.theme);
        switch (props.editorMode) {
            case EditorMode.Settings:
                setIsSaveVisible(true);
                setIsDownloadVisible(true);
                break;
            case EditorMode.LocalFile:
                setIsSaveVisible(true);
                setIsDownloadVisible(false);
                break;
            case EditorMode.RemoteFile:
                setIsSaveVisible(false);
                setIsDownloadVisible(true);
                break;
            case EditorMode.ViewOnly:
                setIsSaveVisible(false);
                setIsDownloadVisible(false);
                break;
        }
    }, [props, props.dialogTitle, props.open, props.editorMode, props.editorText, props.editorFile, props.theme]);
    
    const onEditorChange = useCallback((newValue: any, e: any) => {
        console.log('onEditorChange', newValue, e);
    }, []);

    const saveAndCloseEditor = useCallback(() => {
        console.log("Editor saved");
        editorRef.current?.getAction('save')?.run();
        console.log("Editor closed");
        app.hideDialog();
    }, [app]);

    function downloadEditorContent()
    {
        console.log("Editor download");
        // TODO: handle different file types
        Utils.downloadTextFile(editorFile, editorText, "text/json;charset=utf-8");
        // editorRef.current?.getAction('download')?.run();
    };

    const editorDidMount = useCallback((editorInstance: monaco.editor.IStandaloneCodeEditor | null, monaco: typeof import("monaco-editor")) => {
        console.log('editorDidMount', editorInstance);
        editorRef.current = editorInstance;

        monaco.editor.defineTheme('vs-dark2', vsDarkTheme2);
        monaco.editor.defineTheme('vs-light2', vsLightTheme2);
        monaco.editor.setTheme(editorTheme);

        if (editorInstance) {
            editorInstance.getModel()?.updateOptions({ tabSize: 2, insertSpaces: true });
            if (editorInstance.getAction('save') == null) {
                editorInstance.addAction(
                    {
                        id: 'save', label: 'Save', keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyS],
                        run: () => saveEditorContent()
                    });
            }
            editorInstance.focus();
        } else {
            console.log('Editor is null');
        }
    }, []);

    function saveEditorContent() {
        let editorText = editorRef.current?.getValue() ?? '';
        switch (editorMode) {
            case EditorMode.Settings:
                try {
                    JSON.parse(editorText);
                    // clean old settings
                    Object.keys(app.settings).forEach(key => delete app.settings[key]);
                    Object.assign(app.settings, JSON.parse(editorText));
                    saveAppSettings();
                    app.emit(AppEvents.SETTINGS_SAVED, app.settings);
                } catch (e) {
                    window.alert("Invalid JSON settings!");
                }
                break;
            case EditorMode.LocalFile:
                // TODO: check if we're running 'privileged', e.g. nwjs.
                // If so, then we can simply use the local filesystem to save.
                Utils.downloadTextFile(editorFile, editorText, "text/json;charset=utf-8");
                break;
        }
        console.log('Saved contents:', editorText);
    };

    useEffect(() => {
        const jsonModel = monaco.editor.createModel("", "json");
        return () => {
            jsonModel.dispose();
        };
    }, []);

    return (
        <Dialog open={open}>
            <DialogSurface className='dialogsurface'>
                <CloseButton onClick={() => app.dialogClose()} />
                <DialogBody id='editorDialogBody' className='dialogbody' style={{overflow: 'hidden'}}>
                    <DialogTitle className='dialogtitle'>
                        <Toolbar aria-label="Default" style={{position: "absolute", left:0, top:0, padding: "0.25em" }} >
                            { isSaveVisible && <ToolbarButton onClick={() => saveEditorContent()} aria-label="Save" appearance="primary" icon={<Save24Regular/>} />}
                            &nbsp;
                            { isDownloadVisible && <ToolbarButton onClick={() => downloadEditorContent()} aria-label="Download" appearance="primary" icon={<ArrowDownload24Regular/>} />}
                        </Toolbar>                        
                        {title}
                    </DialogTitle>
                    <DialogContent>
                    <MonacoEditor
                        width={editorWidth}
                        height={editorHeight}
                        language="json"
                        theme={editorTheme}
                        value={editorText}
                        options={{ selectOnLineNumbers: true }}
                        onChange={onEditorChange}
                        editorDidMount={editorDidMount}
                    />
                    </DialogContent>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
}
