import cmuDict from './cmudict.json';

interface CMUDict {
    [word: string]: string[];
}

const dictionary: CMUDict = cmuDict as CMUDict;

export function getPhonemes(word: string): string[] | undefined {
    return dictionary[word.toUpperCase()];
}

const phonemeToVisemeMap = {
//    Consonants (24):
    'B': 'PP',
    'CH': 'CH',
    'D': 'DD',
    'DH': 'DD',
    'F': 'FF',
    'G': 'KK',
    'HH': 'SS',
    'JH': 'CH',
    'K': 'KK',
    'L': 'LL',
    'M': 'NN',
    'N': 'NN',
    'NG': 'NN',
    'P': 'PP',
    'R': 'RR',
    'S': 'SS',
    'SH': 'SS',
    'T': 'DD',
    'TH': 'DD',
    'V': 'FF',
    'W': 'WW',
    'Y': 'WW',
    'Z': 'SS',
    'ZH': 'SS',
// Vowels (15):
    'AA': 'AA',
    'AE': 'AA',
    'AH': 'AA',
    'AO': 'O',
    'AW': 'O',
    'AY': 'AA',
    'EH': 'E',
    'ER': 'RR',
    'EY': 'E',
    'IH': 'I',
    'IY': 'I',
    'OW': 'O',
    'OY': 'O',
    'UH': 'U',
    'UW': 'U',
};

/*
- Silence and Similar: "sil", "sp" → "viseme_sil"
- Bilabial Plosives (p, b): "p", "b" → "viseme_PP"
- Labiodental Fricatives (f, v): "f", "v" → "viseme_FF"
- Dental Fricatives (th in "think", th in "that"): "th" → "viseme_TH"
- Alveolar Plosives (t, d): "t", "d" → "viseme_DD"
- Velar Plosives (k, g): "k", "g" → "viseme_kk"
- Post-Alveolar Affricates and Fricatives (ch, j, sh, zh): "ch", "j", "sh", "zh" → "viseme_CH"
- Sibilant Fricatives (s, z): "s", "z" → "viseme_SS"
- Alveolar Nasals (n): "n" → "viseme_nn"
- Alveolar Approximant (r): "r" → "viseme_RR"
- Open Back Unrounded Vowel (a, ah): "a", "ah" → "viseme_aa"
- Close Front Unrounded Vowel (e, eh): "e", "eh" → "viseme_E"
- Close Front Unrounded Vowel (i, ih): "i", "ih" → "viseme_I"
- Close-mid Back Rounded Vowel (o, oh): "o", "oh" → "viseme_O"
- Close Back Rounded Vowel (u, uh): "u", "uh" → "viseme_U"
*/
const phonemeToVisemeMapOculus = {
    // Vowels
    'AA': 'viseme_aa',
    'AE': 'viseme_aa',
    'AH': 'viseme_aa',
    'AO': 'viseme_O',
    'AW': 'viseme_O',
    'AY': 'viseme_I',
    'EH': 'viseme_E',
    'ER': 'viseme_RR',
    'EY': 'viseme_E',
    'IH': 'viseme_I',
    'IY': 'viseme_I',
    'OW': 'viseme_O',
    'OY': 'viseme_O',
    'UH': 'viseme_U',
    'UW': 'viseme_U',
    // Consonants
    'B': 'viseme_PP',
    'P': 'viseme_PP',
    'D': 'viseme_DD',
    'T': 'viseme_DD',
    'K': 'viseme_kk',
    'G': 'viseme_kk',
    'CH': 'viseme_CH',
    'JH': 'viseme_CH',
    'F': 'viseme_FF',
    'V': 'viseme_FF',
    'TH': 'viseme_TH',
    'DH': 'viseme_TH',
    'S': 'viseme_SS',
    'Z': 'viseme_SS',
    'SH': 'viseme_SS',
    'ZH': 'viseme_SS',
    'HH': 'viseme_SS',
    'M': 'viseme_nn',
    'N': 'viseme_nn',
    'NG': 'viseme_nn',
    'L': 'viseme_nn',
    'R': 'viseme_RR',
    'W': 'viseme_RR',
    'Y': 'viseme_I',
    // Special case for silence or pause
    'SIL': 'viseme_sil',
    'SP': 'viseme_sil'
};

/**
 * Maps a CMUDict phoneme (without stress) to Oculus viseme.
 * @param {string} phoneme - The phoneme to map.
 * @returns {string} The corresponding viseme.
 */
export function phonemeToVisemeOculus(phoneme: string) {
    let ph = phoneme.endsWith('0') || phoneme.endsWith('1') || phoneme.endsWith('2') ? phoneme.slice(0, -1) : phoneme;
    return phonemeToVisemeMapOculus[phoneme.toUpperCase()] || 'viseme_sil'; // Default to silence if unknown
}

export function phonemeToViseme(phoneme: string) {
    let ph = phoneme.endsWith('0') || phoneme.endsWith('1') || phoneme.endsWith('2') ? phoneme.slice(0, -1) : phoneme;
    return phonemeToVisemeMap[ph] || 'Sil';
}