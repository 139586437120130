import '@app/styles.css';
import { Button, Dialog, DialogBody, DialogContent, DialogSurface, DialogTitle, Popover, PopoverProps, PopoverSurface, PopoverTrigger } from "@fluentui/react-components";
import { DialogActions, DialogOpenChangeEventHandler } from '@fluentui/react-dialog'
import App from '@app/App';
import { FileList } from '@dialogs/FileList';
import { CloudFile, PreviewResult } from '@app/api/CloudStorage';
import React, { useEffect, useState } from "react";
import { Attach24Filled } from '@fluentui/react-icons';
import { CloseButton } from '@components/CloseButton';

interface FileListDialogProps {
  app: App;
  onOpenChange?: DialogOpenChangeEventHandler;
  items: CloudFile[]
  dirs: CloudFile[];
}

const FileListDialog: React.FC<FileListDialogProps> = ({...props}: FileListDialogProps) => {

  let parent = props.app;
  let selectedItem: CloudFile | undefined;

  const [dirs, setDirs] = useState<CloudFile[]>(props.dirs);
  const [selectedId, setSelectedId] = useState<string>("");
  const [noPreview, setNoPreview] = useState<boolean>(false);

  useEffect(() => {
    setDirs(props.dirs);
  }, [props.dirs]);

  const attachFile = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    console.log('Attaching file: ' + selectedId);
    let item = props.items.find((item) => item.id === selectedId);
    if (item === undefined) {
      console.error('Selected item not found: ' + selectedId);
    } else
    {
      parent.chatRoom?.attachCloudFile(item);
    }
    props.app.hideDialog();
  }

  const hideDialog = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    console.log('Hiding file list dialog');
    props.app.hideDialog();
  };

  const onDirectoryChange = (id: string, dirs: CloudFile[]) => {
    console.log('onDirectoryChange: ' + id);
    let isNewDirectory: boolean = true;
    for (let i = 0; i < dirs.length; i++) {
      if (dirs[i].id === id) {
        dirs = dirs.slice(0, i + 1);
        isNewDirectory = false;
        break;
      }
    }
    if (isNewDirectory) {
      // Add new directory to the list
      parent.storage!.getMetadataById(id).then((file: CloudFile) => {
        dirs.push(file);
        //// TODO: check if we need this since the dirs are already set in the parent
        setDirs(dirs);
        console.log("Navigating to path:", dirs.map((item) => item.name).join('/'));
        parent.showStorage(id, dirs);
      }).catch((error: any) => {
        console.error('Error getting metadata for file: ' + id, error);
      });
    } else {
      //// TODO: check if we need this since the dirs are already set in the parent
      setDirs(dirs);
      console.log("Navigating to path:", dirs.map((item) => item.name).join('/'));
      parent.showStorage(id, dirs);
    }
  };

  interface PreviewContentsProps
  {
    width: number;
    height: number;
  };

  const PreviewContents: React.FC<PreviewContentsProps> = (props) => 
  {
    let fileId: string = selectedId;
    let preview: PreviewResult;

    const [previewUrl, setPreviewUrl] = useState<string>("");
    const [previewMethod, setPreviewMethod] = useState<string>("popup");
    const [width, setWidth] = useState<number>(props?.width || 800);
    const [height, setHeight] = useState<number>(props?.height || 600);

    useEffect(() => {
      async function getPreview() {
        let result = await parent.storage!.getPreview(fileId);
        if (typeof result === 'string') {
          setPreviewUrl(result as string);
          setPreviewMethod("popup");
        } else {
          preview = result as PreviewResult;
          console.log("Preview: ", preview);
          let pv = preview.large ?? preview.medium ?? preview.small;
          if (pv)
          {
            setWidth(pv.width);
            setHeight(pv.height);
            setPreviewUrl(pv.url);
            setPreviewMethod("img");
          }
        }
      }
      console.log("Getting preview for file id: ", fileId);
      getPreview();
    }, []);

    if (previewUrl !== "")
    {
      if (previewMethod === "popup")
      {
        setNoPreview(true);
        // If we can't show preview, then show file editor in a popup window
        let screen = window.screen;
        let title: string = (selectedItem) ? selectedItem.name ?? "Preview" : "Preview";
        const left = (screen.width / 2) - (width / 2);
        const top = (screen.height / 2) - (height / 2);
        const popup = window.open(previewUrl, title, `scrollbars=yes,width=${width},height=${height},top=${top},left=${left}`);
        popup?.focus();
        return(<div></div>);
      } else {
        setNoPreview(false);
        return (
          <img key={`preview-${fileId}`} src={previewUrl} height={height} width={width} id="preview" title="Preview" />
        );
      }
    }
    return(<div>Loading...</div>);
  }

  const PreviewFile: React.FC = (props: PopoverProps) => (
    <Popover {...props}>
      <PopoverTrigger disableButtonEnhancement>
        <Button disabled={selectedId==""} appearance='secondary' icon={"🔍"}>Preview</Button>
      </PopoverTrigger>
      <PopoverSurface tabIndex={-1} hidden={noPreview}>
        <PreviewContents key={`preview-contents-${selectedId}`} width={800} height={600} />
      </PopoverSurface>
    </Popover>
  );

  interface PreviewContentsProps {
    width: number;
    height: number;
  }

  // 📎
  const AttachButtons: React.FC = () => {
    return (
      <DialogActions>
        <PreviewFile/>
        <Button disabled={selectedId==""} appearance='primary' icon={<Attach24Filled />} onClick={attachFile}>Attach</Button>
        <Button appearance='secondary' onClick={hideDialog}>Cancel</Button>
      </DialogActions>
    );
  };

  const OpenButtons: React.FC = () => {
    return (
      <DialogActions>
        <Button disabled={selectedId==""} appearance='primary' onClick={hideDialog}>Open</Button>
        <Button appearance='secondary' onClick={hideDialog}>Cancel</Button>
      </DialogActions>
    );
  };

  const onSelect = (id: string, item: CloudFile | undefined) => {
    console.log('onSelect: ' + id);
    if (item) {
      selectedItem = item;
      if (item.isFolder) {
        console.log("Directory selected: ", item.name, " id: ", id);
        // We can't select a directory as attachment
        setSelectedId("");
      } else {
        console.log("File selected: ", item.name, " id: ", id);
        setSelectedId(id);
      }
    }
  };

  // style={{ display: "grid", placeItems: "center", placeContent: "center" }}
  return (
    <Dialog open={parent.state.isStorage} onOpenChange={props.onOpenChange}>
      <DialogSurface className='dialogsurface'>
        <DialogBody className='dialogbody'>
          <CloseButton onClick={() => parent.dialogClose()} />
          <DialogTitle className='dialogtitle'>Cloud Files</DialogTitle>
          <DialogContent className="dialogcontent">
              <FileList items={props.items} dirs={dirs} onDirectoryChange={onDirectoryChange} onSelect={onSelect} />
          </DialogContent>
          <AttachButtons/>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
}

export { FileListDialog };
