import '@app/styles.css';

import { FileListProps, getFileIcon } from './FileCommonTypes';
import React, { PropsWithChildren, useEffect, useState } from "react";

import {
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableHeader,
  TableHeaderCell,
  TableCellLayout,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbButton,
  BreadcrumbDivider,
  partitionBreadcrumbItems,
  ButtonProps,
  makeStyles,
  shorthands,
  tokens,
  Button,
  Menu,
  MenuItemLink,
  MenuList,
  MenuPopover,
  MenuTrigger,
  useIsOverflowItemVisible,
  useOverflowMenu,
  Overflow,
  OverflowItem,
  OverflowDivider,
  Tooltip,
} from "@fluentui/react-components";

import { Link } from "@fluentui/react-components";
import type { LinkProps } from "@fluentui/react-components";

import type { PartitionBreadcrumbItems } from "@fluentui/react-components";

import { CloudFile } from '@app/api/CloudStorage';
import { Cloud24Regular, DocumentFolder16Regular } from '@fluentui/react-icons';
import { IButtonStyles } from '@fluentui/react';

interface DirectoryBreadCrumbsProps {
  dirs: CloudFile[];
  onDirectoryChange: (id: string, dirs: CloudFile[]) => void;
}

export const DirectoryBreadCrumbs: React.FunctionComponent<DirectoryBreadCrumbsProps> = ({ dirs, onDirectoryChange }: DirectoryBreadCrumbsProps) => {
  return (
    <div className='directorybreadcrumbs'><Cloud24Regular style={{verticalAlign: 'middle'}} />&nbsp;
      {dirs.map((item, index) => (
        <>
          <a href="#" key={item.id} onClick={(event) => onDirectoryChange(item.id!, dirs)}>{item.name}</a>
          {(index !== dirs.length - 1) ? <span><b> ▸ </b></span> : ""}
        </>
      ))}
    </div>
  );
};

export const FileList: React.FunctionComponent<FileListProps> = ({ columns = [], ...props }: FileListProps) => {
  const items = props.items as CloudFile[];

  const [selectedId, setSelectedId] = useState<string>("");

  function lookUpItem(id: string): CloudFile | undefined {
    return items?.find(item => item.id === id);
  }

  function onSelectRow(event: React.SyntheticEvent<HTMLTableRowElement, Event>, id: string) {
    let target = event.target as HTMLTableRowElement;
    props.onSelect?.(id, lookUpItem(id));
    setSelectedId(id);
  };

  function onSave(event: React.SyntheticEvent<HTMLButtonElement, Event>, id: string, filename: string) {
    console.log("FileList.onSave: " + id);
    let target = event.target as HTMLTableRowElement;
    props.onSave?.(id, filename);
  };

  function onDelete(event: React.SyntheticEvent<HTMLButtonElement, Event>, id: string) {
    console.log("FileList.onDelete: " + id);
    let target = event.target as HTMLTableRowElement;
    props.onDelete?.(id, "");
  };

  function toDateString(unixTimestamp): string {
    // Convert to milliseconds and create a Date object
    const date = new Date(unixTimestamp * 1000);
    // Format the date/time in a readable format
    const formattedDate = date.toLocaleString(); // Converts to local time string
    return formattedDate;
  }

  columns = (columns.length > 0) ? columns : [
    { columnKey: "icon", label: "", style: { width: "3%", overflow: "auto", fontSize: "1.5em" } },
    { columnKey: "name", label: "Filename", style: { alignItems: "flex-start", width: "30%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontSize: "1.5em" } },
    { columnKey: "datetime", label: "Created", style: { width: "15%" } },
    { columnKey: "size", label: "Size", style: { width: "20%" } },
  ];

  const formatSize = (size: number | undefined): string => {
    if (size === undefined) {
      return "";
    }
    if (Number.isNaN(size)) {
      return "";
    }
    const sizeKB = size / 1024;
    const sizeMB = sizeKB / 1024;
    const sizeGB = sizeMB / 1024;
    if (sizeGB > 1) {
      return sizeGB.toFixed(2) + " GB";
    }
    if (sizeMB > 1) {
      return sizeMB.toFixed(2) + " MB";
    }
    if (sizeKB > 1) {
      return sizeKB.toFixed(2) + " KB";
    }
    return size + " bytes";
  }

  const formatDate = (dateStr: string): string => {
    let date = new Date(dateStr);
    return date.toLocaleDateString();
  }

  return (
    <span>
      <DirectoryBreadCrumbs dirs={props.dirs!} onDirectoryChange={props.onDirectoryChange!} />
      <div className='dialog_1024x768'>
        <Table id="fileListTable" arial-label="File List table" className="scrollable-table" sortable={true}>
          <colgroup>
            {columns.map((column, index) => (
              <col key={index} style={column.style} />
            ))}
          </colgroup>
          <TableHeader className='scrollable-table_th'>
            <TableRow>
              {columns.map((column) => (
                <TableHeaderCell key={column.columnKey}>
                  {column.label}
                </TableHeaderCell>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {items?.map((item) => (
              <TableRow
                itemID={item.id}
                key={item.id}
                onClick={(event) => onSelectRow(event, item.id!)}
                className={(selectedId == item.id && item.isFile)?('scrollable-table_tr_selected'):('scrollable-table_tr')}>
                <TableCell>{(item.isFolder === true) ? "📁" : getFileIcon(item.name!)}</TableCell>
                <TableCell>{(item.isFolder === true) ?
                  <Button appearance="transparent" onClick={(event) => props.onDirectoryChange?.(item.id!, props.dirs)}
                    style={{ textAlign: 'left', justifySelf: 'left', justifyContent: 'left' }} >{item.name}</Button> :
                  <a href={item.webUrl!} target="_blank">{item.name}</a>}
                </TableCell>
                <TableCell>{formatDate(item.createdDateTime!)}</TableCell>
                <TableCell>{formatSize(item.size ?? undefined)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </span>
  );
}
