type Emotion = Record<string, number>;

export const EmotionHappy: Emotion = {
    mouthSmileLeft: 1,
    mouthSmileRight: 1,
    eyeSquintLeft: 0.3,
    eyeSquintRight: 0.3,
    browOuterUpLeft: 0.5,
    browOuterUpRight: 0.5,
};

export const EmotionSad: Emotion = {
    mouthFrownLeft: 1,
    mouthFrownRight: 1,
    eyeSquintLeft: 0.2,
    eyeSquintRight: 0.2,
    browDownLeft: 0.5,
    browDownRight: 0.5,
    eyesLookDown: 0.4,
};

export const EmotionAngry: Emotion = {
    browDownLeft: 1,
    browDownRight: 1,
    browInnerUp: 1,
    eyeSquintLeft: 0.5,
    eyeSquintRight: 0.5,
    mouthPressLeft: 0.75,
    mouthPressRight: 0.75,
    jawForward: 0.4,
};

export const EmotionSurprised: Emotion = {
    browOuterUpLeft: 1,
    browOuterUpRight: 1,
    eyeWideLeft: 1,
    eyeWideRight: 1,
    mouthOpen: 0.8,
    jawOpen: 0.4,
};

export const EmotionDisgusted: Emotion = {
    noseSneerLeft: 1,
    noseSneerRight: 1,
    mouthUpperUpLeft: 0.6,
    mouthUpperUpRight: 0.6,
    browDownLeft: 0.6,
    browDownRight: 0.6,
};

export const EmotionFearful: Emotion = {
    browInnerUp: 1,
    eyeWideLeft: 1,
    eyeWideRight: 1,
    mouthStretchLeft: 0.5,
    mouthStretchRight: 0.5,
    jawOpen: 0.7,
};

export const EmotionContempt: Emotion = {
    mouthCornerPullerLeft: 0.5, // Adjust if a corresponding node exists or use mouthLeft/mouthRight
    lipCornerDepressorLeft: 0.5, // Adjust if a corresponding node exists
    lipStretchLeft: 0.3, // Adjust if a corresponding node exists or use mouthLeft/mouthRight
};

export const EmotionNeutral: Emotion = {
    viseme_sil: 1,
};

export const EmotionConfused: Emotion = {
    browInnerUp: 0.75,
    browDownRight: 0.5,
    browDownLeft: 0.5,
    eyeLookUpLeft: 0.4,
    eyeLookUpRight: 0.4,
    mouthShrugUpper: 0.6
};

export const EmotionCurious: Emotion = {
    browInnerUp: 0.5,
    eyeWideLeft: 0.3,
    eyeWideRight: 0.3,
    mouthStretchLeft: 0.3,
    mouthStretchRight: 0.3,
    eyeLookUpLeft: 0.5,
    eyeLookUpRight: 0.5
};

export const EmotionSkeptical: Emotion = {
    browDownLeft: 0.8,
    mouthFrownRight: 0.6,
    noseSneerRight: 0.4,
    eyeLookInRight: 0.3
};

export const EmotionShy: Emotion = {
    eyeLookDownRight: 0.7,
    eyeLookDownLeft: 0.7,
    mouthClose: 0.5,
    browDownLeft: 0.3,
    browDownRight: 0.3
};

export const EmotionProud: Emotion = {
    chestUp: 1,  // Adjust if a corresponding node exists or use mouthUpperUpLeft/mouthUpperUpRight
    headUp: 0.8, // Adjust if a corresponding node exists
    mouthSmileLeft: 0.6,
    mouthSmileRight: 0.6,
    eyeSquintLeft: 0.4,
    eyeSquintRight: 0.4
};

export const EmotionEmbarrassed: Emotion = {
    cheekPuff: 0.8,
    mouthShrugLower: 0.5,
    eyeLookDownLeft: 0.5,
    eyeLookDownRight: 0.5,
    browInnerUp: 0.4
};

export const EmotionBored: Emotion = {
    eyeLookDownLeft: 0.6,
    eyeLookDownRight: 0.6,
    yawning: 0.8,  // Adjust if a corresponding node exists or use mouthOpen/jawOpen
    mouthShrugLower: 0.4
};

export const EmotionAwe: Emotion = {
    jawOpen: 0.7,
    mouthOpen: 0.6,
    eyeWideLeft: 0.8,
    eyeWideRight: 0.8,
    browOuterUpLeft: 0.7,
    browOuterUpRight: 0.7
};

/// Add enum for emotions
export enum Emotions {
    Happy,
    Sad,
    Angry,
    Surprised,
    Disgusted,
    Fearful,
    Contempt,
    Neutral,
    Confused,
    Curious,
    Skeptical,
    Shy,
    Proud,
    Embarrassed,
    Bored,
    Awe
}

/// Add a function to return a random emotion based on enum value
export function randomEmotion(): Emotion {
    let selector: number = Math.floor(Math.random() * 16);
    switch (selector) {
        case Emotions.Happy:
            return EmotionHappy;
        case Emotions.Sad:
            return EmotionSad;
        case Emotions.Angry:
            return EmotionAngry;
        case Emotions.Surprised:
            return EmotionSurprised;
        case Emotions.Disgusted:
            return EmotionDisgusted;
        case Emotions.Fearful:
            return EmotionFearful;
        case Emotions.Contempt:
            return EmotionContempt;
        case Emotions.Neutral:
            return EmotionNeutral;
        case Emotions.Confused:
            return EmotionConfused;
        case Emotions.Curious:
            return EmotionCurious;
        case Emotions.Skeptical:
            return EmotionSkeptical;
        case Emotions.Shy:
            return EmotionShy;
        case Emotions.Proud:
            return EmotionProud;
        case Emotions.Embarrassed:
            return EmotionEmbarrassed;
        case Emotions.Bored:
            return EmotionBored;
        case Emotions.Awe:
            return EmotionAwe;
        default:
            return EmotionNeutral;
    }
}