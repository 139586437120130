import React, { useEffect } from 'react';
import {
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
    Drawer,
    Divider,
    Button,
    makeStyles,
    shorthands,
    tokens,
} from "@fluentui/react-components";
import App from '@app/App';
import { AI } from '@api/AI';
import '@app/styles.css';
import { CloseButton } from '@components/CloseButton';
import { Dismiss24Regular } from '@fluentui/react-icons';

const SideMenuStyles = makeStyles({
    root: {
        ...shorthands.overflow("hidden"),
        display: "flex",
        height: "100%",
        opacity: "50%"
    },
    content: {
        ...shorthands.flex(1),
        ...shorthands.padding("16px"),

        display: "grid",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gridRowGap: tokens.spacingVerticalXXL,
        gridAutoRows: "max-content",
    },
    field: {
        display: "grid",
        gridRowGap: tokens.spacingVerticalS,
    },
});

interface Topic {
    id: string;
    name: string;
    description: string;
    image: string;
    document: string
}

interface SideMenuProps {
    parent: App;
    trainer: AI;
    open: boolean;
}

const MenuButtonStyle = {
    width: '100%',
    "align-items": "left",
    "text-align": "left",
    justifyContent: "flex-start",
    "border": "none",
    "margin-bottom": "16px",
    fontSize: '1.2rem'
};

const Settings48Regular: React.FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} viewBox="0 0 48 48"><path fill="currentColor" d="M24 16.5a7.5 7.5 0 1 0 0 15a7.5 7.5 0 0 0 0-15M19 24a5 5 0 1 1 10 0a5 5 0 0 1-10 0m.494 19.468c1.479.353 2.993.531 4.513.531a19.425 19.425 0 0 0 4.503-.534a1.94 1.94 0 0 0 1.474-1.672l.338-3.071a2.318 2.318 0 0 1 2.183-2.075c.367-.016.732.053 1.068.2l2.807 1.231a1.922 1.922 0 0 0 1.554.01c.247-.105.468-.261.65-.458a20.372 20.372 0 0 0 4.51-7.779a1.942 1.942 0 0 0-.7-2.133l-2.494-1.84a2.326 2.326 0 0 1 0-3.764l2.486-1.836a1.943 1.943 0 0 0 .7-2.138a20.325 20.325 0 0 0-4.515-7.777a1.936 1.936 0 0 0-2.192-.45l-2.806 1.236c-.29.131-.606.2-.926.2a2.338 2.338 0 0 1-2.32-2.088l-.34-3.06a1.937 1.937 0 0 0-1.5-1.681a21.663 21.663 0 0 0-4.469-.519a21.922 21.922 0 0 0-4.5.52a1.935 1.935 0 0 0-1.5 1.677l-.34 3.062a2.345 2.345 0 0 1-.768 1.488a2.526 2.526 0 0 1-1.569.6a2.3 2.3 0 0 1-.923-.194l-2.8-1.236a1.937 1.937 0 0 0-2.2.452a20.354 20.354 0 0 0-4.51 7.775a1.941 1.941 0 0 0 .7 2.137l2.488 1.836a2.344 2.344 0 0 1 .701 2.938a2.338 2.338 0 0 1-.7.829l-2.49 1.839a1.94 1.94 0 0 0-.7 2.135a20.333 20.333 0 0 0 4.51 7.782a1.929 1.929 0 0 0 2.193.454l2.818-1.237c.291-.128.605-.194.923-.194h.008a2.338 2.338 0 0 1 2.32 2.074l.338 3.057a1.94 1.94 0 0 0 1.477 1.673m8.064-2.345c-2.34.5-4.76.5-7.1 0l-.304-2.662a4.838 4.838 0 0 0-4.8-4.3h-.01a4.743 4.743 0 0 0-1.926.4l-2.446 1.073a17.84 17.84 0 0 1-3.56-6.147l2.165-1.6a4.84 4.84 0 0 0 0-7.791l-2.164-1.6a17.852 17.852 0 0 1 3.561-6.14l2.432 1.072a4.74 4.74 0 0 0 1.931.407h.01a4.85 4.85 0 0 0 4.81-4.312l.3-2.66a19.369 19.369 0 0 1 3.562-.376c1.187.015 2.37.14 3.534.375l.3 2.659a4.835 4.835 0 0 0 4.8 4.314a4.338 4.338 0 0 0 1.946-.41l2.43-1.071a17.83 17.83 0 0 1 3.562 6.141l-2.157 1.593a4.826 4.826 0 0 0 0 7.8l2.165 1.6a17.881 17.881 0 0 1-3.56 6.14l-2.44-1.07a4.82 4.82 0 0 0-6.748 3.9z"></path></svg>)
};

const SideMenu: React.FC<SideMenuProps> = (props: SideMenuProps) => {

    let trainer: AI = props.trainer;
    let parent: App = props.parent;

    // Set initial state
    const [open, setOpen] = React.useState(props.open);
    // Use effect to update state when props change
    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    function selectTask(element) {
        let id = element.target.id;
        switch (id) {
            default:
                window.alert(`Handler for task ${id} not implemented!`);
        }
    }

    function getTopics(): Topic[] {
        return (parent.settings.topics ?? []) as Topic[];
    }

    function getButtons() {
        let topics: Topic[] = getTopics();
        return (
            <>
                {topics.map((topic) => (
                    <Button
                        onClickCapture={(evt) => selectTask(evt)}
                        id={topic.id}
                        style={MenuButtonStyle}
                    ><img src={topic.image} /> &nbsp; {topic.name}
                    </Button>
                ))}
            </>
        );
    }

    function closeDrawer() {
        setOpen(false);
        parent.setState({ isSideMenu: false });
    }

    return (
        <Drawer type="overlay" separator open={open} onOpenChange={(_, { open }) => { if (!open) closeDrawer()} } >
            <DrawerHeader>
                <DrawerHeaderTitle action={<CloseButton onClick={() => closeDrawer()} />}></DrawerHeaderTitle>
            </DrawerHeader>
            <DrawerBody>
                Topics
                <Divider />
                {getButtons()}
                <Divider />
                <Button onClickCapture={() => parent.showSettingsEditor()} id='taskSettings' style={MenuButtonStyle} icon={<Settings48Regular />}>Settings</Button>
            </DrawerBody>
        </Drawer>
    );
};

export { SideMenu };
