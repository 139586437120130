export interface CloudFile {
    id?: string;
    isFile?: boolean;
    isFolder?: boolean;
    fileType?: string | null;
    createdBy?: any | null;
    createdDateTime?: string;
    eTag?: string | null;
    lastModifiedBy?: string | null;
    lastModifiedDateTime?: string;
    name?: string | null;
    parentReference?: any;
    webUrl?: string | null;
    size?: number | null;
    mimeType?: string | null;
    [key: string]: any;
}

export interface PreviewResult {
  id: string;
  large?: { height: number; url: string; width: number };
  medium?: { height: number; url: string; width: number };
  small?: { height: number; url: string; width: number };
}

export interface ICloudStorageService {

  // Method to get user details. Returns a promise that resolves with the user's information.
  // This method is useful for getting the user's profile information, such as name, email, etc.
  // Note that the return type is any because it depends on the specific cloud storage provider.
  // For example, OneDrive returns MS-User object, while Google Drive returns a G-User object.
  // We can define a common interface for user details if needed.
  getUserDetails(): Promise<any>;

  // Method to initialize the cloud storage client. Takes an access token as a parameter.
  // Token is used to authenticate the client with the cloud storage provider.
  // Token acquisition is handled by the authentication service elsewhere in the app.
  // Returns the initialized client object, which could be used to make API calls.
  // Note that the return type is any because it depends on the specific cloud storage provider.
  initClient(token: string | null | undefined): Promise<any>;

  // Method to list files in a directory. The path parameter can be used to specify
  // a directory path. Returns a promise that resolves with an array of file information.
  listFiles(path: string): Promise<CloudFile[]>;

  // Method to list files from the app-specific folder. Returns a promise that resolves
  // with an array of file information. This method is useful for listing files stored
  // in a specific location, such as an app-specific folder in the cloud storage provider.
  listFilesFromAppFolder(): Promise<CloudFile[]>;

  // Method to read a file by its identifier. Takes the file identifier as a parameter and
  // returns a promise that resolves with the file's contents as a buffer.
  readFile(fileId: string): Promise<ArrayBuffer>;

  // Method to read a file from a specific folder in the app's storage. Takes the file name
  // and returns a promise that resolves with the file's contents as a buffer.
  readFileFromAppFolder(filename: string): Promise<ArrayBuffer>;

  // Method to write (or update) a file. Takes the file identifier, file contents as a string,
  // and optionally a mimeType (to support different file types). Returns a promise that
  // resolves with the updated file's information.
  writeFile(filename: string, content: string): Promise<string|null>;

  // Method to write a file to a specific folder in the app's storage. Takes the file name
  // and file contents as a string. Returns a promise that resolves with the file's information.
  // This method is useful for storing files in a specific location, such as an app-specific folder.
  writeFileToAppFolder(filename: string, content: string): Promise<string|null>

  getMetadataById(fileId: string): Promise<CloudFile>;
  
  // Method to obtain the file name by its identifier. Takes the file identifier as a parameter.
  // getFilenameById(fileId: string): Promise<string>;

  getPreview(fileId: string): Promise<any>;

}

export function sortCloudFiles(files: CloudFile[]): CloudFile[] {
  return files.sort((a, b) => {
    // Prioritize folders over files
    if (a.isFolder && !b.isFolder) return -1;
    if (!a.isFolder && b.isFolder) return 1;

    // If both are folders or both are files, sort alphabetically by name
    // Assuming name is always defined, but adding checks to prevent errors
    const nameA = a.name?.toLowerCase() || '';
    const nameB = b.name?.toLowerCase() || '';

    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;

    return 0; // names are equal
  });
}