import React from 'react';
import ReactDOM from 'react-dom/client';
import App from '@app/App';
import { Canvas, useFrame, useLoader } from "@react-three/fiber"
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import { TalkingAvatar } from '@components/TalkingAvatar';
import { OrbitControls } from '@react-three/drei';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

///       
/// https://models.readyplayer.me/661796ec54c2882b31ba0ad1.glb?morphTargets=ARKit,Oculus+Visemes,mouthOpen,mouthSmile,eyesClosed,eyesLookUp,eyesLookDown&textureSizeLimit=1024&textureFormat=png
///
/// <TalkingAvatar viseme='A' url='/avatars/660a42e371dbbf09c6d79e6a.glb' />
function AvatarCanvas() {
  return (
    <Canvas shadows camera={{ position: [0, 0, 5], fov: 60 }}>
      <ambientLight intensity={0.5} />
      <pointLight position={[10, 10, 10]} />
      <spotLight 
        position={[5, 5, 5]} // Position the light above and to the side
        angle={0.3}          // Wider light cone
        penumbra={1}         // Soft edges of the spotlight
        intensity={2}        // Brighter light
        shadow-mapSize-width={2048}   // Higher resolution for shadows
        shadow-mapSize-height={2048}
        target-position={[0, 0, 0]}  // Targeting the center of the scene
      />
      <TalkingAvatar viseme='A' url='/avatars/661796ec54c2882b31ba0ad1.glb' />
      <OrbitControls />
    </Canvas>
  )
}

root.render(
  <Router>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/index.html" element={<App />} />
      <Route path="/avatar.html" element={<AvatarCanvas/>} />
    </Routes>
  </Router>
);