import React, { createContext, useContext, useEffect, useState } from 'react';
import { Button, Dropdown, Label, Input, Option, Textarea, Link, Switch, Card, Body1, Caption1, CardHeader, CardPreview, InfoLabel, ToolbarButton, Avatar, TabList, Tab, makeStyles, shorthands, tokens, TabValue, SelectTabEvent, SelectTabData } from "@fluentui/react-components";
import { Popover, PopoverTrigger, PopoverSurface } from '@fluentui/react-popover';
import { Bot24Filled, Save16Regular, Settings24Regular } from "@fluentui/react-icons";
import { AI } from '@api/AI';
import App from '@app/App';
import { Assistant } from 'openai/resources/beta/assistants/assistants';
import { Utils } from '@api/Utils';
import { CloseButton } from '@components/CloseButton';
import { use } from 'matter';
import { AvatarCreatorPage } from '@components/AvatarCreatorPage';

interface AssistantProps {
    assistant: Assistant | undefined;
    children: React.ReactNode;
    setContext?: any;
}

export const CurrentAssistantContext: React.Context<AssistantProps> = createContext({} as AssistantProps);

export const AssistantProvider: React.FC<AssistantProps> = ({ assistant, children }) => {
    function setContext(assistant: Assistant, open: boolean) {
        App.instance.settings.assistants[0] = assistant as any;
    }
    return (
        <CurrentAssistantContext.Provider value={{ assistant, children, setContext: setContext }}>
            {children}
        </CurrentAssistantContext.Provider>
    )
}

const tabStyles = makeStyles({
    root: {
        alignItems: "flex-start",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        ...shorthands.padding("50px", "20px"),
        rowGap: "20px",
    },
    panels: {
        ...shorthands.padding(0, "10px"),
        "& th": {
            textAlign: "left",
            ...shorthands.padding(0, "30px", 0, 0),
        },
    },
    propsTable: {
        "& td:first-child": {
            fontWeight: tokens.fontWeightSemibold,
        },
        "& td": {
            ...shorthands.padding(0, "30px", 0, 0),
        },
    },
});

function RadixIconsAvatar() {
    return (
<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 15 15"><path fill="currentColor" fillRule="evenodd" d="M.877 7.5a6.623 6.623 0 1 1 13.246 0a6.623 6.623 0 0 1-13.246 0M7.5 1.827a5.673 5.673 0 0 0-4.193 9.494A4.971 4.971 0 0 1 7.5 9.025a4.97 4.97 0 0 1 4.193 2.296A5.673 5.673 0 0 0 7.5 1.827m3.482 10.152A4.023 4.023 0 0 0 7.5 9.975a4.023 4.023 0 0 0-3.482 2.004A5.648 5.648 0 0 0 7.5 13.173c1.312 0 2.52-.446 3.482-1.194M5.15 6.505a2.35 2.35 0 1 1 4.7 0a2.35 2.35 0 0 1-4.7 0m2.35-1.4a1.4 1.4 0 1 0 0 2.8a1.4 1.4 0 0 0 0-2.8" clipRule="evenodd"></path></svg>
    );
}

export const AssistantCard: React.FC<{ onHideCard: any }> = ({ onHideCard }) => {
    const styles = tabStyles();
    const [selectedValue, setSelectedValue] = React.useState<TabValue>("AssistantSettings");
    const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
        setSelectedValue(data.value);
    };

    let app = App.instance;
    const { assistant } = useContext(CurrentAssistantContext);
    return (
        <Card appearance="subtle">
            <CardHeader image={
                <div>
                    <img src='img/avatar-128x128.png' alt="AI Assistant Avatar" />
                    <Button style={{ position: "absolute", "left": 0, "top": 0 }} icon={<Settings24Regular />} appearance="secondary" onClick={(evt) => {
                        onHideCard();
                        app.showSettingsEditor();
                    }} id='taskSettings' />
                    <CloseButton onClick={() => onHideCard()} />
                </div>}
                header={<Body1><b>{assistant?.name}</b><br /><br />Id: {assistant?.id}</Body1>}
                description={<Caption1>Created: {new Date(assistant?.created_at! * 1000).toLocaleString()}</Caption1>} />
            <CardPreview>
                <div className={styles.root}>
                    <TabList selectedValue={selectedValue} onTabSelect={onTabSelect}>
                        <Tab id="assistantSettings" value="AssistantSettings">Settings</Tab>
                        <Tab id="assistantMetadata" value="AssistantMetadata">Metadata</Tab>
                        <Button icon={<RadixIconsAvatar/>} onClick={() => app.showAvatarCreator()} appearance='transparent'>Avatar Creator</Button>
                    </TabList>
                    <div className={styles.panels}>
                        {selectedValue === "AssistantSettings" &&
                            <div role="tabpanel" className={styles.propsTable}>
                                <AssistantTable />
                            </div>
                        }
                        {selectedValue === "AssistantMetadata" &&
                            <div role="tabpanel" className={styles.propsTable}>
                                <AssistantMetadata />
                            </div>}
                    </div>
                </div>
            </CardPreview>
        </Card>
    )
};

export const AssistantPopover: React.FC = () => {
    let app = App.instance;
    const [isOpen, setIsOpen] = useState(false);
    return (
        <Popover withArrow open={isOpen} size="small">
            <PopoverTrigger>
                <ToolbarButton aria-label="Settings" icon={<Bot24Filled />} onClick={() => { setIsOpen(!isOpen); }} />
            </PopoverTrigger>
            <PopoverSurface>
                <AssistantCard onHideCard={() => setIsOpen(false)} />
            </PopoverSurface>
        </Popover>
    )
};

export const AvatarPopover: React.FC = () => {
    let app = App.instance;
    const [isOpen, setIsOpen] = useState(false);
    const { assistant } = useContext(CurrentAssistantContext);
    return (
        <Popover withArrow open={isOpen} size="small">
            <PopoverTrigger>
                <Avatar image={{ src: 'img/avatar-128x128.png' }} name="AI Tool" badge={{ status: "available" }}
                    activeAppearance='ring-shadow' about={assistant?.description ?? ""}
                    title={assistant?.name ?? "Assistant"}
                    onClick={() => { setIsOpen(!isOpen); }} />
            </PopoverTrigger>
            <PopoverSurface>
                <AssistantCard onHideCard={() => setIsOpen(false)} />
            </PopoverSurface>
        </Popover>
    )
};

export const AssistantMetadata: React.FC = () => {
    let app = App.instance;
    const { assistant } = useContext(CurrentAssistantContext);
    const [metadata, setMetadata] = useState(AI.getMetadata(assistant));
    useEffect(() => {
        setMetadata(AI.getMetadata(assistant));
    }, [CurrentAssistantContext]);

    const $m = (obj: any, key: string) => Utils.databind(obj, key, (obj) => {
        if (assistant) {
            console.log("Toggle state:", key, obj[key]);
            let i = app.settings.assistants.findIndex((a) => a.id == assistant?.id);
            app.settings.assistants[i] = assistant as any;
            setMetadata(AI.getMetadata(assistant));
        }
    });

    const onSaveMetadata = () => {
        if (assistant) {
            app.ai.setMetadata(assistant, metadata);
        }
    }

    return (
        <table className={"table-assistant"}>
            <tr><td><Label htmlFor="metadataId">Id:</Label></td>
                <td><Input id="metadataId" type="text" {...$m(metadata, 'id')} defaultValue={metadata?.['id'] ?? ""} className='width_200px' /></td>
            </tr>
            <tr><td><Label htmlFor="metadataAvatar">Avatar:</Label></td>
                <td><Input id="metadataAvatar" type="text" {...$m(metadata, 'avatar')} defaultValue={metadata?.['avatar'] ?? ""} className='width_200px' /></td>
            </tr>
            <tr><td><Label htmlFor="metadataAvatar3d">3D Avatar:</Label></td>
                <td><Input id="metadataAvatar3d" type="text" {...$m(metadata, 'avatar_3d')} defaultValue={metadata?.['avatar_3d'] ?? ""} className='width_200px' /></td>
            </tr>
            <tr><td><Label htmlFor="metadataGender">Gender:</Label></td>
                <td><Input id="metadataGender" type="text" {...$m(metadata, 'gender')} defaultValue={metadata?.['gender'] ?? ""} className='width_200px' /></td>
            </tr>
            <tr><td><Label htmlFor="metadataAge">Age:</Label></td>
                <td><Input id="metadataAge" type="text" {...$m(metadata, 'age')} defaultValue={metadata?.['age'] ?? ""} className='width_200px' /></td>
            </tr>
            <tr><td><Label htmlFor="metadataName">Name:</Label></td>
                <td><Input id="metadataName" type="text" {...$m(metadata, 'name')} defaultValue={metadata?.['name'] ?? ""} className='width_200px' /></td>
            </tr>
            <tr><td><Label htmlFor="metadataLocation">Location:</Label></td>
                <td><Input id="metadataLocation" type="text" {...$m(metadata, 'location')} defaultValue={metadata?.['location'] ?? ""} className='width_200px' /></td>
            </tr>
            <tr><td><Label htmlFor="metadataLanguage">Language:</Label></td>
                <td><Input id="metadataLanguage" type="text" {...$m(metadata, 'language')} defaultValue={metadata?.['language'] ?? ""} className='width_200px' /></td>
            </tr>
            <tr><td><Label htmlFor="metadataVoice">Voice:</Label></td>
                <td><Input id="metadataVoice" type="text" {...$m(metadata, 'voice')} defaultValue={metadata?.['voice'] ?? ""} className='width_200px' /></td>
            </tr>
            <tr><td><Label htmlFor="metadataDob">Date of Birth:</Label></td>
                <td><Input id="metadataDob" type="text" {...$m(metadata, 'dob')} defaultValue={metadata?.['dob'] ?? ""} className='width_200px' /></td>
            </tr>
            <tr><td><Label htmlFor="metadataIdentity">Identity:</Label></td>
                <td><Input id="metadataIdentity" type="text" {...$m(metadata, 'identity')} defaultValue={metadata?.['identity'] ?? ""} className='width_200px' /></td>
            </tr>
            <tr><td><Label htmlFor="metadataSkills">Skills:</Label></td>
                <td><Input id="metadataSkills" type="text" {...$m(metadata, 'skills')} defaultValue={metadata?.['skills'] ?? ""} className='width_200px' /></td>
            </tr>
            <th>
                <td colSpan={2}><Button icon={<Save16Regular />} appearance="primary" onClick={(event) => onSaveMetadata()}>Save Metadata</Button></td>
            </th>
        </table>
    )
}

export const AssistantTable: React.FC = () => {
    let app = App.instance;
    const { assistant } = useContext(CurrentAssistantContext);
    const [tools, setTools] = useState(AI.getTools(assistant));
    useEffect(() => {
        setTools(AI.getTools(assistant));
    }, [CurrentAssistantContext]);

    const $a = (obj: any, key: string) => Utils.databind(obj, key, (obj) => {
        let i = app.settings.assistants.findIndex((a) => a.id == assistant?.id);
        app.settings.assistants[i] = assistant as any;
    });

    const $t = (obj: any, key: string) => Utils.databind(obj, key, (obj) => {
        if (assistant) {
            console.log("Toggle tool state:", key, obj[key]);
            AI.setTools(assistant, obj);
            let i = app.settings.assistants.findIndex((a) => a.id == assistant?.id);
            app.settings.assistants[i] = assistant as any;
            setTools(AI.getTools(assistant));
        }
    });

    let models: string[] = [assistant?.model!];
    let functions: string[] = ["foo", "bar", "baz"];

    //    <tr><td>Base URL:</td><td>{trainer.openai?.baseURL}</td></tr>
    //    <tr hidden={trainer.apiVersion == ""}><td>API Version:</td><td>{trainer.apiVersion}</td></tr>

    return (
        <table className={"table-assistant"}>
            <tr><td><Label htmlFor="assistantName">Name:</Label></td>
                <td><Input id="assistantName" type="text" {...$a(assistant, 'name')} defaultValue={assistant?.name ?? ""} className='width_100' /></td>
            </tr>
            <tr><td><Label htmlFor="assistantModel">Model:</Label></td>
                <td><Dropdown id="assistantModel" {...$a(assistant, 'model')} className='width_100' defaultValue={assistant?.model ?? ""}>
                    {models.map((option) => (<Option key={option}>{option}</Option>))}
                </Dropdown></td>
            </tr>
            <tr><td><Label htmlFor="assistantInstructions">Instructions:</Label></td>
                <td><Textarea id="assistantInstructions" {...$a(assistant, 'instructions')} className='textarea-100' defaultValue={assistant?.instructions ?? ""}></Textarea></td>
            </tr>
            <tr><td><Label htmlFor="asssistantDescription">Description:</Label></td>
                <td><Textarea id="asssistantDescription" {...$a(assistant, 'description')} className='textarea-100' defaultValue={assistant?.description ?? ""}></Textarea></td>
            </tr>

            <tr>
                <td><InfoLabel info={<>
                    Code Interpreter enables the assistant to write and run code.
                    This tool can process files with diverse data and formatting,
                    and generate files such as graphs.<br />
                    <Link href="https://platform.openai.com/docs/assistants">Learn more</Link></>}>Code interpreter</InfoLabel></td>
                <td><Switch id="toolCodeRemote" {...$t(tools, 'codeRemote')} defaultChecked={tools.codeRemote} /></td>
            </tr>

            <tr>
                <td><InfoLabel info={<>
                    Local Code Interpreter enables the assistant to write and run
                    code in your browser sandbox, as well as execute functions
                    accessible in your on-premises environment.
                    This tool can process files with diverse data and formatting,
                    and generate files of various types.<br />
                    <Link href="https://platform.openai.com/docs/assistants">Learn more</Link></>}>Code interpreter (local)</InfoLabel></td>
                <td><Switch id="toolCodeLocal" label="Code interpreter (local)" {...$t(tools, 'codeLocal')} defaultChecked={tools.codeLocal} /></td>
            </tr>

            <tr>
                <td><InfoLabel info={<>
                    Retrieval enables the assistant with knowledge from files that
                    you or your users upload. Once a file is uploaded, the assistant
                    automatically decides when to retrieve content based on user
                    requests.<br />
                    <Link href="https://platform.openai.com/docs/assistants">Learn more</Link></>}>Retrieval</InfoLabel></td>
                <td><Switch id="toolRetrieval" label="Retrieval" {...$t(tools, 'retrieval')} defaultChecked={tools.retrieval} /></td>
            </tr>

            <tr>
                <td><InfoLabel info={<>
                    Cloud file access enables the assistant with knowledge from files
                    that are stored in your cloud storage (Microsoft OneDrive, Google
                    Drive, etc.). Once a file is uploaded to your cloud storage, the
                    assistant automatically decides when to retrieve content based on
                    user requests.<br />
                    <Link href="#">Learn more</Link></>}>Cloud File Access</InfoLabel></td>
                <td><Switch id="toolCloudFiles" label="Cloud file access" {...$t(tools, 'cloudFiles')} defaultChecked={tools.cloudFiles} /></td>
            </tr>

            <tr>
                <td><InfoLabel info={<>
                    Function calling lets you describe custom functions of your app or
                    external APIs to the assistant. This allows the assistant to
                    intelligently call those functions by outputting a JSON object
                    containing relevant arguments.<br />
                    <Link href="https://platform.openai.com/docs/assistants">Learn more</Link></>}>Functions</InfoLabel></td>
                <td>
                    <div style={{ visibility: tools.functions.length > 0 ? 'visible' : 'hidden' }}>
                        <Dropdown id="toolFunctions" className='width_100'>
                            {functions.map((option) => (<Option key={option}>{option}</Option>))}
                        </Dropdown>
                    </div>
                </td>
            </tr>
        </table>
    )
};
