import App from "@app/App";
import { IMessage } from "@components/ChatRoom";
import { AI } from "@api/AI";
import OpenAI from "openai";
import { ChatCompletion, ImagesResponse } from "openai/resources";
import { ImageFileContentBlock, Message, TextContentBlock } from "openai/resources/beta/threads/messages";
import { Utils } from "@api/Utils";
import { MDSection } from "@api/MDProcessor";
import { Phoneme, SpeechSegment, WebSpeechPlayer } from "@api/WebSpeechPlayer";
import { text } from "stream/consumers";
import { Speech } from "openai/resources/audio/speech";
import { SPEECH_ON_PHONEME } from "@api/AppEvents";

export class AIActions {

    speechPlayer: WebSpeechPlayer;
    useAudio: boolean = true;
    trainingData: MDSection[] = [];
    useAssistant: boolean = true;
    useDallE: boolean = false;
    app: App;
    ai: AI;

    /**
     * Create a new AIActions instance.
     * @param app 
     */
    constructor(app: App) {
        this.app = app;
        this.ai = app.ai;
        this.speechPlayer = new WebSpeechPlayer( (phoneme: Phoneme) => { this.OnPhoneme(phoneme) });
    }

    public OnPhoneme(phoneme: Phoneme): void
    {
        this.app.emit(SPEECH_ON_PHONEME, phoneme);
    }
    
    /**
     * Delete a file from the assistant.
     * @param id 
     */
    public async deleteAssistantFile(id: string) {
        let result = await this.app.ai.deleteFileById(id);
        // TODO: check result
        // Refresh file dialog
        this.app.showFiles();
    }

    /**
     * Check if the response is failed. If it is, add a message to the chat room
     * and return true. Otherwise, return false.
     * 
     * @param response 
     * @returns 
     */
    private checkFailedResponse(response: any): boolean {
        if (response === undefined || response == null) {
            console.log("RESPONSE FAILED");
            this.app.chatRoom?.addMessage({
                content: "# There was an error processing your request: ",
                role: 'assistant',
                type: 'received',
                info: 'Error'
            });
            return true;
        }
        return false;
    }

    /**
     * Check if the text contains any DALL-E related keywords. If it does, return true.
     * @param text 
     * @returns 
     */
    public evaluateIfDallE(text: string): boolean {
        const keywords = [
            "dalle",
            "dall-e",
            "generate image",
            "generate picture",
            "generate me a picture",
            "generate me an image of",
            "generate me an image with",
            "draw image",
            "draw picutre",
            "draw me a picture",
            "draw me a picture of",
            "draw me a picture with",
            "create image of",
            "create image with",
            "create image using",
            "create image from",
            "create an image variant from",
            "create an image variant of",
            "create an image variant using"
        ];
        const lowerText = text.toLowerCase();
        return keywords.some(keyword => lowerText.includes(keyword));
    }

    /**
     * Ask the assistant a question. If the text contains DALL-E related keywords,
     * send the request to DALL-E. Otherwise, send the request to the assistant.
     * If the assistant is not available, send the request to the completions endpoint.
     * Then handle the response.
     * 
     * @param text 
     * @param file_id 
     */
    public async ask(text: string, file_id: string = "") {
        console.log("REQUEST TO OPENAI:", text, file_id);

        if (this.useDallE || this.evaluateIfDallE(text)) {
            console.log("Sending request to DALL-E");
            let response: ImagesResponse | undefined = await this.app.ai.askDallE(text);
            if (response !== undefined) {
                this.handleImages(response);
            } else {
                window.alert("There was an error while trying to generate an image.");
            }
        } else if (this.useAssistant) {
            console.log("Sending request to Assistant");
            let useStreaming: boolean = true;
            let response: Message[] | null = await this.app.ai.askAssistant(text, file_id, useStreaming);
            if (!this.checkFailedResponse(response)) {
                this.handleMessages(response as Message[]);
            }
        } else {
            console.log("Sending request to Completions");
            let response: ChatCompletion | null = await this.app.ai.askCompletions(text, file_id);
            if (!this.checkFailedResponse(response)) {
                this.handleCompletions([response as ChatCompletion]);
            }
        }
        this.app.chatRoom?.stopProgress();
    }

    /**
     * Handle the images response from DALL-E.
     * Add the images to the chat room.
     * 
     * @param response 
     */
    private handleImages(response: ImagesResponse) {
        console.log("handleImages:", response);
        let chatMessages: IMessage[] = [];
        response.data.forEach((image: OpenAI.Images.Image) => {
            let chatMessage: IMessage = {
                role: 'assistant',
                type: 'received',
                content: "",
                mimetype: 'image/png',
                link: image.url
            };
            chatMessages.push(chatMessage);
        });
        this.app.chatRoom?.addMessages(chatMessages);
    }

    /**
     * Handle the messages response from the assistant.
     * Add the messages to the chat room.
     * 
     * @param response 
     */
    private handleMessages(response: Message[]) {
        console.log("handleMessages:", response);

        let chatMessages: IMessage[] = [];

        response.map((message: Message) => {
            if (message.status === 'incomplete') {
                let chatMessage: IMessage = {
                    role: 'assistant',
                    type: 'received',
                    content: message.incomplete_details?.reason ?? "",
                    info: 'Incomplete'
                };
                chatMessages.push(chatMessage);
            } else {
                for (let i = 0; i < message.content.length; i++) {
                    let chatMessage: IMessage = {
                        role: 'assistant',
                        type: 'received',
                        content: (message.content[i].type === 'text') ? (message.content[i] as TextContentBlock).text.value : message.incomplete_details?.reason ?? "",
                        image_file: (message.content[i].type === 'image_file') ? (message.content[i] as ImageFileContentBlock).image_file.file_id : undefined
                    };
                    chatMessages.push(chatMessage);
                }
            }
        });
        if (this.useAudio) {
            chatMessages.forEach((message: IMessage) => {
                if (message.content !== "") {
                    this.speakChunkToWebAudio(message.content, true);
                }
            });
        }
        this.app.chatRoom?.addMessages(chatMessages);
    }

    /**
     * Handle the completions response from the completions endpoint.
     * @param response 
     */
    private handleCompletions(response: ChatCompletion[]) {
        console.log("handleCompletions:", response);
        let chatMessages: IMessage[] = response.map((message: ChatCompletion) => {
            let chatMessage: IMessage = {
                role: 'assistant',
                type: 'received',
                content: ""
            };
            if (message.choices?.length === 0) {
                chatMessage.content = "Empty response from OpenAI.";
                chatMessage.info = 'Error';
            } else {
                chatMessage.content = message.choices?.[0]?.message?.content ?? "";
            }
            return chatMessage;
        });
        if (this.useAudio) {
            chatMessages.forEach((message: IMessage) => {
                if (message.content !== "") {
                    this.speakChunkToWebAudio(message.content, true);
                }
            });
        }
        this.app.chatRoom?.addMessages(chatMessages);
    }

    /**
     * Train the assistant with the training data from the markdown file.
     * Add the training data to the chat room and handle the response.
     * 
     * @param filename 
     * @param showTrainingProgress 
     */
    public async trainMarkdown(filename: string, showTrainingProgress: boolean = false) {
        this.trainingData = await this.app.ai.extractTrainingDataSections(filename);

        let messages: IMessage[] = [];
        this.app.setIsLoading(true);
        // Training - perform bulk update
        let i: number = 0;
        this.app.chatRoom?.setUpdateAllowed(false);
        this.trainingData.forEach((element) => {
            let text: string = element.text;
            console.log(`Item: ${i} - `, text);
            if (text != "") {
                // All training data sent as system at this time
                messages.push({ content: text, type: 'sent', role: 'system', info: 'Training Data', link: './' + filename });
            }
            i++;
        });
        this.app.chatRoom?.addMessages(messages);

        if (this.useAssistant) {
            let response: Message[] | null = await this.app.ai.train(this.trainingData) as Message[];
            if (response != null) {
                this.handleMessages(response);
            }
        } else {
            let response: ChatCompletion[] | null = await this.app.ai.train(this.trainingData, true) as ChatCompletion[];
            if (response != null) {
                this.handleCompletions(response);
            }
        }

        this.app.chatRoom?.setUpdateAllowed(true);
        this.app.setIsLoading(false);
        this.app.chatRoom?.forceUpdate();
    }

    /**
     * Train the assistant with the training data from the JSON file.
     */
    public async trainJSON() {
        let result = await Utils.uploadTextFile();
        let response = await this.ai.train(result);
        console.log('Training data response:', response);
    }

    public async speakChunkToWebAudio(textchunk: string, force: boolean = false) {
        if (textchunk === "") {
            return;
        }
        if (this.useAudio) {
            try {
                // console.log("Speaking chunk:", textchunk);
                let segment = this.ai.speakChunk(textchunk, force);
                this.speechPlayer.play(segment);
            } catch (error) {
                console.error("Error speaking chunk:", error);
            }
        }
    }

}
