import EventEmitter from 'events';
import * as AppEvents from '@app/api/AppEvents';
import { emit } from 'process';

export interface GoogleUserProfile {
    email?: string;
    family_name?: string;
    given_name?: string;
    id?: string;
    locale?: string;
    name?: string;
    picture?: string;
    verified_email?: boolean
}

export class GoogleOAuthProvider {
    
    public token: string = "";
    public tokenType: string = "";
    public expiresAt: number = 0;
    public isAuthenticated: boolean = false;

    public scopes: string[] = [
        'https://www.googleapis.com/auth/userinfo.profile', // Required to get the user's profile information.
        'https://www.googleapis.com/auth/userinfo.email',   // Required to get the user's email address.
        'https://www.googleapis.com/auth/drive.readonly',   // Required to read files from Google Drive.
        'https://www.googleapis.com/auth/drive.file',       // Required to read files from Google Drive.
        'https://www.googleapis.com/auth/drive.appdata'     // Required to read files from Google Drive.
    ];
    public readonly redirectUri: string = `${window.location.protocol}//${window.location.host}/index.html`;
    public readonly oauth2Endpoint: string = 'https://accounts.google.com/o/oauth2/v2/auth';
    public userProfile: GoogleUserProfile | null = null;

    constructor(public clientId: string, public eventRouter: EventEmitter | null = null)
    { }

    /**
     * Check if the user is authenticated by checking the URL fragment for the access token.
     * If the access token is found, extract the token and set the isAuthenticated flag to true.
     * If the access token is not found, check for an error in the URL fragment and log it.
     * 
     * @returns 
     */
    public handleAuthenticationResponse(): boolean {

        if (this.isAuthenticated && this.expiresAt > Date.now()) {
            console.log("GoogleAuth already authenticated:", this.token);
            return this.isAuthenticated;
        }

        this.isAuthenticated = false;

        // Extract the authentication parameters from the URL fragment
        const fragment = window.location.hash.slice(1);
        const params = new URLSearchParams(fragment);
        const GoogleAuth: any = Object.fromEntries(params);
        const expiresIn: number = parseInt(GoogleAuth.expires_in ?? "0");
        const { access_token: accessToken, token_type: tokenType, error, expires_at: expiresAt } = GoogleAuth;
        GoogleAuth.expires_at = Date.now() + expiresIn * 1000;

        if (!accessToken && !tokenType && !expiresIn && localStorage.getItem("GoogleAuth")) {
            const storedGoogleAuth = JSON.parse(localStorage.getItem("GoogleAuth")!);
            if (storedGoogleAuth && storedGoogleAuth.expires_at <= Date.now()) {
                // Access token has expired, remove it from the local storage.
                console.log("GoogleAuth expired:", storedGoogleAuth);
                localStorage.removeItem("GoogleAuth");
                this.isAuthenticated = false;
                this.eventRouter?.emit(AppEvents.GOOGLE_AUTH_EXPIRED);
                return this.isAuthenticated;
            }
            if (storedGoogleAuth) {
                // Access token found in the local storage, set the authentication flag to true.
                console.log("GoogleAuth loaded from settings:", storedGoogleAuth);
                this.token = storedGoogleAuth.access_token;
                this.tokenType = storedGoogleAuth.token_type;
                this.expiresAt = storedGoogleAuth.expires_at;
                this.isAuthenticated = true;
                this.eventRouter?.emit(AppEvents.GOOGLE_AUTH_SUCCESS);
                this.eventRouter?.emit(AppEvents.GOOGLE_AUTH_TOKEN, this.token);
                return this.isAuthenticated;
            }
        } else {
            // Access token found in the URL fragment.
            localStorage.setItem("GoogleAuth", JSON.stringify(GoogleAuth));
            console.log("GoogleAuth saved to settings:", GoogleAuth);
        }

        if (accessToken && tokenType && expiresIn) {
            // Access token found in the URL fragment, set the authentication flag to true.
            this.isAuthenticated = true;
            this.token = accessToken;
            this.tokenType = tokenType;
            this.expiresAt = parseInt(expiresAt);
            // Get the current URL without any parameters
            const urlWithoutParams = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
            // Replace the current URL in the browser history
            window.history.replaceState(null, '', urlWithoutParams);
            this.eventRouter?.emit(AppEvents.GOOGLE_AUTH_SUCCESS);
            this.eventRouter?.emit(AppEvents.GOOGLE_AUTH_TOKEN, this.token);
        } else {
            if (error) {
                console.error("Google authentication error:", error);
            }
        }

        return this.isAuthenticated;
    }

    /**
     * Request authentication by creating a <form> element and submitting it to the OAuth 2.0 endpoint.
     * The form contains the necessary parameters to authenticate the user and request access to the
     * specified scopes. The form is then appended to the document body and submitted to the OAuth 2.0
     * endpoint to initiate the authentication process. In case of success, the user will be redirected
     * to the redirect URI with the access token in the URL fragment, where it can be extracted and used
     * to authenticate the user. Method handleAuthenticationResponse() can be used to check if the user
     * has been authenticated successfully.
     */
    public requestAuthentication(): void {
        console.log("Google login clicked");

        // Create <form> element to submit parameters to OAuth 2.0 endpoint.
        const form = document.createElement('form');
        form.method = 'GET'; // Send as a GET request.
        form.action = this.oauth2Endpoint;

        const scopes = this.scopes.join(' ');
        // Parameters to pass to OAuth 2.0 endpoint.
        const params = {
            client_id: this.clientId,
            redirect_uri: this.redirectUri,
            response_type: 'token',
            scope: scopes,
            include_granted_scopes: 'true',
            state: 'pass-through value'
        };

        // Add form parameters as hidden input values.
        for (const [key, value] of Object.entries(params)) {
            const input = document.createElement('input');
            input.type = 'hidden';
            input.name = key;
            input.value = value;
            form.appendChild(input);
        }

        // Add form to page and submit it to open the OAuth 2.0 endpoint.
        document.body.appendChild(form);
        form.submit();
    }
 
    public async getUserProfile(): Promise<GoogleUserProfile | null>  {
        if (this.userProfile)
        {
            return this.userProfile;
        }
        let response = await fetch('https://www.googleapis.com/oauth2/v2/userinfo', {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${this.token}`,
                'Content-Type': 'application/json'
            },
        })
        .catch(error => {
            console.error('Error fetching Google user info:', error);
            return null;
        });

        this.userProfile = await response?.json().catch(error => {
            console.error('Error parsing Google user info:', error);
            return null;
        });
        console.log("Google user profile:", this.userProfile);
        return this.userProfile;
    }

    public logout(): void {
        fetch ('https://accounts.google.com/o/oauth2/revoke?token=' + this.token, {
            method: 'GET'
        }).then(response => {
            console.log("Google logout response:", response);
        });
        localStorage.removeItem("GoogleAuth");
        this.isAuthenticated = false;
        this.userProfile = null;
        this.token = "";
        this.tokenType = "";
        this.expiresAt = 0;
        window.location.reload();
    }

}
