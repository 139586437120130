import App from "@app/App";
import { CloseButton } from "@components/CloseButton";
import { Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, Spinner } from "@fluentui/react-components";
import React, { useContext, useEffect, useRef } from "react";

import { AvatarCreator, AvatarCreatorConfig, AvatarExportedEvent } from '@readyplayerme/react-avatar-creator';
import { Avatar, CAMERA, AvatarProps } from "@readyplayerme/visage";
import { useState } from "react";
import { CurrentAssistantContext } from "@components/AssistantInfo";
import { AI } from "@api/AI";
import { StandardLonghandProperties, Property } from "csstype";
import { Vector3 } from 'three';
import { Canvas, useFrame, useLoader } from "@react-three/fiber"

import { SPEECH_ON_PHONEME } from "@api/AppEvents";
import { Phoneme } from "@api/WebSpeechPlayer";
import _ from "lodash";
import * as Emotions from '@components/Emotions';
import { on } from "events";

const config: AvatarCreatorConfig = {
  clearCache: false,
  bodyType: 'fullbody',
  quickStart: false,
  language: 'en',
};

interface FloatingAvatarProps {
  width: Property.Width | undefined;
  height: Property.Width | undefined;
}

type Emotion = Record<string, number>;

export const FloatingAvatar: React.FunctionComponent<FloatingAvatarProps> = ({ width, height }) => {
  let app = App.instance;
  // const rootRef = useRef(null);
  const [avatarUrl, setAvatarUrl] = useState('');
  const [avatarWidth, setAvatarWidth] = useState(width ?? "100%");
  const [avatarHeight, setAvatarHeight] = useState(height ?? "100%");
  const [zoomTarget, setZoomTarget] = useState<Vector3>(CAMERA.CONTROLS.FULL_BODY.ZOOM_TARGET);
  const [emotion, setEmotion] = useState<Emotion>({ "viseme_sil": 1 });
  const [idle, setIdle] = useState(true);

  let blinkIntervalId: any = undefined;
  let idleIntervalId: any = undefined;

  function blink() {
    console.log("Blinking");
    let newEmotion: Emotion = emotion;
    newEmotion["eyeBlinkLeft"] = 1;
    newEmotion["eyeBlinkRight"] = 1;
    setEmotion(newEmotion);
    setTimeout(() => {
      // Open the eyes after a short duration
      let newEmotion: Emotion = emotion;
      newEmotion["eyeBlinkLeft"] = 0;
      newEmotion["eyeBlinkRight"] = 0;
      setEmotion(newEmotion);
    }, 150); // Duration eyes are closed, typically 100-200 milliseconds
  }

  function startBlinking() {
    stopBlinking();
    const blinkInterval = 4000; // Time in milliseconds between blinks, typically 3-5 seconds
    // Start blinking once and never stop
    blinkIntervalId = setInterval(() => { blink(); }, blinkInterval);
  }

  function stopBlinking() {
    if (blinkIntervalId!== undefined) {
      clearInterval(blinkIntervalId);
      blinkIntervalId = undefined;
    }
  }

  function morphEmotion(from: Emotion, to: Emotion, duration: number = 85) {
    let start = performance.now();
    let startEmotion = { ...from };

    function animate(time: number) {
      let progress = (time - start) / duration;
      if (progress > 1) progress = 1;

      for (let key of Object.keys({ ...from, ...to })) {
        let diff = (to[key] ?? 0) - (startEmotion[key] ?? 0);
        from[key] = (startEmotion[key] ?? 0) + diff * progress;
      }

      for (let key of Object.keys(from)) {
        if (to[key] === undefined && from[key] == 0) {
          delete from[key];
        }
      }

      setEmotion(from);

      if (progress < 1) {
        requestAnimationFrame(animate);
      }
    }

    requestAnimationFrame(animate);
  }

  // Event listener for phoneme events from the WebSpeechPlayer
  let lastSpeechTime = Date.now();
  app.on(SPEECH_ON_PHONEME, (phoneme: Phoneme) => {
    lastSpeechTime = Date.now();
    setIdle(false);
    setTimeout(() => {
      if (Date.now() - lastSpeechTime > 2000) {
        setIdle(true);
      }
    }, 5000);
    let newEmotion: Emotion = {};
    newEmotion[phoneme.viseme] = 1;
    morphEmotion(emotion, newEmotion);
  });

  // If we're not talking, then select a random emotion every 5 seconds
  idleIntervalId = setInterval(() => {
    if (idle) {
      let newEmotion: Emotion = Emotions.randomEmotion();
      morphEmotion(emotion, newEmotion, 500);
    }
  }, 5000);

  const { assistant } = useContext(CurrentAssistantContext);
  useEffect(() => {
    let metadata = AI.getMetadata(assistant);
    setAvatarUrl(metadata.avatar_3d ?? "");
  }, [CurrentAssistantContext]);

  useEffect(() => {
    if (avatarWidth !== width || avatarHeight !== height) {
      if (typeof width === "string" && width.includes("%")) {
        let w = parseInt(width.toString().replace("%", ""));
        let ZOOM_TARGET: Vector3 = new Vector3(-0.11 - w / 10, 1, -10);
        setZoomTarget(ZOOM_TARGET);
      }
      setAvatarWidth(width ?? "100%");
      setAvatarHeight(height ?? "100%");
    }
  }, [setAvatarWidth, setAvatarHeight]);

  startBlinking();

  /*
      const handleOnAvatarExported = (event: AvatarExportedEvent) => {
        setAvatarUrl(event.data.url);
      };
  */
  return (
    <>
      {avatarUrl && <Avatar
        cameraInitialDistance={5}
        cameraTarget={1}
        modelSrc={avatarUrl}
        shadows={true}
        halfBody={false}
        headMovement={true}
        cameraZoomTarget={zoomTarget}
        scale={1}
        emotion={emotion}
        idleRotation={true}
        className="floating-avatar-canvas"
        animationSrc={"/avatars/661796ec54c2882b31ba0ad1/StandingIdle.fbx"}
        style={{ position: 'fixed', top: 0, left: 0, width: width, height: height }}
      />}
    </>
  );
}

