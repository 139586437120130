import { ITheme, createTheme } from "@fluentui/react";
import { BrandVariants, Theme, createLightTheme } from "@fluentui/react-components";

export const lightTheme: ITheme = createTheme({
  palette: {
    themePrimary: '#FF0000', // Red
    themeLighterAlt: '#f3f9fd',
    themeLighter: '#d0e7f8',
    themeLight: '#a9d3f2',
    themeTertiary: '#5ca9e5',
    themeSecondary: '#1a86d9',
    themeDarkAlt: '#006cbe',
    themeDark: '#005ba1',
    themeDarker: '#004377',
    neutralLighterAlt: '#FFA500', // Orange
    neutralLighter: '#800080', // Purple
    neutralLight: '#00FFFF', // Cyan
    neutralQuaternaryAlt: '#4a4a4a',
    neutralQuaternary: '#515151',
    neutralTertiaryAlt: '#6f6f6f',
    neutralTertiary: '#c8c8c8',
    neutralSecondary: '#d0d0d0',
    neutralPrimaryAlt: '#dadada',
    neutralPrimary: '#000000',
    neutralDark: '#f4f4f4',
    black: '#FFD700', // Gold
    white: '#008000', // Dark Green
  },
});

const lightThemeVariants: BrandVariants = { 
  10: "#030203",
  20: "#191518",
  30: "#292227",
  40: "#362D35",
  50: "#433843",
  60: "#514452",
  70: "#5F5061",
  80: "#6D5D70",
  90: "#7B6A81",
  100: "#8A7791",
  110: "#9885A2",
  120: "#A693B3",
  130: "#B4A2C4",
  140: "#C2B1D6",
  150: "#D0C0E7",
  160: "#DECFF8"
};

export const light: Theme = {
  ...createLightTheme(lightThemeVariants), 
};

// Update the color values
light.colorBrandBackground = "#00001e";
light.colorBrandBackgroundHover = "#00005e";
light.colorBrandBackgroundPressed = "#0000FF"; // Blue
light.colorBrandBackgroundSelected = "#FF00FF"; // Magenta
light.colorBrandBackgroundStatic = "#FFFF00"; // Yellow

light.colorNeutralBackground1 = "#cccccc"; // Gray
light.colorNeutralBackground2 = "#800080"; // Purple
light.colorNeutralBackground3 = "#dddddd"; // Chat area
light.colorNeutralBackground4 = "#bbbbff"; // Blue

light.colorNeutralForeground1 = "#000000";
light.colorNeutralForeground2 = "#000050";
light.colorNeutralForeground3 = "#008000";
light.colorNeutralForeground4 = "#000020";
