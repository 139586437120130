import * as commonmark from 'commonmark';
import { Utils } from '@api/Utils';

interface MDSection {
    heading: string;
    content: string[];
    text: string;
    dataset: string;
}

const LIST_TYPE       = 'list';
const HEADING_TYPE    = 'heading';
const TEXT_TYPE       = 'text';
const PARAGRAPH_TYPE  = 'paragraph';
const ITEM_TYPE       = 'item';
const LINK_TYPE       = 'link';
const CODE_BLOCK_TYPE = 'code_block';
const CODE_TYPE       = 'code';

/**
 * Represents a Markdown Processor.
 */
class MDProcessor {

    private filename: string;
    private basedir: string;
    private markdownContent: string;
    private parser: commonmark.Parser;
    private parsed: any;

    /**
     * Creates an instance of MDProcessor.
     * @param {string} filename - The filename of the Markdown file.
     */
    constructor(filename: string) {
        this.filename = filename;
        this.basedir = '';
        this.markdownContent = '';
    }

    /**
     * Extracts sections from the Markdown file asynchronously.
     * @returns {Promise<MDSection[]>} - A promise that resolves to an array of MDSection objects.
     * @throws {Error} - If there is an error fetching the data.
     */
    public async extractSectionsAsync(): Promise<MDSection[]> {
        try {
            const content: string = await Utils.readFileAsync(this.filename) as string;
            this.markdownContent = content;
            this.parser = new commonmark.Parser();
            this.parsed = this.parser.parse(this.markdownContent);
            return this.extractSections();
        } catch (err) {
            console.log(err);
            throw new Error('Failed to fetch data!');
        }
    }

    /**
     * Extracts sections from the parsed Markdown content.
     * @returns {MDSection[]} - An array of MDSection objects.
     */
    private extractSections(): MDSection[] {

        const walker = this.parsed.walker();
        let event, currentHeading = '', currentLevel = 0;
        const sections: MDSection[] = [];

        while ((event = walker.next())) {
            const { node, entering } = event;

            if (node.type === HEADING_TYPE && entering) {
                currentHeading = node.firstChild.literal;
                currentLevel = node.level;
                const newSection: MDSection = { heading: currentHeading, content: [], text: '', dataset: '' };
                sections.push(newSection);
                walker.next();
                continue;
            }

            if (node.type === HEADING_TYPE && !entering && node.level <= currentLevel) {
                currentLevel = 0;
                continue;
            }

            if (sections.length === 0) {
                continue;
            }

            let txt = '';
            const lastSection: MDSection = sections[sections.length - 1];

            if (currentLevel >= node.level) {
                if (entering) {
                    txt = node.literal ?? '';
                    switch (node.type) {
                        case LIST_TYPE:
                        case HEADING_TYPE:
                        case PARAGRAPH_TYPE:
                            txt = '\n';
                            continue;
                        case ITEM_TYPE:
                            txt = '\n-';
                            break;
                        case LINK_TYPE:
                            const linkUrl: string = node.destination;
                            lastSection.dataset = linkUrl;
                            txt = '';
                            walker.next();
                            break;
                        case CODE_BLOCK_TYPE:
                            if (node.info === 'response') {
                                continue;
                            }
                            txt = txt.trim();
                            break;
                        case CODE_TYPE:
                            txt = '`' + txt + '`';
                            break;
                        default:
                            txt = txt.trim();
                            break;
                    }
                } else {
                    switch (node.type) {
                        case LIST_TYPE:
                            txt = '\n\n';
                            break;
                        case TEXT_TYPE:
                            txt = '';
                            break;
                        case PARAGRAPH_TYPE:
                            txt = '';
                            break;
                    }
                }
                if (txt !== '') {
                    lastSection.content.push(txt);
                }
            }
        }
        sections.forEach((section) => {
            let text: string = section.content.join(' ');
            text = text.replace(/\s+$/gm, '');
            section.text = text;
        });
        return sections;
    }
}

export { MDSection, MDProcessor };
