import * as React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "@app/authConfig";
import { MicrosoftLoginButton } from "react-social-login-buttons";
import { useIsAuthenticated } from '@azure/msal-react';
import { PublicClientApplication } from "@azure/msal-browser";
import { useContext } from "react";
import { AppContext } from "@components/AppContext";

interface AuthButtonProps {
    instance: PublicClientApplication;
}

const defaultLoginKind = "redirect";

export const MicrosoftSignInButton: React.FC<AuthButtonProps> = (props, context) => {
    const instance: PublicClientApplication = props.instance;

    const handleLogin = loginType => {
        console.log("Handling login: ", loginType);
        console.log("Instance: ", instance);
        if (loginType === "popup") {
            instance.loginPopup(loginRequest).catch(e => {
                // Handle the error if needed
            });
        } else if (loginType === "redirect") {
            instance.loginRedirect(loginRequest).catch(e => {
                // Handle the error if needed
            });
        }
    };

    return (
        <MicrosoftLoginButton
            activeStyle={{ background: '#0000EE' }}
            style={{ background: "#000080", borderRadius: '10px', position: 'relative', border: '2px ridge' }}
            onClick={() => handleLogin(defaultLoginKind)}>
            Sign in with Microsoft
        </MicrosoftLoginButton>
    );
};

export const MicrosoftSignOutButton: React.FC<AuthButtonProps> = (props) => {
    const instance: PublicClientApplication  = props.instance;

    const handleLogout = logoutType => {
        if (logoutType === "popup") {
            instance.logoutPopup({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/"
            });
        } else if (logoutType === "redirect") {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
        }
    };

    return (
        <MicrosoftLoginButton
            style={{ borderRadius: '10px', position: 'relative', border: '2px ridge' }}
            onClick={() => handleLogout(defaultLoginKind)}>
            Sign out
        </MicrosoftLoginButton>
    );
};


export const MicrosoftSignInOutButton: React.FC<AuthButtonProps> = (props) => {
    const appContext = useContext(AppContext); // Accessing the context value

    const isAuthenticated = useIsAuthenticated();
    appContext.isAuthenticated = isAuthenticated;
    const instance = props.instance;

    return (
        isAuthenticated ? <MicrosoftSignOutButton instance={instance} /> : <MicrosoftSignInButton instance={instance} />
    );
}