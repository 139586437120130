import '@app/styles.css';

import { FileItem, FileListProps, FileType, getFileIcon } from './FileCommonTypes';
import React, { ReactNode, useState } from "react";
import { mergeStyles } from '@fluentui/react';

import {
  Delete16Regular,
  Save16Regular,
  FolderRegular,
  EditRegular,
  OpenRegular,
  DocumentRegular,
  PeopleRegular,
  DocumentPdfRegular,
  VideoRegular,

  Document16Regular,
  DocumentPdf16Regular,
  TextBulletList16Regular,
  DocumentLandscapeRegular,
  DocumentText16Regular,
  FluentIcon
} from "@fluentui/react-icons";

// TODO: use "@fluentui/react-file-type-icons" ?

import {
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableHeader,
  TableHeaderCell,
  TableCellLayout,
  PresenceBadgeStatus,
  Avatar,
  Button,
} from "@fluentui/react-components";

export let AIFileListState: FileListProps = {};

export const AIFileList: React.FunctionComponent<FileListProps> = (defaultProps: FileListProps) => {

  AIFileListState = defaultProps;

  const [state, setState] = useState(AIFileListState);

  function onSelectRow(event: React.SyntheticEvent<HTMLTableRowElement, Event>, id: string) {
    let target = event.target as HTMLTableRowElement;
    let newState = AIFileListState;
    newState.selectedId = id;
    setState(newState);
  };

  function onSave(event: React.SyntheticEvent<HTMLButtonElement, Event>, id: string, filename: string) {
    console.log("AIFileList.onSave: " + id);
    let target = event.target as HTMLTableRowElement;
    AIFileListState.onSave?.(id, filename);
  };

  function onDelete(event: React.SyntheticEvent<HTMLButtonElement, Event>, id: string) {
    console.log("AIFileList.onDelete: " + id);
    let target = event.target as HTMLTableRowElement;
    AIFileListState.onDelete?.(id, "");
  };

  function toDateString(unixTimestamp): string {
    // Convert to milliseconds and create a Date object
    const date = new Date(unixTimestamp * 1000);
    // Format the date/time in a readable format
    const formattedDate = date.toLocaleString(); // Converts to local time string
    return formattedDate;
  }

  const columns = [
    { columnKey: "save", label: "" },
    { columnKey: "delete", label: "" },
    { columnKey: "filename", label: "Filename" },
    { columnKey: "purpose", label: "Purpose" },
    { columnKey: "created_at", label: "Created" },
    { columnKey: "size", label: "Size" }
  ];

  //  import { mergeStyles } from '@fluentui/react-components';

  // Column width styles
  const column1Style = mergeStyles({ width: 24, display: 'inline-block' });
  const column2Style = mergeStyles({ width: 24, display: 'inline-block' });
  const column3Style = mergeStyles({ width: 300, display: 'inline-block' });

  return (
    <Table id="fileListTable" arial-label="File List table">
      <colgroup>
        <col style={{ width: '32px' }} />
        <col style={{ width: '32px' }} />
        <col style={{ width: '512px' }} />
        <col style={{ width: '128px' }} />
        <col style={{ width: '192px' }} />
        <col style={{ width: '128px' }} />
      </colgroup>

      <TableHeader className="tableheader" hidden={true}>
        <TableRow>
          {columns.map((column) => (
            <TableHeaderCell key={column.columnKey}>
              {column.label}
            </TableHeaderCell>
          ))}
        </TableRow>
      </TableHeader>

      <TableBody className="tablebody">
        {state.items?.map((item) => (
          <TableRow itemID={item.id} key={item.id} onClick={(event) => onSelectRow(event, item.id)} appearance={(state.selectedId == item.id) ? ("brand") : ("neutral")}>
            <TableCell className={column1Style}>
              <TableCellLayout>
                {item.purpose == "assistants_output" && <Button icon={<Save16Regular />} appearance="subtle" onClick={(event) => onSave(event, item.id, item.filename)} />}
              </TableCellLayout>
            </TableCell>
            <TableCell className={column2Style}>
              <TableCellLayout>
                <Button icon={<Delete16Regular />} appearance="subtle" onClick={(event) => onDelete(event, item.id)} />
              </TableCellLayout>
            </TableCell>
            <TableCell>
              <TableCellLayout appearance='primary' style={{ width: "100%" }}>
                <Button shape='rounded' title={item.id} appearance='outline' style={{ borderWidth: 0 }} className='hover-text'
                  onClick={(event) => onSave(event, item.id, item.filename)}>
                  <div className='hover-text'>{getFileIcon(item.filename)} &nbsp; {item.filename}</div>
                </Button>
              </TableCellLayout>
            </TableCell>
            <TableCell>
              {item.purpose}
            </TableCell>
            <TableCell>
              {toDateString(item.created_at)}
            </TableCell>
            <TableCell>
              {(item.bytes > 1024) ? Math.round(item.bytes / 1024) + " KB" : (item.bytes) + " bytes"}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
