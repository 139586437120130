import React, { MouseEvent } from 'react';
import { ITheme, ThemeProvider, initializeIcons } from '@fluentui/react';
import { AI, AssistantSettings } from '@api/AI';
import * as AppEvents from '@api/AppEvents';
import { ChatRoom } from '@components/ChatRoom';
import { MenuBar } from '@components/MenuBar';
import { Settings, loadAppSettings, saveAppSettings } from '@app/Settings';
import { SideMenu } from '@components/SideMenu';
import { AIFileListState } from '@dialogs/AIFileList';
import { FileListDialog } from '@dialogs/FileListDialog';
import { AuthDialog } from '@dialogs/AuthDialog';
import { FluentProvider, Theme, webDarkTheme, webLightTheme } from "@fluentui/react-components";
import { DialogOpenChangeEventHandler } from '@fluentui/react-dialog';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import EventEmitter from 'events';
import OpenAI from 'openai';

import { msalConfig } from '@app/authConfig';
import '@app/styles.css';
import { EventMessage, EventType, PublicClientApplication } from '@azure/msal-browser';

import { GoogleOAuthProvider } from '@components/GoogleAuth';
import { FileItem } from '@dialogs/FileCommonTypes';
import { ThreadsDialog } from '@dialogs/ThreadsDialog';
import { CloudFile, ICloudStorageService, sortCloudFiles } from '@api/CloudStorage';
import { OneDriveStorageService } from '@api/OneDrive';
import { GoogleDriveStorageService } from './api/GDrive';
import { AIFileDialog } from '@dialogs/AIFileDialog';
import { EditorDialog, EditorMode } from '@dialogs/EditorDialog';
import { switchTheme } from '@components/ThemeSwitcher';
import { light, lightTheme } from '@app/themes/LightTheme';
import { darkTheme } from '@app/themes/DarkTheme'; 
import { AIActions } from '@components/AIActions';
import { AssistantProvider } from './components/AssistantInfo';
import { AvatarCreatorPage } from './components/AvatarCreatorPage';
import { AvatarDialog } from './dialogs/AvatarDialog';
import { FloatingAvatar } from './components/FloatingAvatar';
import { StandardLonghandProperties, Property } from "csstype";

initializeIcons();

const googleOAuthInstance = new GoogleOAuthProvider("75190267425-n4tjut1gmenblg9aounhp1qo78fu5dcb.apps.googleusercontent.com");

// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

interface AppProps {
  name?: string;
}

interface AppState {
  isLoading: boolean;         // Show a spinner when loading
  isEditor: boolean;          // Show the editor dialog
  isThreads: boolean;         // Show the threads dialog
  isFiles: boolean;           // Show the files dialog
  isStorage: boolean;         // Show the cloud storage dialog
  isSideMenu: boolean;        // Show the side menu
  isAuth: boolean;            // Show the authentication dialog
  isAvatar: boolean;          // Show the avatar dialog

  cloudFiles: CloudFile[];    // List of cloud files
  isLoggedIn: boolean;        // User is logged in
  dialogTitle: string;        // Dialog title
  editorText: string;         // Editor text

  assistant?: OpenAI.Beta.Assistants.Assistant | undefined; // AI assistant

  theme_v8?: ITheme;          // Fluent UI theme for v8 components
  theme_v9?: Partial<Theme>;  // Fluent UI theme for v9 components
  theme_editor: string;       // Editor theme

  avatarWidth: Property.Width;
  avatarHeight: Property.Width;
}

export function toast(text: string) {
  console.log("Toast:", text);
}

class App extends React.Component<AppProps, AppState> {

  public static instance: App;

  // Authentication providers
  public googAuth: GoogleOAuthProvider;
  public msalAuth: PublicClientApplication;
  readonly googleClientId: string = "75190267425-n4tjut1gmenblg9aounhp1qo78fu5dcb.apps.googleusercontent.com";

  // Cloud storage
  public storage: ICloudStorageService | null = null;
  public cloudFiles: CloudFile[] = [];
  public cloudDirs: CloudFile[] = [];

  // TODO: move to a separate component
  private editorFile: string;
  private editorMode: EditorMode;

  // Event emitter
  private eventRouter = new EventEmitter();
  threads: OpenAI.Beta.Threads.Thread[];

  private handleHideDialog(ev?: MouseEvent<HTMLButtonElement, MouseEvent>) {
    this.setState({ isEditor: false, isThreads: false, isAuth: false, isFiles: false, isStorage: false, isAvatar: false});
  };

  public fileList: FileItem[] = [];

  public emit(message: string, data: Object = "") {
    this.eventRouter.emit(message, data);
  }

  public on(message: string, cb: (...args: any[]) => void) {
    this.eventRouter.on(message, cb);
  }

  // Global settings
  public settings = Settings;

  // AI assistant instance
  public ai: AI;
  public aiActions: AIActions;

  public chatRoom: ChatRoom | null = null;

  constructor(props: AppProps) {
    super(props);
    App.instance = this;

    loadAppSettings();

    this.state = {
      isLoading: false,
      isEditor: false,
      isThreads: false,
      isFiles: false,
      isStorage: false,
      isSideMenu: false,
      isAvatar: false,
      cloudFiles: [],
      isAuth: false,
      dialogTitle: "",
      editorText: "",
      isLoggedIn: false,
      theme_v8: darkTheme,
      theme_v9: webDarkTheme,
      theme_editor: "vs-dark2",
      avatarWidth: "100%",
      avatarHeight: "1024"
    };

    this.settings = Settings;

    this.registerEventHandlers();

    // Initialize AI
    this.ai = new AI(this.settings, this.eventRouter, saveAppSettings);
    
    // Auth: Google login
    this.googAuth = new GoogleOAuthProvider(this.googleClientId, this.eventRouter);

    // Auth: Microsoft login
    this.msalAuth = new PublicClientApplication(msalConfig);
    console.log("App constructor: adding MSAL event callback.");
    let callback = this.msalAuth.addEventCallback((message) => this.handleMsalEvents(message));
    console.log("MSAL event callback:", callback);
  }

  private async initStorage() {
    if (this.storage == null) {
      this.storage = (this.googAuth.isAuthenticated) ?
        new GoogleDriveStorageService(this.googAuth) :
        new OneDriveStorageService(this.msalAuth);
      await this.storage.initClient("");
      let user = await this.storage.getUserDetails();
      console.log("User:", user);
      let files = await this.storage.listFiles("");
      console.log("Got response with files:", files);
      for (let i = 0; i < files.length; i++) {
        console.log("FILE: ", files[i]);
      }
      this.emit(AppEvents.STORAGE_READY);
    }
  }

  private updateAuthState(callback: any = undefined) {
    if (this.googAuth.isAuthenticated) {
      this.setState({ isLoggedIn: true }, callback);
      this.emit(AppEvents.USER_AUTH_SUCCESS);
      return;
    }
    if (this.msalAuth.getActiveAccount() != null) {
      this.setState({ isLoggedIn: true }, callback);
      this.emit(AppEvents.USER_AUTH_SUCCESS);
      return;
    }
    this.setState({ isLoggedIn: false }, callback);
  }

  private registerEventHandlers() {

    this.eventRouter.on(AppEvents.AI_ASSISTANT_READY, (assistant) => {
      console.log("on AI_ASSISTANT_READY:");
      // Create AI actions API
      this.aiActions = new AIActions(this);
      this.setState({ assistant: assistant });
      this.aiActions.ask("Say Hello, and introduce yourself, please.");
    });

    this.eventRouter.on(AppEvents.GOOGLE_AUTH_SUCCESS, () => {
      console.log("Google Auth Success");
      this.setState({ isLoggedIn: true });
      this.initStorage();
    });

    this.eventRouter.on(AppEvents.GOOGLE_AUTH_FAILED, () => {
      console.log("Google Auth Failed");
      this.setState({ isLoggedIn: false });
    });

    this.eventRouter.on(AppEvents.GOOGLE_AUTH_SIGNOUT, () => {
      console.log("Google Auth Signout");
      this.setState({ isLoggedIn: false });
    });

    this.eventRouter.on(AppEvents.GOOGLE_AUTH_TOKEN, (token) => {
      console.log("Google Auth Token:", token);
    });

    this.eventRouter.on(AppEvents.GOOGLE_AUTH_PROFILE, (profile) => {
      console.log("Google Auth Profile:", profile);
    });

    this.eventRouter.on(AppEvents.GOOGLE_AUTH_EXPIRED, () => {
      console.log("Google Auth Expired");
      this.setState({ isLoggedIn: false });
    });

    this.eventRouter.on(AppEvents.MICROSOFT_AUTH_SUCCESS, (message) => {
      console.log("Microsoft Auth Success", message);
      this.setState({ isLoggedIn: true });
      this.initStorage();
    });

    this.eventRouter.on(AppEvents.MICROSOFT_AUTH_FAILED, () => {
      console.log("Microsoft Auth Failed");
      this.setState({ isLoggedIn: false });
    });

    this.eventRouter.on(AppEvents.MICROSOFT_AUTH_SIGNOUT, () => {
      console.log("Microsoft Auth Signout");
      this.setState({ isLoggedIn: false });
    });

    this.eventRouter.on(AppEvents.MICROSOFT_AUTH_TOKEN, (token) => {
      // console.log("Microsoft Auth Token:", token);
      this.initStorage();
    });

    this.eventRouter.on(AppEvents.MICROSOFT_AUTH_PROFILE, (profile) => {
      console.log("Microsoft Auth Profile:", profile);
    });

    this.eventRouter.on(AppEvents.MICROSOFT_AUTH_EXPIRED, () => {
      console.log("Microsoft Auth Expired");
      this.setState({ isLoggedIn: false });
    });

    this.eventRouter.on(AppEvents.STORAGE_READY, async () => {
      await this.ai.initialize();
      this.settings.assistants[0] = (this.ai.assistant as AssistantSettings) as any;
      // TODO: move from browser local storage to cloud storage for settings
      saveAppSettings();
    });

  }

  handleMsalEvents(message: EventMessage) {

    console.log("MSAL event:", message);
    // Check the event type
    switch (message.eventType) {
      case EventType.LOGIN_SUCCESS:
      case EventType.SSO_SILENT_SUCCESS:
        console.log("Login success:", message);
        this.setState({ isLoggedIn: true });
        // this.storage.initializeOneDrive();
        this.eventRouter.emit(AppEvents.MICROSOFT_AUTH_SUCCESS, message);
        break;

      case EventType.ACQUIRE_TOKEN_SUCCESS:
        console.log("Token success:", message);
        this.eventRouter.emit(AppEvents.MICROSOFT_AUTH_TOKEN, message);
        break;

      case EventType.LOGIN_FAILURE:
      case EventType.LOGOUT_SUCCESS:
      case EventType.LOGOUT_FAILURE:
        this.setState({ isLoggedIn: false, isAuth: true });
        this.eventRouter.emit(AppEvents.MICROSOFT_AUTH_FAILED, message);
        break;
      default:
        break;
    }
  };

  /**
   * Remove blur handler.
   * 
   * @param element 
   */
  public removeBlurHandler(element: any): void {
    //@ts-expect-error
    let listeners = window.getEventListeners(element);
    console.log("Event Listeners:", listeners);
  }

  public setIsLoading(isLoading: boolean): void {
    this.setState({ isLoading: isLoading });
  }

  handleChatRoomMount = (chatRoom: ChatRoom) => {
    console.log("handleChatRoomMount:", chatRoom);
    this.chatRoom = chatRoom;
  };

  /**
   * Show the authentication dialog.
   */
  public showAuthDialog() {
    console.log("showAuthDialog");
    this.setState({ isAuth: true });
  }

  /**
   * Show the file editor dialog.
   * @param filename 
   * @param contents 
   */
  public showFileEditor(filename: string, contents: string = "") {
    console.log("showFileEditor");
    this.editorMode = EditorMode.LocalFile;
    this.editorFile = filename;
    this.setState({ isEditor: true, editorText: contents, dialogTitle: filename });
  }

  /**
   * Show the settings editor dialog.
   */
  public showSettingsEditor() {
    console.log("showSettingsEditor");
    this.editorMode = EditorMode.Settings;
    this.editorFile = "settings.json";
    const jsonString = JSON.stringify(this.settings, null, 2);
    this.setState({ isEditor: true, editorText: jsonString, dialogTitle: "settings.json" });
  }

  /**
   * Show the text editor dialog.
   * @param text 
   */
  public showTextEditor(text: string) {
    console.log("ShowTextEditor");
    this.editorMode = EditorMode.ViewOnly;
    this.editorFile = "";
    this.setState({ isEditor: true, editorText: text, isFiles: false, dialogTitle: "Code contents" });
  }

  /**
   * Show the threads dialog.
   */
  public async showThreads(force: boolean = false) {
    console.log("ShowThreads");
    this.setState({ isEditor: false, isThreads: true, isFiles: false, dialogTitle: "Thread List" });
    this.setIsLoading(true);
    this.threads = await this.ai.fetchThreadList(force);
    console.log("Thread table data:", this.threads);
    this.setIsLoading(false);
  }

  /**
   * Show the files dialog.
   */
  public async showFiles() {
    console.log("ShowFiles");
    this.setState({ isEditor: false, isThreads: false, isFiles: true, dialogTitle: "Files List" });
    this.setIsLoading(true);
    let files = await this.ai.fetchFilesList();
    console.log("File table data:", files);
    this.fileList = [];
    for (let i = 0; i < files.length; i++) {
      let item: FileItem = files[i] as FileItem;
      this.fileList.push(item);
      console.log(this.fileList);
    }
    AIFileListState.items = this.fileList;
    this.setIsLoading(false);
  }

  /**
   * Show the cloud storage dialog.
   */
  public async showStorage(path: string = "", dirs: CloudFile[] = []) {
    console.log("ShowStorage");
    this.setIsLoading(true);
    let cloudFiles = await this.storage?.listFiles(path)!;
    this.cloudFiles = sortCloudFiles(cloudFiles);
    if (path === "") {
      dirs = [{ id: "", name: "Cloud Drive", isFolder: true }];
    }
    this.cloudDirs = dirs;
    console.log("Storage files:", this.cloudFiles);
    this.setIsLoading(false);
    this.setState({ isStorage: true });
  }

  public async showAvatarCreator() {
    console.log("ShowAvatarCreator");
    this.setIsLoading(true);
    this.setState({ dialogTitle: "Avatar Creator", isAvatar: true });
    setTimeout(() => {
      this.setIsLoading(false);
    }, 500);
  }

  /**
   * Open the side menu.
   */
  public openSideMenu(): void {
    this.setState({ isSideMenu: true });
  }

  /**
   * Clear the chat room.
   */
  public clearChat(): void {
    if (this.chatRoom) {
      this.chatRoom.clearMessages();
      this.chatRoom.forceUpdate();
    }
  }

  /**
   * Called when the main App component is mounted.
   */
  componentDidMount(): void {
    // Fullscreen
    console.log('App Component mounted');
    const bodyElement = document.querySelector('body');
    bodyElement!.className = "fullscreen";
    bodyElement!.style.height = '100vh';
    bodyElement!.style.width = '100vw';
    // Remove tabster, please...
    bodyElement!.removeAttribute('data-tabster');

    this.showAuthDialog();
  }

  /**
   * Hide dialog.
   */
  public hideDialog() {
    this.setState({ isEditor: false, isThreads: false, isFiles: false, isAuth: false, isStorage: false, isAvatar: false });
  }

  /**
   * Save chat history to a file.
   */
  public async showChatHistory() {
    let thread_id = this.ai.thread?.id ?? "";
    if (thread_id === "") {
      let chathistory_v1: string = this.chatRoom?.saveChatHistory() ?? "";
      this.editorMode = EditorMode.RemoteFile;
      this.editorFile = "chat_history.json";
      this.setState({ isEditor: true, editorText: chathistory_v1, dialogTitle: this.editorFile });
    } else {
      let history = await this.ai.fetchThreadMessages(thread_id);
      if (history === undefined || history == null) {
        window.alert("There was an error fetching the thread history.");
        return;
      }
      history = this.ai.sortMessagesByCreated(history);
      const jsonString = JSON.stringify(history, null, 2);
      this.editorMode = EditorMode.RemoteFile;
      this.editorFile = `${thread_id}.json`;
      this.setState({ isEditor: true, editorText: jsonString, dialogTitle: this.editorFile });
    }
  }

  private isLightTheme: boolean = false;
  public async toggleTheme() {
    this.isLightTheme = !this.isLightTheme;
    if (this.isLightTheme) {
      this.setState({ theme_v8: lightTheme, theme_v9: webLightTheme, theme_editor: "vs-light2"});
      switchTheme({ theme: "light" });
    } else {
      this.setState({ theme_v8: darkTheme, theme_v9: webDarkTheme, theme_editor: "vs-dark2"});
      switchTheme({ theme: "dark" });
    }
  }

  dialogClose() {
    console.log("Dialog closed");
    this.hideDialog();
  }

  authDialogOnOpenChange: DialogOpenChangeEventHandler = (event, data) => {
    console.log("AuthDialog onOpenChange:", event, data);
    this.setState({ isAuth: data.open });
    if (!data.open) {
      // Check if we're logged in after closing the authenticaion dialog
      this.updateAuthState(() => {
        if (this.state.isLoggedIn) {
          // Initialize cloud storage if we're logged in
          this.initStorage();
        } else {
          // Show a warning if we're not logged in
          setTimeout(() => {
            console.log("WARNING: you are logged in as a guest. Your data will not be saved.");
            // Initialize in read-only unprivileged mode.
            this.ai.initialize();
          }, 500);
        }
        this.setState({ avatarWidth: "30%"}); // Slide left
      });
    }
  };

  render() {
    return (
      <ThemeProvider applyTo='body' theme={this.state.theme_v8} className="fullscreen">
        <FluentProvider id="fluentProvider" theme={this.state.theme_v9} style={{ background: "transparent", height: "100%" }}>
        <AssistantProvider assistant={this.state.assistant}>
        <MenuBar />
        <SideMenu parent={this} trainer={this.ai} open={this.state.isSideMenu} />
        <ChatRoom parent={this} trainer={this.ai} name="default" onComponentMount={this.handleChatRoomMount} />
        </AssistantProvider>

        <EditorDialog
          app={this}
          open={this.state.isEditor}
          editorMode={this.editorMode}
          editorFile={this.editorFile}
          editorText={this.state.editorText}
          dialogTitle={this.state.dialogTitle}
          theme={this.state.theme_editor} />

        <ThreadsDialog parent={this} threads={this.threads} />

        <AIFileDialog app={this} />

        {this.state.isAuth && <div className='blur-overlay'></div>}

        <AuthDialog app={this} open={this.state.isAuth} msalInstance={this.msalAuth} onOpenChange={(event, data) => this.authDialogOnOpenChange(event, data)} />

        <FileListDialog app={this} items={this.cloudFiles} dirs={this.cloudDirs} />

        <AvatarDialog app={this} />
        
        <FloatingAvatar width={this.state.avatarWidth} height={this.state.avatarHeight} />

        {this.state.isLoading && <Spinner size={SpinnerSize.large} className="app-spinner" />}
        </FluentProvider>
      </ThemeProvider>
    );
  }
};

export default App;
